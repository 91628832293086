import { ReactElement, useEffect, useContext, useState } from 'react'
import FadeIn from 'react-fade-in/lib/FadeIn'

// @mui material imports //
import Alert from '@mui/material/Alert'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import useMediaQuery from '@mui/material/useMediaQuery'

// KN imports //
import theme from 'assets/theme'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'

// Modules //
import ShipmentDetailsMenu from './submodules/ShipmentDetailsMenu/ShipmentDetailsMenu'

// Context //
import { UserContext } from 'context/authentication/UserContext'
import { useInsightDetailsContext } from 'context/detailsNext/InsightDetails'

// Types //
import { EntityType, GeneralInfoListItemProps } from 'context/detailsNext/InsightDetails.types'

// Template //
import GeneralListItemTemplate from './GeneralInfoListItem'

// Data //
import { generalInfoListTranslations } from './GeneralInfoList.data'

const GeneralInfoList = (): ReactElement => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isMedium = useMediaQuery(theme.breakpoints.down('md'))
  const { user } = useContext(UserContext)
  const { insightDetailsState } = useInsightDetailsContext()

  // Props
  const { data } = insightDetailsState.insightDetailsContext
  const { shipmentId, comRefId, entityType } = insightDetailsState.insightDetailsContext
  const pairingId = data?.pairingId
  const generalInfoListItems = data?.generalInfoListProps?.listItems
  const completionDate = data?.generalInfoListProps?.completionDate
  const followed = data?.generalInfoListProps?.followed
  const level = data?.generalInfoListProps?.level
  const sendingApplication = data?.trackingInfoProps?.sendingApplication
  const trackingNumber = data?.trackingInfoProps?.trackingNumber

  // Translated Data //
  const { translation } = generalInfoListTranslations()

  // Module state
  const [eventTrigger, setEventTrigger] = useState<Date>()
  useEffect(() => {
    if (eventTrigger) {
      setTimeout(() => window.location.reload(), 1000)
    }
  }, [eventTrigger])

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      {user && shipmentId && (
        <ShipmentDetailsMenu
          shipmentid={shipmentId}
          shipmentFollowed={followed}
          pairingId={pairingId}
          completionDate={completionDate}
          eventTrigger={(): void => setEventTrigger(new Date())}
          comRefId={comRefId}
          level={level}
          sendingApplication={sendingApplication ?? ''}
          trackingNumber={trackingNumber ?? ''}
        />
      )}
      {isMedium && (
        <Box
          sx={{
            position: 'relative',
            mt: ({ functions: { pxToRem } }): string => pxToRem(-10),
            ml: ({ functions: { pxToRem } }): string => pxToRem(-20),
          }}
        >
          <KNButton variant="text" color="primary" onClick={(): void => history.back()} startIcon={<ArrowBackIcon />}>
            {translation.goBack}
          </KNButton>
        </Box>
      )}
      <Stack data-test="general-info-list" spacing={1.5} sx={{ mt: isMedium ? 1 : 0 }}>
        {generalInfoListItems?.map((generalInfoListItem: GeneralInfoListItemProps, i) =>
          GeneralListItemTemplate(generalInfoListItem, i)
        )}
        {level === 'Max' && (
          <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <img
              src={process.env.PUBLIC_URL + '/img/companies/chorus.png'}
              style={{ borderBottom: `1px solid #E0E6EE` }}
            />
          </Box>
        )}
        {entityType === EntityType.pairing && (
          <FadeIn visible={entityType === EntityType.pairing}>
            <Alert severity="info">{translation.noTMSdata}</Alert>
          </FadeIn>
        )}
      </Stack>
      {isMobile && <Divider sx={{ mt: 2 }} />}
    </Box>
  )
}

export default GeneralInfoList
