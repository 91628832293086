// @mui imports
import DeleteIcon from '@mui/icons-material/Delete'
import Box from '@mui/material/Box'

// Data
import { notificationCenterTranslations } from '../../screens/NotificationCenter/NotificationCenter.data'

// Types
import { MonitoringRuleRow, MonitoringRulesTableAction, MonitoringRulesTableColumn } from './MonitoringRulesTable.types'

export const getColumns = (): MonitoringRulesTableColumn[] => {
  const { translation } = notificationCenterTranslations()

  return [
    {
      name: 'name',
      label: translation.ruleName,
    },
    {
      name: 'type',
      label: translation.type,
    },
    {
      name: 'settings',
      label: translation.settings,
      getValue: (row: MonitoringRuleRow): JSX.Element => (
        <Box>{row.settings ? row.settings.map((setting, i) => <Box key={i}>{setting}</Box>) : <></>}</Box>
      ),
    },
    {
      name: 'created',
      label: translation.created,
    },
  ]
}

export const getActions = (onDelete): MonitoringRulesTableAction[] => {
  const { translation } = notificationCenterTranslations()
  return [
    {
      name: 'delete',
      label: translation.delete,
      icon: <DeleteIcon />,
      onClick: onDelete,
    },
  ]
}
