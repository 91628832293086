import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import Stack from '@mui/material/Stack'
import GpsFixedIcon from '@mui/icons-material/GpsFixed'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

interface TrackingLabelProps {
  compact?: boolean
}

const TrackingLabel = ({ compact }: TrackingLabelProps): ReactElement => {
  const { t } = useTranslation()
  return (
    <Stack spacing={0.5} direction="row" alignItems="center">
      <GpsFixedIcon sx={{ color: 'primary.light', fontSize: '1rem' }} />
      {!compact && (
        <KNTypography variant="textMD_SB" color="primary.light">
          {t('vehicle_manager.card.tracking')}
        </KNTypography>
      )}
    </Stack>
  )
}

export default TrackingLabel
