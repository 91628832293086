import { useState, ReactElement, useCallback } from 'react'
import { useParams } from 'react-router-dom'

// @mui material imports
import Box from '@mui/material/Box'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import CircularProgress from '@mui/material/CircularProgress'

// KN Components
import Modal from 'components/Molecules/Modal/Modal'

// Modules
import ShipmentDetailsMenuAlert from './ShipmentDetailsMenuAlert'

// Functional
import { getErrorMessage } from 'global/helpers/errorHandler'
import { getShipmentDetailsMenuOptions } from './ShipmentDetailsMenu.helpers'

// Types
import ShipmentDetailsMenuProps from './ShipmentDetailsMenu.types'

const ShipmentDetailsMenu = (props: ShipmentDetailsMenuProps): ReactElement => {
  const { token, entitytype } = useParams<{
    token: string
    entitytype: 'P' | 'S'
  }>()
  // Module state
  const {
    shipmentid,
    shipmentFollowed = false,
    pairingId,
    eventTrigger,
    completionDate,
    comRefId,
    level,
    sendingApplication,
    trackingNumber,
  } = props
  const [loading, setLoading] = useState<number>()
  const [alertOpen, setAlertOpen] = useState(false)
  const [activeModal, setActiveModal] = useState('')
  const [message, setMessage] = useState<{
    status: string
    name: string
    messageText?: string
    persistent?: boolean
  }>()

  // Dropdown state
  const [shipmentMenuEl, setShipmentMenuEl] = useState<null | HTMLElement>(null)
  const shipmentOptionsMenuOpen = Boolean(shipmentMenuEl)

  const handleClickMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      setShipmentMenuEl(event.currentTarget)
    },
    [shipmentMenuEl]
  )

  const handleCloseMenu = useCallback((): void => {
    setShipmentMenuEl(null)
  }, [shipmentMenuEl])

  const handleClickMenuItem = async (
    onClick: () => Promise<void>,
    type: string,
    messageText?: string,
    noRefresh?: boolean
  ): Promise<void> => {
    !noRefresh && eventTrigger()
    try {
      const response = await onClick()
      setMessage({
        status: String(response),
        name: type,
        messageText: `${messageText as string}${typeof response === 'string' ? `: ${String(response)}` : ''}`,
        persistent: noRefresh ? true : false,
      })
    } catch (error) {
      setMessage({
        status: getErrorMessage(error),
        name: type,
        messageText: messageText,
        persistent: noRefresh ? true : false,
      })
    }
    setLoading(undefined)
    setShipmentMenuEl(null)
    setAlertOpen(true)
  }

  const noMenuOptions =
    getShipmentDetailsMenuOptions(
      shipmentid,
      shipmentFollowed,
      pairingId,
      completionDate,
      comRefId,
      level,
      entitytype,
      sendingApplication,
      trackingNumber
    ).length === 0

  return (
    <>
      <ShipmentDetailsMenuAlert message={message} alertOpen={alertOpen} setAlertOpen={setAlertOpen} />
      {!noMenuOptions && !token && (
        <Box sx={{ position: 'absolute', right: 0, mt: ({ functions: { pxToRem } }): string => pxToRem(-10) }}>
          <IconButton
            id="shipment-details-button"
            onClick={handleClickMenu}
            sx={{
              fontSize: '0.75rem',
              cursor: 'pointer',
              width: '100%',
              color: ({ palette }) => palette.grey[500],
            }}
            aria-controls={shipmentOptionsMenuOpen ? 'shipment-details-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={shipmentOptionsMenuOpen ? 'true' : undefined}
          >
            <MoreVertIcon />
          </IconButton>
        </Box>
      )}
      <Menu
        id="shipment-details-menu"
        anchorEl={shipmentMenuEl}
        open={shipmentOptionsMenuOpen}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'shipment-details-menu-button',
          role: 'listbox',
        }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        {getShipmentDetailsMenuOptions(
          shipmentid,
          shipmentFollowed,
          pairingId,
          completionDate,
          comRefId,
          level,
          entitytype
        ).map((option, index) =>
          loading === index ? (
            <Box key={index}>
              <MenuItem sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress size={20} />
              </MenuItem>
            </Box>
          ) : (
            <div key={index}>
              <MenuItem
                data-test="shipment-details-menu-options"
                onClick={async (): Promise<void> => {
                  if (option.withModal) {
                    setActiveModal(option.name)
                  } else if (option.onClick) {
                    setLoading(index)
                    await handleClickMenuItem(option.onClick, option.name, option.optionMessage, option.noRefresh)
                  }
                }}
              >
                <ListItemIcon>
                  <Icon>{option.icon}</Icon>
                </ListItemIcon>
                <ListItemText disableTypography>{option.name}</ListItemText>
              </MenuItem>
              {activeModal !== '' && (
                <Modal
                  open={activeModal === option.name}
                  onClose={(): void => setActiveModal('')}
                  size="large"
                  sx={{
                    maxHeight: '850px',
                    overflow: 'scroll',
                  }}
                >
                  <Box my={2}>{option.withModal}</Box>
                </Modal>
              )}
            </div>
          )
        )}
      </Menu>
    </>
  )
}

export default ShipmentDetailsMenu
