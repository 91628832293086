import { ReactNode } from 'react'

import { EtnLogo, EtnSymbol, KNLogo, KNSymbol } from 'components/KN_Molecules/KNIcon/KNLogo'

export const getLogo = (type: 'logo' | 'symbol', width: string, height: string): ReactNode => {
  const etnUrl = window.location.host.includes('etrucknow')
  if (type === 'symbol') {
    if (etnUrl) {
      return (
        <EtnSymbol
          sx={{
            width: width,
            height: height,
            color: 'primary.main',
          }}
        />
      )
    }
    return (
      <KNSymbol
        sx={{
          width: width,
          height: height,
          color: 'primary.main',
        }}
      />
    )
  }
  if (etnUrl) {
    return (
      <EtnLogo
        sx={{
          width: width,
          height: height,
          color: 'primary.main',
        }}
      />
    )
  }
  return (
    <KNLogo
      sx={{
        width: width,
        height: height,
        color: 'primary.main',
      }}
    />
  )
}
