import { ReactElement, useEffect, useState } from 'react'
import FadeIn from 'react-fade-in'
import { useForm } from 'react-hook-form'

// @mui imports
import Box from '@mui/material/Box'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import DialogContentText from '@mui/material/DialogContentText'
import Alert from '@mui/material/Alert'

// KN components
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNFormText from 'components/KN_Molecules/KNForm/KNFormText'

// Functional
import { processDefaultValues } from 'global/helpers/form'
import { geocodeAddress } from 'screens/Playground/Playground.service'
import { getErrorMessage } from 'global/helpers/errorHandler'

// Data
import { playgroundTranslations } from 'screens/Playground/Playground.data'

// Types
import { GeocodeAddressDialogProps, PickUpDelivery } from 'screens/Playground/Playground.types'

const GeocodeAddressDialog = ({
  addressData,
  handleClose,
  dialogOpen,
  handleSave,
}: GeocodeAddressDialogProps): ReactElement => {
  const { handleSubmit, reset, control, formState, setValue, getValues, setError, clearErrors } =
    useForm<PickUpDelivery>()
  const { translation } = playgroundTranslations()

  useEffect(() => {
    if (addressData) reset(processDefaultValues(addressData))
    else resetForm()
  }, [addressData])

  const resetForm = (): void => {
    reset(
      processDefaultValues({
        early: null,
        late: null,
        address: {
          name: null,
          country: null,
          postalCode: null,
          city: null,
          address: null,
          location: null,
        },
      })
    )
  }

  const onSave = async (data: PickUpDelivery): Promise<void> => {
    let dataString = ''
    const dataTable: string[] = []

    const address = data.address.address
    const city = data.address.city
    const postalCode = data.address.postalCode
    const country = data.address.country

    if (address) dataTable.push(`address=${address}`)
    if (city) dataTable.push(`city=${city}`)
    if (postalCode) dataTable.push(`postalCode=${postalCode}`)
    if (country) dataTable.push(`country=${country}`)

    if (dataTable.length > 0) dataString = `?${dataTable.join('&')}`

    try {
      const latLong = await geocodeAddress(dataString)
      if (latLong) data.address.location = latLong
      handleClose()
      resetForm()
      handleSave(data)
    } catch (error) {
      setError('root.server', {
        message: getErrorMessage(error),
      })
    }
  }

  const onClose = (): void => {
    clearErrors()
    handleClose()
  }

  const getDialogContent = (): ReactElement => (
    <Box my={2}>
      {formState.errors?.root?.server && (
        <DialogContentText component="div" mb={3}>
          <Alert severity="error">{formState.errors.root.server.message}</Alert>
        </DialogContentText>
      )}
      <Box mb={3}>
        <Box display="flex" alignItems="center">
          <FadeIn>
            <Stack spacing={1}>
              {getValues('address.name') && (
                <Box mt={1}>
                  <KNFormText
                    name="address.name"
                    label={translation.addressNameLabel}
                    control={control}
                    onType={clearErrors}
                  />
                </Box>
              )}
              <Box mt={1}>
                <KNFormText
                  name="address.address"
                  label={translation.addressAddressLabel}
                  control={control}
                  onType={clearErrors}
                  rules={{
                    required: translation.required,
                  }}
                />
              </Box>
              <Box mt={1}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <KNFormText
                      name="address.postalCode"
                      label={translation.addressPostalCodeLabel}
                      control={control}
                      onType={clearErrors}
                      rules={{
                        required: translation.required,
                      }}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <KNFormText
                      name="address.city"
                      label={translation.addressCityLabel}
                      control={control}
                      onType={clearErrors}
                      rules={{
                        required: translation.required,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={1}>
                <KNFormText
                  name="address.country"
                  label={translation.addressCountryLabel}
                  control={control}
                  onType={clearErrors}
                  rules={{
                    required: translation.required,
                  }}
                />
              </Box>
            </Stack>
          </FadeIn>
        </Box>
      </Box>
    </Box>
  )
  return (
    <Dialog
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '80%',
          maxHeight: 435,
          zIndex: 9999,
        },
      }}
      maxWidth="xs"
      open={dialogOpen}
    >
      <DialogContent>{getDialogContent()}</DialogContent>
      <DialogActions>
        <KNButton
          size="medium"
          color="primary"
          variant="contained"
          type="submit"
          startIcon={<CheckIcon fontSize="inherit" />}
          disabled={Object.keys(formState.errors).length > 0}
          onClick={handleSubmit(onSave)}
        >
          {translation.shipmentAddAddressSubmitButton}
        </KNButton>
        <KNButton
          size="medium"
          color="primary"
          type="button"
          variant="contained"
          startIcon={<CloseIcon fontSize="inherit" />}
          onClick={onClose}
        >
          {translation.shipmentCancelButton}
        </KNButton>
      </DialogActions>
    </Dialog>
  )
}

export default GeocodeAddressDialog
