import { useState, useEffect, useContext, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

// @mui imports
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import InfoIcon from '@mui/icons-material/Info'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

// KN imports
import { TripListContext } from 'context/trips/TripListContext'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'
import KNCaption from 'components/KN_Molecules/KNCaption/KNCaption'
import ShipmentsView from './ShipmentsView'
import StopsView from './StopsView'
import MapView from './MapView'
import TripSummary from './TripSummary'
import ViewSwitcher from './ViewSwitcher'

// Functional
import { getDrivers } from 'screens/DriverManager/DriverManager.service'
import { getVehicles } from 'screens/VehicleManager/VehicleManager.service'
import { isViewSwitcherAvailable } from 'screens/TripDetails/TripDetails.helpers'
import { getTripLegs } from 'screens/TripDetails/TripDetails.service'
import { getTrip } from 'screens/TripDashboard/TripDashboard.service'
import { getUserPreferences } from 'screens/UserManager/UserManager.service'
import { getWelcomeGuide } from './TripDetails.guide'
import { analyticsEvent, analyticsPageView } from 'global/helpers/analytics'
import { sleep } from 'global/helpers/sleep'

// Types
import { TripData } from 'screens/TripDashboard/TripDashboard.types'
import { WelcomeGuideStatus } from 'screens/UserManager/UserManager.types'
import { LegData, StopData } from './TripDetails.types'

const TripDetails = (): ReactElement => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const { tripCid } = useParams<{
    tripCid: string
  }>()
  const [tripData, setTripData] = useState<TripData>()
  const [legsData, setLegsData] = useState<LegData[]>([])
  const [loading, setLoading] = useState(true)
  const [tripListState, tripListDispatch] = useContext(TripListContext)

  const fetchTripDetailsData = async (backgroundLoading = false): Promise<void> => {
    if (backgroundLoading) {
      tripListDispatch({ type: 'setBackgroundLoading', payload: true })
      await sleep(1000)
    } else {
      setLoading(true)
    }
    const [vehicles, drivers, trip, legs] = await Promise.all([
      tripListState.vehiclesPreloaded ? tripListState.vehicles : getVehicles(),
      tripListState.driversPreloaded ? tripListState.drivers : getDrivers(),
      getTrip(tripCid),
      getTripLegs(tripCid),
    ])
    if (!tripListState.vehiclesPreloaded) {
      tripListDispatch({ type: 'setVehicles', payload: vehicles })
    }
    if (!tripListState.driversPreloaded) {
      tripListDispatch({ type: 'setDrivers', payload: drivers })
    }
    trip.assignedDriver = drivers.find((driver) => driver.cid === trip.assignedDriverId)
    trip.assignedVehicle = vehicles.find((vehicle) => vehicle.licensePlate === trip.assignedVehicleLicensePlate)
    trip.secondaryAssignedVehicle = vehicles.find(
      (vehicle) => vehicle.licensePlate === trip.secondaryAssignedVehicleLicensePlate
    )
    setTripData(trip)
    setLegsData(legs)
    if (backgroundLoading) {
      tripListDispatch({ type: 'setBackgroundLoading', payload: false })
    } else {
      setLoading(false)
    }
  }

  const handleStopsOnChange = useCallback(
    async (updatedStops: StopData[]): Promise<void> => {
      const updatedLegsData = legsData.map((leg) => {
        // NOTE: return one of the updated stops if wayPointCid matches, or original stop if not
        leg.wayPoints = leg.wayPoints.map(
          (stop) => updatedStops.filter((updatedStop) => updatedStop.wayPointCid === stop.wayPointCid)?.[0] ?? stop
        )
        return leg
      })
      setLegsData(updatedLegsData)
      await fetchTripDetailsData(true)
    },
    [legsData]
  )

  const handleTripOnChange = useCallback(
    async (updatedTrip?: TripData): Promise<void> => {
      if (updatedTrip) {
        const updatedTripData: TripData = {
          ...updatedTrip,
          assignedDriver: tripListState.drivers.find((driver) => driver.cid === updatedTrip.assignedDriverId),
          assignedVehicle: tripListState.vehicles.find(
            (vehicle) => vehicle.licensePlate === updatedTrip.assignedVehicleLicensePlate
          ),
          secondaryAssignedVehicle: tripListState.vehicles.find(
            (vehicle) => vehicle.licensePlate === updatedTrip.secondaryAssignedVehicleLicensePlate
          ),
        }
        setTripData(updatedTripData)
      }
      await fetchTripDetailsData(true)
    },
    [tripData]
  )

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchTripDetailsData()
    analyticsPageView('polestar/cs/trip_details')
  }, [])

  const welcomeGuide = async (): Promise<void> => {
    try {
      const preferences = await getUserPreferences()
      if (preferences.welcomeGuideTripDetails === WelcomeGuideStatus.PENDING) {
        getWelcomeGuide(true).drive()
      }
    } catch {
      //
    }
  }

  useEffect(() => {
    if (!loading) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      welcomeGuide()
    }
  }, [loading])

  useEffect(() => {
    if (!tripData) {
      return
    }
    if (isMobile && isViewSwitcherAvailable(tripData)) {
      tripListDispatch({ type: 'setDetailsView', payload: 'stops' })
    }
    if (!isViewSwitcherAvailable(tripData)) {
      tripListDispatch({ type: 'setDetailsView', payload: 'shipments' })
    }
  }, [isMobile, tripData])

  return (
    <Container
      data-test="trip-details-container"
      maxWidth="xl"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      {loading ? (
        <KNLoader>
          <KNTypography>{t('trip_details.loading')}</KNTypography>
        </KNLoader>
      ) : (
        <Stack spacing={2} sx={{ flexGrow: 1 }}>
          {isViewSwitcherAvailable(tripData!) && !isMobile && <ViewSwitcher />}
          <Stack
            direction={{ xs: 'column-reverse', lg: 'row' }}
            spacing={{ xs: 2, lg: 4 }}
            alignItems="stretch"
            sx={{
              position: 'relative',
              marginX: {
                xs: '-0.5rem !important',
                md: '0 !important',
              },
              flexGrow: 1,
            }}
          >
            {tripListState.detailsView === 'shipments' && (
              <ShipmentsView trip={tripData!} legs={legsData} onChange={handleStopsOnChange} />
            )}
            {tripListState.detailsView === 'stops' && (
              <StopsView trip={tripData!} legs={legsData} onChange={handleStopsOnChange} />
            )}
            {tripListState.detailsView === 'map' && (
              <MapView trip={tripData!} legs={legsData} onChange={handleStopsOnChange} />
            )}
            <Stack
              spacing={2}
              sx={{
                position: { lg: 'sticky' },
                width: { lg: '360px' },
                flexShrink: 0,
                top: { xs: '56px', md: '64px' },
              }}
            >
              <TripSummary trip={tripData!} legs={legsData} onChange={handleTripOnChange} />
              <KNCaption icon={<InfoIcon />}>{t('trip_details.local_time')}</KNCaption>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Container>
  )
}

export default TripDetails
