import { useEffect, useState, ReactElement, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import CountUp from 'react-countup'
import i18n from 'i18n'
import { format, utcToZonedTime } from 'date-fns-tz'
import { distinctUntilChanged, Subscription } from 'rxjs'

// @mui imports
import { Collapse, Divider, IconButton } from '@mui/material'
import Box from '@mui/material/Box'
import Icon from '@mui/material/Icon'
import Menu from '@mui/material/Menu'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'

// KN Components
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Context
import { UserContext } from 'context/authentication/UserContext'

// Functional
import { markNotificationAsRead, streamNotifications } from './NotificationsMenu.service'
import { updateNotificationsData } from './NotificationsMenu.helpers'
import { getDateFormat } from 'global/helpers/dateFormatters'
import { getErrorMessage } from 'global/helpers/errorHandler'

// Types
import NotificationProps, { TemplateDataContextType } from './NotificationsMenu.type'

// Data
import { notificationMenuTranslations } from './NotificationsMenu.data'

const NotificationsMenu = (): ReactElement => {
  const history = useHistory()
  const [limitedView, setLimitedView] = useState(true)
  const [notificationsData, setNotificationsData] = useState<NotificationProps[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string | null>(null)

  // Menu
  const [updateTime, setUpdateTime] = useState(Date())
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
    setUpdateTime(Date())
  }

  // Context //
  const { userTimezone } = useContext(UserContext)

  // Translated Data //
  const { translation } = notificationMenuTranslations()

  // Data
  useEffect(() => {
    //let notificationsSubscription = new Subscription()

    const fetchNotificationsUpdates = async (): Promise<void> => {
      try {
        await streamNotifications(
          (next) => setNotificationsData((prevState) => updateNotificationsData(next, prevState)),
          (error) => setError(getErrorMessage(error)),
          () => console.log('No notifications found')
        ).then(
          /* (observable) => (notificationsSubscription = observable.pipe(distinctUntilChanged()).subscribe()) */
          (res) => setNotificationsData(res)
        )
      } catch (error) {
        setError(getErrorMessage(error))
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchNotificationsUpdates()

    return () => {
      //notificationsSubscription.unsubscribe()
    }
  }, [updateTime])

  // Notifications functions
  const updateNotification = async (notification: NotificationProps): Promise<void> => {
    const newState = notificationsData.map((newNotification) => {
      if (newNotification.cid === notification.cid) {
        return { ...newNotification, readAt: !notification.readAt }
      }
      return newNotification
    })
    await markNotificationAsRead(notification.cid)
    setNotificationsData(newState)
  }

  const updateNotificationAndClose = async (notification: NotificationProps): Promise<void> => {
    await updateNotification(notification)
    handleClose()
    history.push(`/shipment-details/S/${notification.notificationContext.cid}/${notification.notificationContext.cid}`)
  }

  /*
  const updateAllNotifications = (): Promise<void> => {
    const newState = notificationsData?.map((newNotification) => {
      return { ...newNotification, readAt: true }
    })
    setNotificationsData(newState)
    handleClose()
  }
  */

  // Notification UI
  const getNotificationUI = (notification: NotificationProps, index: number): ReactElement => {
    const getDescription = (): string => {
      switch (notification.notificationContext.templateDataContext.type) {
        case TemplateDataContextType.temperature:
          return `${i18n.t('shared.notifications_menu.description.temperature')} ${String(
            notification.notificationContext.templateDataContext.temperature
          )}`
        case TemplateDataContextType.geofence: {
          const enter = notification.notificationContext.templateDataContext.direction === 'ENTER'
          const coordsString = `${String(
            notification.notificationContext.templateDataContext.location?.longitude
          )}, ${String(notification.notificationContext.templateDataContext.location?.latitude)}`
          return enter
            ? `${i18n.t('shared.notifications_menu.description.geofence_enter')} ${coordsString}`
            : `${i18n.t('shared.notifications_menu.description.geofence_exit')} ${coordsString}`
        }
        case TemplateDataContextType.shipment_status:
          return `${i18n.t('shared.notifications_menu.description.shipment_status')} ${String(
            notification.notificationContext.templateDataContext.statusCode
          )}`
        case TemplateDataContextType.pick_up:
          return i18n.t('shared.notifications_menu.description.pick_up')
        case TemplateDataContextType.delivery:
          return i18n.t('shared.notifications_menu.description.delivery')
        default:
          return ''
      }
    }

    return (
      <Box key={index} my={1} sx={{ position: 'relative', width: '250px' }}>
        <KNTypography
          variant="body2"
          color="secondary.main"
          mb={0.5}
          onClick={async (): Promise<void> => await updateNotificationAndClose(notification)}
          sx={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          {i18n.t(`shared.notifications_menu.entity_types.${notification.notificationContext.type}`)}
        </KNTypography>
        <Box mb={1}>
          <KNTypography mr={1} color="secondary.focus">
            {i18n.t(`shared.notifications_menu.types.${notification.notificationContext.templateDataContext.type}`)}
          </KNTypography>
          <KNTypography>{getDescription()}</KNTypography>
        </Box>
        <KNTypography color="secondary.focus" mb={0.5}>
          {format(utcToZonedTime(new Date(notification.createdAt), userTimezone), getDateFormat('full_timezoned'))}
        </KNTypography>
        <Icon
          onClick={async (): Promise<void> => await updateNotification(notification)}
          sx={{
            position: 'absolute',
            top: 5,
            right: 0,
            color: ({ palette: { grey, success } }) => (!notification.readAt ? grey[400] : success.main),
            transition: 'color 0.5s ease',
            '&:hover': {
              color: ({ palette: { success } }) => success.main,
            },
          }}
          fontSize="small"
        >
          {!notification.readAt ? 'radio_button_unchecked' : 'check_circle'}
        </Icon>
        <Divider sx={{ borderColor: 'primary.main' }} />
      </Box>
    )
  }

  return (
    <>
      <IconButton
        id="positioned-button"
        aria-controls={open ? 'positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <NotificationsNoneOutlinedIcon color={notificationsData.length ? 'primary' : 'disabled'} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: '5px',
            right: '5px',
            backgroundColor: ({ palette: { error } }): string => error.main,
            color: ({ palette: { common } }): string => common.white,
            borderRadius: '25px',
            fontSize: notificationsData.length > 9 ? '10px' : '12px',
            height: '15px',
            width: '15px',
          }}
        >
          <CountUp duration={0.2} start={0} end={notificationsData.length || 0} />
        </Box>
      </IconButton>
      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 50,
        }}
        sx={{
          maxHeight: '600px',
          '& .MuiPaper-root': {
            mt: 1,
            p: 2,
          },
        }}
      >
        <KNTypography variant="displayXXS_SB">{translation.moduleName}</KNTypography>
        {notificationsData && (
          <div>
            {notificationsData.slice(0, 3).map((notification, index) => getNotificationUI(notification, index))}
            <Collapse orientation="vertical" in={!limitedView}>
              {notificationsData
                .slice(3, notificationsData.length)
                .map((notification, index) => getNotificationUI(notification, index))}
            </Collapse>
            {notificationsData.length > 3 && (
              <Box sx={{ textAlign: 'center' }} p={1}>
                <KNTypography
                  variant="button"
                  onClick={(): void => setLimitedView(!limitedView)}
                  sx={{
                    cursor: 'pointer',
                    color: ({ palette: { grey } }): string => grey[400],
                    transition: 'color 0.5s ease',
                    '&:hover': {
                      color: ({ palette: { primary } }): string => primary.main,
                    },
                  }}
                >
                  {limitedView ? translation.moreButton : translation.lessButton}
                </KNTypography>
              </Box>
            )}
            {notificationsData.length > 0 && (
              <Box my={1} sx={{ width: '250px' }}>
                <KNTypography color="primary.main" variant="caption">
                  {translation.closeMessage}
                </KNTypography>
              </Box>
            )}
            {/* <Box mt={1} sx={{ textAlign: "right" }}>
              <KNTypography
                variant="subtitle2"
                color="secondary"
                sx={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={(): void => updateAllNotifications()}
              >
                {translation.markAllAsRead}
              </KNTypography>
            </Box> */}
          </div>
        )}
        {notificationsData.length === 0 && (
          <Box mt={1}>
            <KNTypography variant="textLG" color="secondary.focus">
              {translation.noNotifications}
            </KNTypography>
          </Box>
        )}
      </Menu>
    </>
  )
}

export default NotificationsMenu
