import React, { useState, useEffect, useRef, useCallback, ReactElement, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { parseISO, isPast, isThisYear, isEqual, isSameDay, differenceInHours } from 'date-fns'

// @mui imports
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Collapse from '@mui/material/Collapse'

// KN imports
import { outlineAnimation } from 'global/helpers/animations'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import { KNDataCardAction } from 'components/KN_Molecules/KNDataCard/types'
import StatusUpdateWithAttachmentsDialog from 'screens/StatusManager/StatusUpdateWithAttachmentsDialog'
import DocumentsUploadDialog from 'screens/StatusManager/DocumentsUploadDialog'
import DocumentLink from 'screens/StatusManager/DocumentLink'
import LocationLink from 'screens/TripDashboard/LocationLink'
import StopHeader from './StopHeader'
import PrimaryActions from './PrimaryActions'
import { LegCardPrimaryStopProps } from './LegCard.types'
import { StopData } from './TripDetails.types'

const LegCardPrimaryStop = ({
  trip,
  leg,
  stop,
  weblinkToken,
  onChange,
  collapsed = false,
}: LegCardPrimaryStopProps): ReactElement => {
  const { t } = useTranslation()
  const location = useLocation<{ wayPointCids: string[] }>()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [statusUpdateWithAttachmentsDialogOpen, setStatusUpdateWithAttachmentsDialogOpen] = useState(false)
  const [documentsUploadDialogOpen, setDocumentsUploadDialogOpen] = useState(false)
  const [isHighlighted, setIsHighlighted] = useState(false)
  const cardRef = useRef<HTMLDivElement>(null)

  const handleStatusUpdateClick = useCallback((): void => setStatusUpdateWithAttachmentsDialogOpen(true), [])
  const handleStatusUpdateAction = useCallback((updatedStops: StopData[]): void => {
    setStatusUpdateWithAttachmentsDialogOpen(false)
    onChange?.(updatedStops)
  }, [])
  const handleStatusUpdateClose = useCallback((): void => {
    setStatusUpdateWithAttachmentsDialogOpen(false)
  }, [])

  const handleDocumentsUploadClick = useCallback((): void => setDocumentsUploadDialogOpen(true), [])
  const handleDocumentsUploadAction = useCallback((updatedStops: StopData[]): void => {
    setDocumentsUploadDialogOpen(false)
    onChange?.(updatedStops)
  }, [])
  const handleDocumentsUploadClose = useCallback((): void => {
    setDocumentsUploadDialogOpen(false)
  }, [])

  const actions: KNDataCardAction[] = []
  if (stop.availableStatuses.length > 0) {
    actions.push({
      label: t('trip_details.card.actions.update_status_and_documents'),
      handler: handleStatusUpdateClick,
    })
  } else {
    actions.push({
      label: t('trip_details.card.actions.upload_documents'),
      handler: handleDocumentsUploadClick,
      variant: 'text',
    })
  }

  // NOTE: make whole card clickable, but only for weblink and only on mobile
  const handleCardClick = (): void => {
    if (!weblinkToken || !isMobile || stop.availableStatuses.length === 0) {
      return
    }
    setStatusUpdateWithAttachmentsDialogOpen(true)
  }

  const getBackgroundColor = (): string | undefined => {
    switch (stop.type) {
      case 'PUP':
        return '#ecf2fa'
      case 'DEL':
        return '#d3ddf1'
      default:
        return undefined
    }
  }

  useEffect(() => {
    setIsHighlighted(location.state?.wayPointCids.includes(stop.wayPointCid))
  }, [location.state])

  useEffect(() => {
    if (isHighlighted) {
      cardRef.current?.scrollIntoView()
    }
  }, [isHighlighted])

  return (
    <>
      <Box
        data-test="leg-card-box"
        data-guide={`${stop.type}-card`}
        ref={cardRef}
        sx={{
          width: '100%',
          padding: { xs: 2, md: 0 },
          background: { xs: getBackgroundColor(), md: 'none' },
          animation: isHighlighted ? outlineAnimation() : undefined,
        }}
        onClick={handleCardClick}
      >
        <Stack
          direction="column"
          spacing={1}
          sx={{
            height: { sx: 'auto', md: '100%' },
          }}
        >
          <StopHeader stop={stop} />
          <Collapse in={!collapsed} timeout={200} sx={{ flexGrow: 1 }}>
            <Grid data-test="pickup-delivery-address" container>
              {isMobile && stop.type === 'PUP' && leg.collectionInstructions && (
                <Grid item xs={12}>
                  <KNTypography variant="textMD_SB" color="primary.light">
                    {t('trip_details.shipment_details.collection_instructions')}
                  </KNTypography>
                  <KNTypography color="text.main" fontSize="textLG">
                    {leg.collectionInstructions}
                  </KNTypography>
                </Grid>
              )}
              {isMobile && stop.type === 'DEL' && leg.deliveryInstructions && (
                <Grid item xs={12}>
                  <KNTypography variant="textMD_SB" color="primary.light">
                    {t('trip_details.shipment_details.delivery_instructions')}
                  </KNTypography>
                  <KNTypography color="text.main" fontSize="textLG">
                    {leg.deliveryInstructions}
                  </KNTypography>
                </Grid>
              )}
              <Grid data-test="shipper-consignee" item xs={12} xl={6}>
                <KNTypography variant="textMD_SB" color="primary.light">
                  {stop.type === 'PUP' ? t('trip_details.card.shipper') : t('trip_details.card.consignee')}
                </KNTypography>
                <KNTypography color="text.main" fontSize="textLG">
                  {stop.address.name.join(' ')}
                </KNTypography>
              </Grid>
              <Grid data-test="trips-address" item xs={12} xl={6}>
                <KNTypography variant="textMD_SB" color="primary.light">
                  {t('trip_details.card.address')}
                </KNTypography>
                <LocationLink
                  data-test="location-link"
                  countryCode={stop.address.countryCode}
                  city={stop.address.city.join(', ')}
                  zipCode={stop.address.zipCode}
                  street={stop.address.street.join(', ')}
                  marker={
                    stop.geoPoint
                      ? {
                          coords: {
                            lat: stop.geoPoint.latitude,
                            lng: stop.geoPoint.longitude,
                          },
                          type: stop.type,
                        }
                      : undefined
                  }
                  lineBreaks="all"
                />
              </Grid>
              {stop.documents && (
                <Grid item xs={12}>
                  <KNTypography variant="textMD_SB" color="primary.light">
                    {t('trip_details.card.attachments')}
                  </KNTypography>
                  <KNTypography color="text.main" fontSize="textLG">
                    {stop.documents
                      .filter((file) => ['POP', 'POD'].includes(file.fileType))
                      .map((file, index) => (
                        <DocumentLink
                          key={file.id}
                          file={file}
                          index={index > 1 ? index : undefined}
                          weblinkToken={weblinkToken}
                        />
                      ))}
                    {stop.documents
                      .filter((file) => !['POP', 'POD'].includes(file.fileType))
                      .map((file, index) => (
                        <DocumentLink key={file.id} file={file} weblinkToken={weblinkToken} />
                      ))}
                  </KNTypography>
                </Grid>
              )}
            </Grid>
          </Collapse>
          {actions.length > 0 && <PrimaryActions actions={actions} />}
        </Stack>
      </Box>

      <StatusUpdateWithAttachmentsDialog
        data-test="attachments-dialog"
        payload={{
          trip,
          leg,
          stop,
          weblinkToken,
          useDeviceTime: !!weblinkToken && isMobile, // NOTE: use device time on weblink mobile
        }}
        open={statusUpdateWithAttachmentsDialogOpen}
        onAction={handleStatusUpdateAction}
        onClose={handleStatusUpdateClose}
      />

      <DocumentsUploadDialog
        data-test="docs-upload-dialog"
        payload={{
          trip,
          leg,
          stop,
          weblinkToken,
        }}
        open={documentsUploadDialogOpen}
        onAction={handleDocumentsUploadAction}
        onClose={handleDocumentsUploadClose}
      />
    </>
  )
}

export default LegCardPrimaryStop
