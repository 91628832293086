import { useState, useEffect, useContext, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'
import { CarrierListContext, CarrierFiltersValues } from 'context/carriers/CarrierListContext'
import CarrierFilters from './CarrierFilters'
import CarrierTable from './CarrierTable'
import { getCarriers } from './CarrierManager.service'
import { CarrierData } from './CarrierManager.type'

const CarrierManager = (): ReactElement => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [carriersData, setCarriersData] = useState<CarrierData[]>([])
  const [filteredCarriersData, setFilteredCarriersData] = useState<CarrierData[]>([])
  const [carrierListState, carrierListDispatch] = useContext(CarrierListContext)

  const fetchData = async (): Promise<void> => {
    setLoading(true)
    setCarriersData(await getCarriers())
    setLoading(false)
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData()
  }, [])

  useEffect(() => {
    filterData(carrierListState.filters)
  }, [carriersData, carrierListState.filters])

  const handleOnChange = useCallback(async (): Promise<void> => {
    await fetchData()
  }, [])

  const filterData = (filters: CarrierFiltersValues): void => {
    setFilteredCarriersData(
      carriersData.filter((carrier) => {
        const lowercaseKeywords = filters.keywords?.map((keyword: string) => keyword.toLowerCase())
        let keywordsCondition = true
        if (lowercaseKeywords?.length) {
          const lowercaseValues = [carrier.cid, carrier.name, carrier.customerIdentifier].map((value: string) =>
            value.toLowerCase()
          )
          keywordsCondition = lowercaseValues
            .map((value) => {
              return lowercaseKeywords.map((keyword) => value.includes(keyword)).some((condition: boolean) => condition)
            })
            .some((condition: boolean) => condition)
        }

        let integrationsCondition = true
        const integrationsFilters = filters.integrations
        if (integrationsFilters?.length) {
          integrationsCondition = integrationsFilters
            .map((integration) => {
              return carrier.integration === integration
            })
            .some((condition: boolean) => condition)
        }

        let telematicsCondition = true
        const telematicsFilters = filters.telematics
        if (telematicsFilters?.length) {
          telematicsCondition = telematicsFilters
            .map((telematics) => {
              return carrier.telematics === telematics
            })
            .some((condition: boolean) => condition)
        }

        return [keywordsCondition, integrationsCondition, telematicsCondition].every(
          (condition: boolean): boolean => condition
        )
      })
    )
  }

  return (
    <Container maxWidth="xl" data-test="carriers-container">
      {loading ? (
        <KNLoader>
          <KNTypography>{t('carrier_manager.loading')}</KNTypography>
        </KNLoader>
      ) : (
        <Paper elevation={8} sx={{ padding: 2 }}>
          <Stack
            spacing={1}
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            alignItems={{ xs: 'start', md: 'center' }}
            mb={2}
          >
            <KNTypography variant="h4">
              {t('carrier_manager.carriers_count', { count: filteredCarriersData.length })}
            </KNTypography>
          </Stack>
          <CarrierFilters />
          <CarrierTable carriers={filteredCarriersData} onChange={handleOnChange} />
        </Paper>
      )}
    </Container>
  )
}

export default CarrierManager
