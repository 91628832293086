import { ReactElement } from 'react'
import i18n from 'i18n'
import format from 'date-fns-tz/format'
import isBefore from 'date-fns/isBefore'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import reactStringReplace from 'react-string-replace'

// @mui imports
import { ChipProps } from '@mui/material/Chip'
import { Box, Theme, Tooltip } from '@mui/material'
import { SystemStyleObject } from '@mui/system'

// KN Components
import ETABlinkBlink from 'modules/ETA/ETABlinkBlink'
import KNCountryFlag from 'components/KN_Molecules/KNCountryFlag/KNCountryFlag'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import ShipmentPairer from 'modules/ShipmentPairer/ShipmentPairer'
import MonitoringRuleForm from 'modules/MonitoringRuleForm/MonitoringRuleForm'

// Icons
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import LeakAddIcon from '@mui/icons-material/LeakAdd'
import LeakRemoveIcon from '@mui/icons-material/LeakRemove'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined'

// Functional
import { unpairShipment, deletePairing } from './ShipmentsInsightData.service'
import { hasRole } from 'global/helpers/authorization'
import { analyticsEvent } from 'global/helpers/analytics'
import { valueToZonedDatetimeWithoutOffset } from 'global/helpers/dataTransformation'
import {
  sharePairing,
  shareShipment,
} from 'modules/GeneralInfoList/submodules/ShipmentDetailsMenu/ShipmentDetailsMenu.service'
import { getShareLink } from 'modules/GeneralInfoList/submodules/ShipmentDetailsMenu/ShipmentDetailsMenu.helpers'
import { getSelectedCompany } from 'context/authentication/User.helpers'

// Types
import KNCardProps, { KNCardIndicator, KNCardMenuOption } from 'components/KN_Molecules/KNCard/KNCard.types'
import { ShipmentInsightDataProps, ShipmentDataProps } from './ShipmentsInsightData.type'
import {
  MegaTableIcon,
  MegaTableMenuOption,
  MegaTableRow,
  MegaTableRowLabel,
} from 'components/KN_Molecules/MegaTable/MegaTable.types'
import { IconName } from 'components/KN_Components/Base/Icons/Icon.type'
import { Role } from 'context/authentication/Role.types'

// Data
import { moonshotTableTranslations, shipmentsTableTranslations } from './ShipmentsInsightData.data'

export const setDataForCards = (
  allShipments: ShipmentInsightDataProps[],
  setEvent: any,
  userTimezone: string,
  featureFlagged?: boolean
): KNCardProps[] => {
  // Translated Data //
  const { translation } = shipmentsTableTranslations()
  const { moonshotTranslation } = moonshotTableTranslations()

  // Left indicators
  const getLeftIndicators = (shipment: ShipmentInsightDataProps): KNCardIndicator[] => {
    let indicators: KNCardIndicator[] = []
    // Tracking active
    if (shipment.pairingDate && !shipment.unpairingDate) {
      const IndicatorLevelMax = {
        icon: <LeakAddIcon />,
        backgroundColor: 'secondary.focus',
        color: 'white.main',
        description: translation.labels.labelTrackingActive,
      }
      indicators = [...indicators, IndicatorLevelMax]
    }
    // Tracking completed
    if (shipment.pairingDate && shipment.unpairingDate) {
      const IndicatorLevelMax = {
        icon: <LeakRemoveIcon />,
        backgroundColor: 'success.main',
        color: 'white.main',
        description: translation.labels.labelTrackingCompleted,
      }
      indicators = [...indicators, IndicatorLevelMax]
    }
    // TMS data available
    if (shipment.comRefId > '') {
      const IndicatorTMS = {
        icon: 'TMS',
        color: 'white.main',
        backgroundColor: 'primary.light',
        description: translation.labels.labelTMSDataAvailable,
      }
      indicators = [...indicators, IndicatorTMS]
    }
    // Shipment transport type
    if (shipment.airInfo) {
      const indicatorAir = {
        icon: <AirplanemodeActiveIcon />,
        color: 'white.main',
        backgroundColor: 'primary.light',
        description: translation.labels.labelAirShipment,
      }
      indicators = [...indicators, indicatorAir]
    }
    // ETA available
    const selectedCompany = getSelectedCompany()
    const etaModuleActive = selectedCompany?.modules?.eta
    if (shipment.latestEta && etaModuleActive) {
      let pastEta = false
      if (isBefore(new Date(shipment?.latestEta), new Date())) pastEta = true
      const IndicatorETA = {
        icon: <ETABlinkBlink />,
        color: 'white.main',
        backgroundColor: 'white.main',
        description: valueToZonedDatetimeWithoutOffset(shipment.latestEta),
      }
      indicators = pastEta ? [...indicators] : [...indicators, IndicatorETA]
    }
    return indicators
  }
  // Right indicators
  const getRightIndicators = (shipment: ShipmentInsightDataProps): KNCardIndicator[] => {
    let indicators: KNCardIndicator[] = []
    // Followed
    if (shipment.hasMonitoringRules) {
      const IndicatorFollowed = {
        icon: <VisibilityOutlined />,
        backgroundColor: 'secondary.focus',
        color: 'white.main',
        description: translation.labels.labelFollowed,
      }
      indicators = [...indicators, IndicatorFollowed]
    }
    return indicators
  }
  // Shipment Menu options
  const getMenuOptions = (shipment: ShipmentInsightDataProps): KNCardMenuOption[] => {
    let menuOptions: KNCardMenuOption[] = []
    // Follow option
    if (!shipment.hasMonitoringRules && shipment.entityType === 'SHIPMENT' && featureFlagged) {
      const FollowOption = {
        text: moonshotTranslation.general.editPairing,
        withModal: (): ReactElement => (
          <MonitoringRuleForm
            modalMode={{
              sendingApplication: shipment.sendingApplication,
              trackingNumber: shipment.trackingNumber,
              cid: shipment.cid,
            }}
            forceRefresh={true}
          />
        ),
        icon: <VisibilityIcon />,
      }
      menuOptions = [...menuOptions, FollowOption]
    }

    // Edit pair option
    if (!shipment.unpairingDate && shipment.entityType === 'PAIRING') {
      const EditOption = {
        text: moonshotTranslation.general.editPairing,
        withModal: (): ReactElement => <ShipmentPairer id={shipment.cid} editMode={true} modalMode />,
        icon: <LeakAddIcon />,
      }
      menuOptions = [...menuOptions, EditOption]
    }
    // Unpair option
    if (!shipment.unpairingDate && shipment.entityType === 'PAIRING') {
      const UnpairOption = {
        text: moonshotTranslation.general.unpairShipment,
        onClick: async (): Promise<void> => {
          await unpairShipment(shipment.cid).then(() => setEvent(new Date()))
          analyticsEvent('polestar_cv_shipment_unpaired')
        },
        icon: <LeakRemoveIcon />,
      }
      menuOptions = [...menuOptions, UnpairOption]
    }
    return menuOptions
  }

  // Assign rowIds to list items
  const shipmentsMapped = allShipments.map((shipment, index) => {
    return {
      title: shipment.displayReference,
      link:
        shipment.entityType === 'PAIRING' && shipment.pairingReference
          ? `/shipment-details/P/${shipment.cid}/${shipment.displayReference}`
          : `/shipment-details/S/${shipment.cid}/${shipment.comRefId}`,
      id: index,
      label:
        shipment.entityType !== 'PAIRING'
          ? {
              color: shipment.status === 'DELIVERED' ? 'success.main' : 'primary.light',
              text: shipment.status ? i18n.t(`shared.aggregatedStatus.${shipment.status}`) : '',
            }
          : undefined,
      gridItems: [
        { property: 'Shipment ID', value: shipment.comRefId ?? '' },
        {
          property: 'Pickup',
          value: shipment.pickUp && (
            <>
              {String(
                `${
                  shipment.pickUp.early
                    ? format(utcToZonedTime(new Date(shipment.pickUp.early), userTimezone), 'dd/MM/yyyy HH:mm')
                    : ''
                } ${shipment.pickUp?.address?.name ?? ''} ${shipment.pickUp?.address?.city ?? ''} ${
                  shipment.pickUp?.address?.postalCode ?? ''
                }`
              )}
              <KNCountryFlag
                countryCode={shipment.pickUp?.address.country ?? ''}
                sx={{ ml: 1, display: 'inline-flex' }}
              />
            </>
          ),
          valueMultiline: true,
        },
        {
          property: 'Delivery',
          value: shipment.delivery && (
            <>
              {String(
                `${
                  shipment.delivery.late
                    ? format(utcToZonedTime(new Date(shipment.delivery.late), userTimezone), 'dd/MM/yyyy HH:mm')
                    : ''
                } ${shipment.delivery?.address?.name ?? ''} ${shipment.delivery?.address?.city ?? ''} ${
                  shipment.delivery?.address?.postalCode ?? ''
                }`
              )}
              <KNCountryFlag
                countryCode={shipment.delivery?.address.country ?? ''}
                sx={{ ml: 1, display: 'inline-flex' }}
              />
            </>
          ),
          valueMultiline: true,
        },
      ],
      leftIndicators: getLeftIndicators(shipment),
      rightIndicators: getRightIndicators(shipment),
      menuOptions: getMenuOptions(shipment),
      highlighted: false,
    }
  })

  // Order data items by creation date, newest first
  const dataByCreationDate = shipmentsMapped.sort((a, b) => a.id - b.id)
  return dataByCreationDate
}

export const setDataForMegaTable = (
  allEntities: ShipmentInsightDataProps[],
  setEvent: any,
  userTimezone: string,
  highlight?: string,
  featureFlagged?: boolean
): MegaTableRow[] => {
  // Translated Data //
  const { translation } = shipmentsTableTranslations()
  const { moonshotTranslation } = moonshotTableTranslations()

  const editPairingOption = {
    displayName: moonshotTranslation.general.editPairing,
    icon: 'link' as IconName,
    withModal: (row): ReactElement => <ShipmentPairer id={row.pairingCid ?? ''} editMode={true} modalMode />,
  }

  const deletePairingOption = {
    displayName: moonshotTranslation.general.removeEntry,
    icon: 'delete_outline' as IconName,
    withConfirmation: {
      title: moonshotTranslation.general.confirm.title,
      content: moonshotTranslation.general.confirm.content,
      confirmButton: moonshotTranslation.general.confirm.confirmButton,
      cancelButton: moonshotTranslation.general.confirm.cancelButton,
      confirm: async (row: ShipmentDataProps): Promise<void> => {
        await deletePairing(row.cid).then(() => setEvent(new Date()))
      },
    },
  }

  const unpairShipmentOption = {
    displayName: moonshotTranslation.general.unpairShipment,
    icon: 'link_off' as IconName,
    onClick: async (row: ShipmentDataProps): Promise<void> => {
      await unpairShipment(row.pairingCid ?? '').then(() => setEvent(new Date()))
      analyticsEvent('polestar_cv_shipment_unpaired')
    },
  }

  const getEntityInfo = (cid: string): ShipmentInsightDataProps | undefined => {
    const entity = allEntities.find((entity) => entity.cid === cid)
    return entity
  }
  const notificationOptions = {
    displayName: translation.general.followShipment,
    icon: 'visibility' as IconName,
    withModal: (row): ReactElement => {
      const entity = getEntityInfo(row.cid)
      return (
        <MonitoringRuleForm
          modalMode={{
            sendingApplication: entity?.sendingApplication ?? '',
            trackingNumber: entity?.trackingNumber ?? '',
            cid: row.cid,
          }}
          forceRefresh={true}
        />
      )
    },
  }

  const shareShipmentMenuOption = (href: string): MegaTableMenuOption => ({
    displayName: translation.general.shareShipment,
    icon: 'reply' as IconName,
    optionMessage: translation.general.shareShipmentMessage,
    withAlert: async (row: MegaTableRow): Promise<any> =>
      shareShipment(row.cid ?? '').then((response) => {
        getShareLink(href, response)
        const link = getShareLink(href, response)
        void navigator.clipboard.writeText(link)
        return response.password
      }),
  })

  const sharePairingMenuOption = (href: string): MegaTableMenuOption => ({
    displayName: translation.general.shareShipment,
    icon: 'reply' as IconName,
    optionMessage: translation.general.shareShipmentMessage,
    withAlert: async (row: MegaTableRow): Promise<any> =>
      sharePairing(row.cid ?? '').then((response) => {
        const link = getShareLink(href, response)
        void navigator.clipboard.writeText(link)
        return response.password
      }),
  })

  const getMenuOptions = (entity: ShipmentInsightDataProps): MegaTableMenuOption[] => {
    const host = window.location.host
    const shipmentLink = `${host}${getHyperlink(entity)}`
    const shareShipment = shareShipmentMenuOption(shipmentLink)
    const sharePairing = sharePairingMenuOption(shipmentLink)

    const withFollowOptions = hasRole(Role.Editor) && featureFlagged ? [notificationOptions] : []

    const withPairingsOptions = hasRole(Role.Editor)
      ? !entity.unpairingDate && entity.pairingReference
        ? [...withFollowOptions, editPairingOption, unpairShipmentOption]
        : [...withFollowOptions]
      : [...withFollowOptions]

    const withShareOption = [...withPairingsOptions, shareShipment]

    const onlyPairingsOptions = !entity.unpairingDate
      ? hasRole(Role.Editor)
        ? [editPairingOption, unpairShipmentOption, sharePairing]
        : [sharePairing]
      : hasRole(Role.Admin)
      ? [deletePairingOption, sharePairing]
      : [sharePairing]

    return entity.entityType === 'PAIRING' ? onlyPairingsOptions : withShareOption
  }

  const getLeftShipmentIndicators = (shipment: ShipmentInsightDataProps): MegaTableIcon[] => {
    let indicators: MegaTableIcon[] = []
    // Tracking active
    if (shipment.pairingDate && !shipment.unpairingDate) {
      const IndicatorLevelMax = {
        name: (
          <LeakAddIcon
            sx={({ functions: { pxToRem } }: Theme): SystemStyleObject<Theme> => ({
              width: pxToRem(18),
              height: pxToRem(18),
            })}
          />
        ),
        color: 'secondary.focus',
        backgroundColor: 'white.main',
        tooltip: [{ text: translation.labels.labelTrackingActive }],
      }
      indicators = [...indicators, IndicatorLevelMax]
    }
    // Tracking completed
    if (shipment.pairingDate && shipment.unpairingDate) {
      const IndicatorLevelMax = {
        name: (
          <LeakRemoveIcon
            sx={({ functions: { pxToRem } }: Theme): SystemStyleObject<Theme> => ({
              width: pxToRem(18),
              height: pxToRem(18),
            })}
          />
        ),
        color: 'success.main',
        backgroundColor: 'white.main',
        tooltip: [{ text: translation.labels.labelTrackingCompleted }],
      }
      indicators = [...indicators, IndicatorLevelMax]
    }
    // TMS data available
    if (shipment.comRefId > '') {
      const IndicatorTMS = {
        name: 'TMS',
        color: 'white.main',
        backgroundColor: 'primary.light',
        tooltip: [{ text: translation.labels.labelTMSDataAvailable }],
      }
      indicators = [...indicators, IndicatorTMS]
    }
    // ETA available
    if (shipment.latestEta) {
      let pastEta = false
      if (isBefore(new Date(shipment?.latestEta), new Date())) pastEta = true
      const IndicatorETA = {
        name: <ETABlinkBlink />,
        color: 'white.main',
        backgroundColor: 'white.main',
        tooltip: [
          { text: `${translation.general.eta as string}: ${valueToZonedDatetimeWithoutOffset(shipment.latestEta)}` },
        ],
      }
      indicators = pastEta ? [...indicators] : [...indicators, IndicatorETA]
    }
    return indicators
  }

  const getRightShipmentIndicators = (shipment: ShipmentInsightDataProps): MegaTableIcon[] => {
    let indicators: MegaTableIcon[] = []
    // Followed
    if (shipment.hasMonitoringRules) {
      const IndicatorFollowed = {
        name: (
          <VisibilityOutlined
            sx={({ functions: { pxToRem } }: Theme): SystemStyleObject<Theme> => ({
              width: pxToRem(15),
              height: pxToRem(15),
            })}
          />
        ),
        color: 'white.main',
        backgroundColor: 'secondary.focus',
        tooltip: [{ text: translation.labels.labelFollowed }],
      }
      indicators = [...indicators, IndicatorFollowed]
    }
    return indicators
  }

  const getLabels = (entity: ShipmentInsightDataProps): MegaTableRowLabel[] => {
    let labels: MegaTableRowLabel[] = []
    if (entity.entityType.startsWith('SHIPMENT')) {
      const getLabelBgColor = (): ChipProps['color'] => {
        if (entity.status === 'DELIVERED') return 'success'
        return 'default'
      }

      const getLabelColor = (): string => {
        if (entity.status === 'DELIVERED') return 'white.main'
        return 'dark.main'
      }

      if (entity.status) {
        const statusLabel: MegaTableRowLabel = {
          name: i18n.t(`shared.aggregatedStatus.${entity.status}`),
          color: getLabelColor(),
          backgroundColor: getLabelBgColor(),
        }
        labels = [...labels, statusLabel]
      }
    }
    return labels
  }

  const replaceHighlight = (text: string): JSX.Element | string => {
    if (!highlight || highlight == '') {
      return text
    }

    return (
      <>
        {reactStringReplace(text, highlight, (match: string, index: number) => (
          <KNTypography
            component="span"
            key={index}
            sx={{ backgroundColor: ({ palette: { warning } }: Theme): string => warning.light }}
          >
            {match}
          </KNTypography>
        ))}
      </>
    )
  }

  const getHyperlink = (entity: ShipmentInsightDataProps): string =>
    entity.entityType === 'PAIRING' && entity.pairingReference
      ? `/shipment-details/P/${entity.cid}/${entity.displayReference}`
      : `/shipment-details/S/${entity.cid}/${entity.comRefId}`

  // const getShipmentType = (entity: ShipmentInsightDataProps): JSX.Element => {
  //   const ShipmentTypeIcon = (props: { title: string; icon: JSX.Element }): JSX.Element | null => {
  //     const { title, icon } = props

  //     return (
  //       <Tooltip
  //         title={
  //           <KNTypography
  //             variant="textSM"
  //             sx={({ palette: { primary } }: Theme): SystemStyleObject<Theme> => ({
  //               color: primary.contrastText,
  //             })}
  //           >
  //             {title}
  //           </KNTypography>
  //         }
  //         placement="bottom"
  //       >
  //         {icon}
  //       </Tooltip>
  //     )
  //   }

  //   const hasAirInfo = entity.airInfo

  //   const roadShipment = (
  //     <ShipmentTypeIcon
  //       title={translation.labels.labelRoadShipment}
  //       icon={<LocalShippingIcon fontSize="small" color="inherit" />}
  //     />
  //   )

  //   const airShipment = hasAirInfo ? (
  //     <ShipmentTypeIcon
  //       title={translation.labels.labelAirShipment}
  //       icon={<AirplanemodeActiveIcon fontSize="small" color="inherit" />}
  //     />
  //   ) : null

  //   // Uncomment the following block if you want to include sea shipment
  //   // const seaShipment = hasSeaInfo ? (
  //   //   <ShipmentTypeIcon title="Sea shipment" icon={<DirectionsBoatIcon fontSize="small" color="inherit" />} />
  //   // ) : null;

  //   return (
  //     <Box sx={{ display: 'flex', gap: 0.5, color: ({ palette: { primary } }: Theme): string => primary.light }}>
  //       {roadShipment}
  //       {airShipment}
  //       {/* Include seaShipment here when available */}
  //     </Box>
  //   )
  // }

  const getMappedEntity = (entity: ShipmentInsightDataProps): MegaTableRow['rowCells'] => {
    const cells = [
      {
        cellContent: replaceHighlight(entity.displayReference),
        cellHyperlink: getHyperlink(entity),
        cellIcons: getLeftShipmentIndicators(entity),
        cellLabels: getLabels(entity),
        cellSize: 2.4,
      },
    ]

    if (entity.entityType === 'PAIRING') return cells

    return [
      ...cells,
      // {
      //   cellContent: getShipmentType(entity),
      //   cellSize: 1,
      // },
      {
        cellContent: replaceHighlight(entity.comRefId),
      },
      {
        cellContent: entity.pickUp ? (
          <>
            {entity.pickUp?.early && (
              <KNTypography color="grey.600">
                {format(new Date(entity.pickUp?.early ?? ''), 'dd/MM/yyyy HH:mm')}
              </KNTypography>
            )}
            <KNTypography fontWeight={500} color="grey.600" sx={{ display: 'inline-block' }}>
              {replaceHighlight(entity.pickUp?.address?.name ?? '')}
            </KNTypography>{' '}
            {replaceHighlight(entity.pickUp?.address?.city ?? '')}{' '}
            {replaceHighlight(entity.pickUp?.address?.postalCode ?? '')}
          </>
        ) : (
          ''
        ),
        cellFlag: entity.pickUp?.address.country,
        cellSize: 3,
      },
      {
        cellContent: entity.delivery ? (
          <>
            {entity.delivery?.late && (
              <KNTypography color="grey.600">
                {format(new Date(entity.delivery?.late ?? ''), 'dd/MM/yyyy HH:mm')}
              </KNTypography>
            )}
            <KNTypography fontWeight={500} color="grey.600" sx={{ display: 'inline-block' }}>
              {replaceHighlight(entity.delivery?.address?.name ?? '')}
            </KNTypography>{' '}
            {replaceHighlight(entity.delivery?.address?.city) ?? ''}{' '}
            {replaceHighlight(entity.delivery?.address?.postalCode ?? '')}
          </>
        ) : (
          ''
        ),
        cellFlag: entity.delivery?.address.country,
        cellSize: 3,
      },
    ]
  }

  const getRibbon = (entity: any): string | undefined => {
    const matches = [
      'displayReference',
      'comRefId',
      'delivery.address.name',
      'delivery.address.city',
      'delivery.address.postalCode',
      'pickup.address.name',
      'pickup.address.city',
      'pickup.address.postalCode',
    ]

    const highlightToLowerCase = highlight?.toLocaleLowerCase()

    const isMatch = matches.some((path) => {
      const keys = path.split('.')
      let value = entity

      for (const key of keys) {
        if (value?.[key]) {
          value = value[key]
        } else {
          return false
        }
      }

      return value?.toLocaleLowerCase().includes(highlightToLowerCase)
    })

    if (highlight && !isMatch) {
      return `${highlight} ${translation.ribbon as string}`
    }
  }

  const mappedEntities = allEntities.map((entity, index) => ({
    rowId: index,
    cid: entity.cid,
    pairingCid: entity.pairingCid,
    followed: entity.hasMonitoringRules,
    rowCells: getMappedEntity(entity),
    menuOptions: getMenuOptions(entity),
    indicators: getRightShipmentIndicators(entity),
    ribbon: getRibbon(entity),
  }))

  // Order data items by creation date, newest first
  const dataByCreationDate = mappedEntities.sort((a, b) => a.rowId - b.rowId)
  return dataByCreationDate
}
