import React, { useState, useEffect, useRef, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { parseISO, isPast, isThisYear, differenceInHours } from 'date-fns'

// @mui imports
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Collapse from '@mui/material/Collapse'

// KN imports
import { outlineAnimation } from 'global/helpers/animations'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import { KNDataCardAction } from 'components/KN_Molecules/KNDataCard/types'
import { KNCustomsIcon } from 'components/KN_Molecules/KNIcon/KNMaterialIcon'
import StatusUpdateDialog from 'screens/StatusManager/StatusUpdateDialog'
import LocationLink from 'screens/TripDashboard/LocationLink'
import StopHeader from './StopHeader'
import PrimaryActions from './PrimaryActions'
import { LegCardCustomsStopProps } from './LegCard.types'
import { StopData } from './TripDetails.types'

const LegCardCustomsStop = ({
  trip,
  leg,
  stop,
  weblinkToken,
  onChange,
  collapsed = false,
}: LegCardCustomsStopProps): ReactElement => {
  const { t } = useTranslation()
  const location = useLocation<{ wayPointCids: string[] }>()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [statusUpdateDialogOpen, setStatusUpdateDialogOpen] = useState(false)
  const [isHighlighted, setIsHighlighted] = useState(false)
  const cardRef = useRef<HTMLDivElement>(null)

  const handleStatusUpdateClick = useCallback((): void => setStatusUpdateDialogOpen(true), [])
  const handleStatusUpdateAction = useCallback((updatedStops: StopData[]): void => {
    setStatusUpdateDialogOpen(false)
    onChange?.(updatedStops)
  }, [])
  const handleStatusUpdateClose = useCallback((): void => {
    setStatusUpdateDialogOpen(false)
  }, [])

  const actions: KNDataCardAction[] = []
  if (stop.availableStatuses.length > 0) {
    actions.push({
      label: t('trip_details.card.actions.update_status'),
      handler: handleStatusUpdateClick,
    })
  }

  useEffect(() => {
    setIsHighlighted(location.state?.wayPointCids.includes(stop.wayPointCid))
  }, [location.state])

  useEffect(() => {
    if (isHighlighted) {
      cardRef.current?.scrollIntoView()
    }
  }, [isHighlighted])

  return (
    <>
      <Box
        ref={cardRef}
        sx={{
          width: '100%',
          padding: { xs: 2, md: 0 },
          animation: isHighlighted ? outlineAnimation() : undefined,
        }}
      >
        <Stack
          spacing={{ xs: 0, md: 2 }}
          direction={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'stretch', md: 'center' }}
        >
          <Box sx={{ minWidth: '16rem' }}>
            <StopHeader stop={stop} />
          </Box>
          <Collapse in={!collapsed} timeout={200} sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 0, md: 3 }} alignItems={{ md: 'center' }}>
              <Grid item xs={12} sm={6} md={4}>
                <KNTypography variant="textMD_SB" color="primary.light">
                  {t('trip_details.card.location')}
                </KNTypography>
                <KNTypography color="text.main" fontSize="textLG">
                  {stop.address.name.length > 0 ? stop.address.name.join(' ') : t('waypoints.types.CUS')}
                </KNTypography>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <KNTypography variant="textMD_SB" color="primary.light">
                  {t('trip_details.card.address')}
                </KNTypography>
                <LocationLink
                  countryCode={stop.address.countryCode}
                  city={stop.address.city.join(', ')}
                  zipCode={stop.address.zipCode}
                  street={stop.address.street.join(', ')}
                  marker={
                    stop.geoPoint
                      ? {
                          coords: {
                            lat: stop.geoPoint.latitude,
                            lng: stop.geoPoint.longitude,
                          },
                          type: stop.type,
                        }
                      : undefined
                  }
                  lineBreaks={isMobile ? 'all' : 'city'}
                />
              </Grid>
            </Grid>
          </Collapse>
          {actions.length > 0 && <PrimaryActions actions={actions} sx={{ paddingTop: { xs: 2, md: 0 } }} />}
        </Stack>
      </Box>

      <StatusUpdateDialog
        payload={{
          trip,
          leg,
          stop,
          weblinkToken,
        }}
        open={statusUpdateDialogOpen}
        onAction={handleStatusUpdateAction}
        onClose={handleStatusUpdateClose}
      />
    </>
  )
}

export default LegCardCustomsStop
