import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import { SxProps } from '@mui/system'
import Stack from '@mui/material/Stack'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import StraightenIcon from '@mui/icons-material/Straighten'

// KN imports
import KNAttribute from 'components/KN_Molecules/KNAttribute/KNAttribute'
import { KNWeightIcon, KNVolumeIcon, KNPalletIcon } from 'components/KN_Molecules/KNIcon/KNMaterialIcon'
import { TripData } from './TripDashboard.types'

export interface TripCargoProps {
  trip: TripData
  sx?: SxProps
}

const TripCargo = ({ trip, sx }: TripCargoProps): ReactElement => {
  const { t } = useTranslation()
  return (
    <Stack direction="row" alignItems="start" sx={{ ...sx, flexWrap: 'wrap', columnGap: 1, rowGap: 0 }}>
      <KNAttribute
        name="total-items"
        value={trip.totalItems}
        suffix={t('trip_details.card.items_count', { count: trip.totalItems })}
        icon={<Inventory2OutlinedIcon />}
      />
      <KNAttribute name="total-weight" value={trip.totalWeight} suffix="kg" icon={<KNWeightIcon />} />
      <KNAttribute name="total-volume" value={trip.totalVolume} suffix="m³" icon={<KNVolumeIcon />} />
      <KNAttribute
        name="total-loading-meters"
        value={trip.totalLoadingMeters}
        suffix={t('trip_details.card.ldm')}
        icon={<StraightenIcon />}
      />
      <KNAttribute
        name="total-number-of-pallets"
        value={trip.totalNumberOfPallets}
        suffix={t('trip_details.card.pallets_count', { count: trip.totalNumberOfPallets })}
        icon={<KNPalletIcon />}
        hideEmpty
      />
    </Stack>
  )
}

export default TripCargo
