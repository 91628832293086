// Modules
import ShipmentPairer from 'modules/ShipmentPairer/ShipmentPairer'
import Geofence from 'screens/Geofence/Geofence'
import MonitoringRuleForm from 'modules/MonitoringRuleForm/MonitoringRuleForm'

// Data
import { shipmentDetailsMenuTranslations } from './ShipmentDetailsMenu.data'

// Functional
import { analyticsEvent } from 'global/helpers/analytics'
import { sharePairing, shareShipment, unpairShipment } from './ShipmentDetailsMenu.service'

// Types
import { ShareShipmentResponse, ShipmentDetailsMenuOptionProps } from './ShipmentDetailsMenu.types'

// Feature flags
import { notificationCenterFeatureFlag } from 'FeatureFlags'

export const getShipmentDetailsMenuOptions = (
  shipmentId: string,
  shipmentFollowed: boolean,
  pairingCid?: string,
  unpairingDate?: string,
  comRefId?: string,
  level?: string,
  entitytype?: string,
  sendingApplication?: string,
  trackingNumber?: string
): ShipmentDetailsMenuOptionProps[] => {
  const href = window.location.href
  // Translated Data //
  const { translation } = shipmentDetailsMenuTranslations()

  // Feature flags
  const notificationCenterFlag = Boolean(notificationCenterFeatureFlag())

  const shipmentLevelIIIOptions =
    pairingCid && !unpairingDate && level === 'Max'
      ? [
          {
            icon: 'link',
            name: translation.editPairing,
            withModal: <ShipmentPairer id={pairingCid} editMode={true} modalMode />,
          },
          {
            icon: 'link_off',
            name: translation.unpairShipment,
            onClick: async (): Promise<void> => {
              await unpairShipment(pairingCid || '')
              analyticsEvent('polestar_cv_shipment_details_unpair_shipment', [pairingCid])
            },
          },
        ]
      : []

  const isPairingOnly = entitytype === 'P' && pairingCid
  const shareShipmentOption = [
    {
      icon: 'reply',
      name: translation.shareShipment,
      optionMessage: `${translation.shareShipmentMessage as string}`,
      noRefresh: true,
      onClick: isPairingOnly
        ? async (): Promise<any> =>
            sharePairing(pairingCid).then((response) => {
              const link = getShareLink(href, response)
              void navigator.clipboard.writeText(link)
              return response.password
            })
        : async (): Promise<any> =>
            shareShipment(shipmentId).then((response) => {
              getShareLink(href, response)
              const link = getShareLink(href, response)
              void navigator.clipboard.writeText(link)
              return response.password
            }),
    },
  ]

  const followOption = notificationCenterFlag
    ? [
        {
          icon: 'visibility',
          name: translation.followShipment,
          withModal: (
            <MonitoringRuleForm
              modalMode={{
                sendingApplication: sendingApplication ?? '',
                trackingNumber: trackingNumber ?? '',
                cid: shipmentId,
              }}
              forceRefresh={true}
            />
          ),
        },
      ]
    : []

  const shipmentDetailsMenuOptions =
    shipmentId && comRefId
      ? [
          ...shareShipmentOption,
          ...followOption,
          {
            icon: 'map',
            name: translation.geofence,
            withModal: <Geofence />,
          },
          ...shipmentLevelIIIOptions,
        ]
      : [...shareShipmentOption, ...shipmentLevelIIIOptions]
  return shipmentDetailsMenuOptions
}

export const getShareLink = (href: string, response: ShareShipmentResponse): string => {
  const externalHref = href.replace('shipment-details', 'share_shipment')
  return String(`${externalHref}/${response.tokenId}`)
}
