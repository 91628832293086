import { fetchDelete, fetchGet, fetchPost, fetchPut } from 'global/helpers/fetchWrapper'

// Types
import { Company } from 'context/authentication/Company.types'
import { CreateOrEditCompanyFormValues } from './CreateOrEditCompanyDialog'

export const getCompanies = async (): Promise<Company[]> => {
  const companies = await fetchGet('companies')
  return companies.sort(sortCompanies)
}

export const createCompany = async (data: CreateOrEditCompanyFormValues): Promise<void> =>
  await fetchPost('companies', data)

export const editCompany = async (cid: string, data: CreateOrEditCompanyFormValues): Promise<void> => {
  const processedData = {
    ...data,
    companyCid: cid,
  }
  return await fetchPut(`companies/${cid}`, processedData)
}

export const deleteCompany = async (cid: string): Promise<void> => await fetchDelete(`companies/${cid}`)

export const sortCompanies = (a, b) =>
  a.displayName === b.displayName ? 0 : a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1
