import { useState, useEffect, useContext, ReactElement } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

// @mui imports
import Stack from '@mui/material/Stack'
import DialogContentText from '@mui/material/DialogContentText'
import Alert from '@mui/material/Alert'

// KN imports
import { analyticsEvent } from 'global/helpers/analytics'
import { getRouteName } from 'global/helpers/activeRoute'
import { sleep } from 'global/helpers/sleep'
import { TripListContext } from 'context/trips/TripListContext'
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import { processServerErrorMessages } from 'global/helpers/form'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import KNFormAutocomplete from 'components/KN_Molecules/KNForm/KNFormAutocomplete'
import { KNOptionProps } from 'components/KN_Molecules/KNForm/types'
import { TripData } from './TripDashboard.types'
import { assignDriver } from './TripDashboard.service'
import { getUpdatedTripForAssignDriver } from './TripDashboard.helpers'

interface AssignDriverDialogPayload {
  trip: TripData
}

interface AssignDriverDialogProps {
  payload: AssignDriverDialogPayload
  open: boolean
  onAction: (updatedTrip: TripData) => void
  onClose: () => void
}

export interface AssignDriverFormValues {
  driverCid: string | null
}

const AssignDriverDialog = ({ payload, open, onAction, onClose }: AssignDriverDialogProps): ReactElement => {
  const { t } = useTranslation()
  const location = useLocation()
  const [tripListState] = useContext(TripListContext)
  const [options, setOptions] = useState<KNOptionProps[]>([])
  const { handleSubmit, reset, control, formState, setError } = useForm<AssignDriverFormValues>()

  useEffect(() => {
    setOptions(
      tripListState.drivers.map((driver) => {
        return {
          value: driver.cid,
          label: driver.name,
        }
      })
    )
  }, [tripListState.drivers])

  useEffect(() => {
    reset({
      driverCid: payload.trip.assignedDriverId ?? null,
    })
  }, [payload])

  const onSubmit: SubmitHandler<AssignDriverFormValues> = async (data: AssignDriverFormValues) => {
    try {
      await assignDriver(payload.trip.entityId, data)
      onAction(getUpdatedTripForAssignDriver(payload.trip, data))
      analyticsEvent('polestar_cs_driver_assigned', [getRouteName(location.pathname)])
    } catch (error) {
      setError('root', processServerErrorMessages(error))
    }
  }

  return (
    <KNDialog
      open={open}
      onClose={onClose}
      closeLabel={t('general.cancel')}
      preventClosing={formState.isSubmitting}
      title={`${payload.trip.voyageNumber} — ${t('trip_dashboard.card.actions.assign_driver')}`}
      maxWidth="xs"
      actions={
        <>
          <KNLoadingButton
            type="submit"
            color="primary"
            variant="contained"
            loading={formState.isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            {t('trip_dashboard.assign_driver.assign')}
          </KNLoadingButton>
        </>
      }
      onSubmit={handleSubmit(onSubmit)}
    >
      <KNDialogFormErrors errors={formState.errors?.root} />
      <Stack spacing={2}>
        <KNFormAutocomplete
          name="driverCid"
          label={t('trip_dashboard.assign_driver.driver')}
          control={control}
          rules={{
            required: t('form.validation.required'),
          }}
          options={options}
        />
      </Stack>
    </KNDialog>
  )
}

export default AssignDriverDialog
