import { ReactElement } from 'react'
import i18n from 'i18next'

// @mui imports
import Chip, { ChipProps } from '@mui/material/Chip'

export interface StopStatusChipProps {
  statusId: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

export const getLabel = (statusId?: string): string => {
  switch (statusId) {
    case 'PUP':
      return i18n.t('trip_details.statuses.pickup_new')
    case '0500':
      return i18n.t('trip_details.statuses.pickup_done')
    case '0501_CI01':
      return i18n.t('trip_details.statuses.pickup_refused')
    case '0501_CI09':
      return i18n.t('trip_details.statuses.pickup_not_done')
    case '0502_DD01':
      return i18n.t('trip_details.statuses.pickup_short')
    case '0502_DD02':
      return i18n.t('trip_details.statuses.pickup_damaged')
    case '0490':
      return i18n.t('trip_details.statuses.pickup_gate_in')
    case '0491':
      return i18n.t('trip_details.statuses.pickup_gate_out')
    case 'CUS':
      return i18n.t('trip_details.statuses.customs_new')
    case '1500':
      return i18n.t('trip_details.statuses.customs_started')
    case '1600':
      return i18n.t('trip_details.statuses.customs_completed')
    case '1567':
      return i18n.t('trip_details.statuses.customs_gate_in')
    case '1568':
      return i18n.t('trip_details.statuses.customs_gate_out')
    case 'DEL':
      return i18n.t('trip_details.statuses.delivery_new')
    case '3000':
      return i18n.t('trip_details.statuses.delivery_done')
    case '3001_DI03':
      return i18n.t('trip_details.statuses.delivery_refused')
    case '3001_DI28':
      return i18n.t('trip_details.statuses.delivery_not_done')
    case '3002_DD01':
      return i18n.t('trip_details.statuses.delivery_short')
    case '3002_DD02':
      return i18n.t('trip_details.statuses.delivery_damaged')
    case '2990':
      return i18n.t('trip_details.statuses.delivery_gate_in')
    case '2991':
      return i18n.t('trip_details.statuses.delivery_gate_out')
    default:
      return i18n.t('trip_details.statuses.unknown')
  }
}

export const getComponentColor = (statusId?: string): ChipProps['color'] => {
  switch (statusId) {
    case 'PUP':
    case 'CUS':
    case 'DEL':
    case '1500':
      return 'secondary'
    case '0500':
    case '3000':
    case '1600':
    case '0491':
    case '2991':
    case '1568':
      return 'success'
    case '0501_CI01':
    case '0501_CI09':
    case '3001_DI03':
    case '3001_DI28':
      return 'error'
    case '0502_DD01':
    case '0502_DD02':
    case '3002_DD01':
    case '3002_DD02':
      return 'warning'
    case '0490':
    case '2990':
    case '1567':
    default:
      return 'default'
  }
}

const StopStatusChip = ({ statusId, onClick }: StopStatusChipProps): ReactElement => {
  return <Chip label={getLabel(statusId)} size="small" color={getComponentColor(statusId)} onClick={onClick} />
}

export default StopStatusChip
