import { useState, useEffect, useContext, ReactElement } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

// @mui imports
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import DialogContentText from '@mui/material/DialogContentText'
import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'

// KN imports
import { analyticsEvent } from 'global/helpers/analytics'
import { getRouteName } from 'global/helpers/activeRoute'
import { sleep } from 'global/helpers/sleep'
import { TripListContext } from 'context/trips/TripListContext'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import { processServerErrorMessages } from 'global/helpers/form'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import KNFormAutocomplete from 'components/KN_Molecules/KNForm/KNFormAutocomplete'
import KNFormSwitch from 'components/KN_Molecules/KNForm/KNFormSwitch'
import { KNOptionProps } from 'components/KN_Molecules/KNForm/types'
import TrackingLabel from 'screens/VehicleManager/TrackingLabel'
import { TripData } from './TripDashboard.types'
import { assignVehicle } from './TripDashboard.service'
import { getUpdatedTripForAssignVehicle } from './TripDashboard.helpers'

interface AssignVehicleDialogPayload {
  trip: TripData
}

interface AssignVehicleDialogProps {
  payload: AssignVehicleDialogPayload
  open: boolean
  onAction: (updatedTrip: TripData) => void
  onClose: () => void
}

export interface AssignVehicleFormValues {
  licensePlate: string | null
  isTrackable: boolean
  trailerLicensePlate?: string | null
  trailerIsTrackable?: boolean
  withTrailer: boolean
}

const AssignVehicleDialog = ({ payload, open, onAction, onClose }: AssignVehicleDialogProps): ReactElement => {
  const { t } = useTranslation()
  const location = useLocation()
  const [tripListState] = useContext(TripListContext)
  const [options, setOptions] = useState<KNOptionProps[]>([])
  const { handleSubmit, watch, reset, control, formState, setValue, setError } = useForm<AssignVehicleFormValues>()
  const watchedLicensePlate = watch('licensePlate')
  const watchedTrailerLicensePlate = watch('trailerLicensePlate')
  const watchedWithTrailer = watch('withTrailer')

  useEffect(() => {
    setOptions(
      tripListState.vehicles.map((vehicle) => {
        return {
          value: vehicle.licensePlate,
          label: vehicle.displayLicensePlate,
          payload: {
            telematicsReady: vehicle.telematicsReady,
            vehicleType: vehicle.vehicleType,
          },
        }
      })
    )
  }, [tripListState.vehicles])

  useEffect(() => {
    reset(
      payload.trip.secondaryAssignedVehicle
        ? {
            licensePlate: payload.trip.secondaryAssignedVehicleLicensePlate,
            trailerLicensePlate: payload.trip.assignedVehicleLicensePlate,
            withTrailer: true,
          }
        : {
            licensePlate: payload.trip.assignedVehicleLicensePlate,
            trailerLicensePlate: null,
            withTrailer: false,
          }
    )
  }, [payload])

  useEffect(() => {
    const matchedOption = options.find((option) => option.value === watchedLicensePlate)
    setValue('isTrackable', matchedOption?.payload.telematicsReady)
  }, [watchedLicensePlate])

  useEffect(() => {
    const matchedOption = options.find((option) => option.value === watchedTrailerLicensePlate)
    setValue('trailerIsTrackable', matchedOption?.payload.telematicsReady)
  }, [watchedTrailerLicensePlate])

  const handleWithTrailerChange = () => {
    reset({
      licensePlate: null,
      trailerLicensePlate: null,
      withTrailer: !watchedWithTrailer,
    })
  }

  const onSubmit: SubmitHandler<AssignVehicleFormValues> = async (data: AssignVehicleFormValues) => {
    try {
      await assignVehicle(payload.trip.entityId, data)
      onAction(getUpdatedTripForAssignVehicle(payload.trip, data))
      analyticsEvent('polestar_cs_vehicle_assigned', [getRouteName(location.pathname)])
    } catch (error) {
      setError('root', processServerErrorMessages(error))
    }
  }

  return (
    <KNDialog
      open={open}
      onClose={onClose}
      closeLabel={t('general.cancel')}
      preventClosing={formState.isSubmitting}
      title={`${payload.trip.voyageNumber} — ${t('trip_dashboard.card.actions.assign_vehicle')}`}
      maxWidth="xs"
      actions={
        <>
          <KNLoadingButton
            type="submit"
            color="primary"
            variant="contained"
            loading={formState.isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            {t('trip_dashboard.assign_vehicle.assign')}
          </KNLoadingButton>
        </>
      }
      onSubmit={handleSubmit(onSubmit)}
    >
      <KNDialogFormErrors errors={formState.errors?.root} />
      <Stack spacing={2}>
        <KNFormSwitch
          name="withTrailer"
          label={t('trip_dashboard.assign_vehicle.with_trailer')}
          control={control}
          onChange={handleWithTrailerChange}
        />
        <Box>
          <Collapse in={!watchedWithTrailer} unmountOnExit>
            <KNFormAutocomplete
              name="licensePlate"
              label={t('trip_dashboard.assign_vehicle.vehicle')}
              control={control}
              rules={{
                required: t('form.validation.required'),
              }}
              renderOption={(props, option): ReactElement => (
                <Box component="li" {...props} key={option.value}>
                  <Stack spacing={1} direction="row" alignItems="center">
                    {option.payload?.telematicsReady && <TrackingLabel />}
                    <Box>{option.label}</Box>
                  </Stack>
                </Box>
              )}
              options={options}
            />
          </Collapse>
          <Collapse in={watchedWithTrailer} unmountOnExit>
            <Stack spacing={2}>
              <KNFormAutocomplete
                name="licensePlate"
                label={t('vehicle_manager.vehicle_types.TRUCK')}
                control={control}
                rules={{
                  required: t('form.validation.required'),
                }}
                renderOption={(props, option): ReactElement => (
                  <Box component="li" {...props} key={option.value}>
                    <Stack spacing={1} direction="row" alignItems="center">
                      {option.payload?.telematicsReady && <TrackingLabel />}
                      <Box>{option.label}</Box>
                    </Stack>
                  </Box>
                )}
                options={options.filter((option) => option.payload?.vehicleType === 'TRUCK')}
              />
              <KNFormAutocomplete
                name="trailerLicensePlate"
                label={t('vehicle_manager.vehicle_types.TRAILER')}
                control={control}
                rules={{
                  required: t('form.validation.required'),
                }}
                renderOption={(props, option): ReactElement => (
                  <Box component="li" {...props} key={option.value}>
                    <Stack spacing={1} direction="row" alignItems="center">
                      {option.payload?.telematicsReady && <TrackingLabel />}
                      <Box>{option.label}</Box>
                    </Stack>
                  </Box>
                )}
                options={options.filter((option) => option.payload?.vehicleType === 'TRAILER')}
              />
            </Stack>
          </Collapse>
        </Box>
      </Stack>
    </KNDialog>
  )
}

export default AssignVehicleDialog
