import { fetchGet, fetchPost } from 'global/helpers/fetchWrapper'

// Types
import {
  CreatePredefinedScenarioFormValues,
  CreateShipmentPayloadValues,
  GeoPoint,
  PlaygroundResponse,
} from './Playground.types'

export const createPredefinedScenario = async (data: CreatePredefinedScenarioFormValues): Promise<PlaygroundResponse> =>
  await fetchPost('generate/scenario', data, {}, 'playground')

export const createShipment = async (data: CreateShipmentPayloadValues): Promise<PlaygroundResponse> =>
  await fetchPost('generate/shipment', data, {}, 'playground')

export const geocodeAddress = async (data: string): Promise<GeoPoint> =>
  await fetchGet(`geocode${data}`, {}, 'playground')
