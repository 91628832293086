import { ReactElement } from 'react'
import { Switch, Redirect } from 'react-router-dom'

// @mui imports
import useMediaQuery from '@mui/material/useMediaQuery'
import Stack from '@mui/material/Stack'

// KN components
import theme from 'assets/theme'
import GuardedRoute from './GuardedRoute'
import OperatorAdminRoute from './OperatorAdminRoute'

// Screens Shared
import DrawerMenu from 'modules/DrawerMenu/DrawerMenu'
import TopNavigation from 'modules/TopNavigation/TopNavigation'
import Support from 'modules/Support/Support'
import ProfilePage from '../ProfilePage/ProfilePage'
import ReleaseNotes from 'screens/ReleaseNotes/ReleaseNotes'
import UserManager from '../UserManager/UserManager'
import CompanyManager from '../CompanyManager/CompanyManager'
import CarrierManager from '../CarrierManager/CarrierManager'

// Screens CS
import CSRoot from './CSRoot'
import DriverManager from '../DriverManager/DriverManager'
import VehicleManager from '../VehicleManager/VehicleManager'

// Screens CV
import CVRoot from './CVRoot'
import PairShipment from '../PairShipment/PairShipment'
import NotificationCenter from 'screens/NotificationCenter/NotificationCenter'
import MonitoringRuleCreateOrEdit from 'screens/MonitoringRuleCreateOrEdit/MonitoringRuleCreateOrEdit'
import Playground from 'screens/Playground/Playground'
import PairDevices from 'screens/PairDevices/PairDevices'

// Functional
import ScrollToTop from 'hooks/scrollToTop'
import { getStartPage } from 'context/authentication/User.helpers'

// Context
import { useMenuContext } from 'context/menu/MenuContext'
import { NavigationContextProvider } from 'context/navigation/NavigationContext'
import { SearchContextProvider } from 'context/search/SearchContext'
import { DriverListProvider } from 'context/drivers/DriverListContext'
import { VehicleListProvider } from 'context/vehicles/VehicleListContext'
import { UserListProvider } from 'context/users/UserListContext'
import { CompanyListProvider } from 'context/companies/CompanyListContext'
import { CarrierListProvider } from 'context/carriers/CarrierListContext'

// Feature flags
import { scannerFeatureFlag } from 'FeatureFlags'
import { notificationCenterFeatureFlag } from 'FeatureFlags'

const Home = (): ReactElement => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const startPage = getStartPage()

  // Drawer Menu
  const { menuState } = useMenuContext()
  const { menuExtended } = menuState.menuContext

  // Feature flags
  const notificationCenterFlag = notificationCenterFeatureFlag()
  const scannerFlag = scannerFeatureFlag()

  return (
    <>
      <NavigationContextProvider>
        <SearchContextProvider>
          <ScrollToTop />
          <DrawerMenu />
          <div
            style={{
              position: 'fixed',
              height: '100%',
              width: '100%',
              backgroundColor: 'white.main',
              backgroundImage: `url(${process.env.PUBLIC_URL}/img/bg.png)`,
              backgroundSize: 'contain',
              backgroundPosition: '130% 300px',
              backgroundRepeat: 'no-repeat',
              pointerEvents: 'none',
              zIndex: -1,
            }}
          />
          <Stack
            direction="column"
            paddingBottom={4}
            sx={{
              marginLeft: isMobile ? 0 : menuExtended ? '270px' : '120px',
              marginRight: isMobile ? 0 : '16px',
              transition: 'margin-left 0.1s',
              minHeight: '100vh',
            }}
          >
            <TopNavigation />
            <Switch>
              <GuardedRoute exact path="/">
                <Redirect to={startPage} />
              </GuardedRoute>
              {scannerFlag ? (
                <GuardedRoute exact path="/pairing" visibilityGuard="Max" excludedRoles={['Viewer']}>
                  <PairDevices />
                </GuardedRoute>
              ) : (
                <GuardedRoute exact path="/pairing" visibilityGuard="Max" excludedRoles={['Viewer']}>
                  <PairShipment />
                </GuardedRoute>
              )}
              <GuardedRoute exact path="/drivers" companyTypeGuard="Carrier">
                <Support moduleName="cs" />
                <DriverListProvider>
                  <DriverManager />
                </DriverListProvider>
              </GuardedRoute>
              <GuardedRoute exact path="/vehicles" companyTypeGuard="Carrier">
                <Support moduleName="cs" />
                <VehicleListProvider>
                  <VehicleManager />
                </VehicleListProvider>
              </GuardedRoute>
              <GuardedRoute exact path="/users" adminOnly>
                <UserListProvider>
                  <UserManager />
                </UserListProvider>
              </GuardedRoute>
              <GuardedRoute exact path="/companies" adminOnly>
                <CompanyListProvider>
                  <CompanyManager />
                </CompanyListProvider>
              </GuardedRoute>
              <GuardedRoute exact path="/carriers" adminOnly>
                <CarrierListProvider>
                  <CarrierManager />
                </CarrierListProvider>
              </GuardedRoute>
              <GuardedRoute exact path="/profile" noRedirect>
                <ProfilePage />
              </GuardedRoute>
              {notificationCenterFlag && (
                <GuardedRoute exact path="/notification-center" noRedirect>
                  <NotificationCenter />
                </GuardedRoute>
              )}
              {notificationCenterFlag && (
                <GuardedRoute exact path="/notification-center/:action/:ruleCid?" noRedirect>
                  <MonitoringRuleCreateOrEdit />
                </GuardedRoute>
              )}
              <OperatorAdminRoute exact path="/playground">
                <Playground />
              </OperatorAdminRoute>
              <GuardedRoute path="/help&info" noRedirect>
                <ReleaseNotes />
              </GuardedRoute>
              <>
                <CSRoot />
                <CVRoot />
              </>
            </Switch>
          </Stack>
        </SearchContextProvider>
      </NavigationContextProvider>
    </>
  )
}

export default Home
