import { SubmitHandler, useForm } from 'react-hook-form'
import { useState } from 'react'

// @mui imports
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import DialogContentText from '@mui/material/DialogContentText'
import Alert from '@mui/material/Alert'

// KN imports
import KNForm from 'components/KN_Molecules/KNForm/KNForm'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNFormText from 'components/KN_Molecules/KNForm/KNFormText'
import KNFormDropdown from 'components/KN_Molecules/KNForm/KNFormDropdown'
import KNFormCheckbox from 'components/KN_Molecules/KNForm/KNFormCheckbox'
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'

// Functional
import { getErrorMessage } from 'global/helpers/errorHandler'
import { createPredefinedScenario } from 'screens/Playground/Playground.service'
import { getScenarios, getUserRoles } from './PlaygroundForms.helpers'
import { regexEmail } from 'global/helpers/validators'
import { analyticsEvent } from 'global/helpers/analytics'

// Data
import { playgroundTranslations } from 'screens/Playground/Playground.data'

// Types
import { CreatePredefinedScenarioFormValues } from 'screens/Playground/Playground.types'

const CreatePlaygroundScenarioForm = (): React.ReactElement => {
  const [loading, setLoading] = useState(false)
  const [requestMessage, setRequestMessage] = useState<string>('')
  const { translation } = playgroundTranslations()
  const { handleSubmit, control, formState, setError, getValues } = useForm<CreatePredefinedScenarioFormValues>()

  const onSubmit: SubmitHandler<CreatePredefinedScenarioFormValues> = async (
    data: CreatePredefinedScenarioFormValues
  ) => {
    setLoading(true)
    setRequestMessage('')
    try {
      await createPredefinedScenario(data).then((response) => {
        setRequestMessage(String(response))
        analyticsEvent('polestar_cv_playground_predefined_scenario_created', [data.scenarioId])
      })
    } catch (error) {
      setError('root.server', {
        message: getErrorMessage(error),
      })
    }
    setLoading(false)
  }

  return loading ? (
    <KNLoader />
  ) : (
    <KNForm onSubmit={handleSubmit(onSubmit)}>
      <Stack data-test="predefined-scenario-container" spacing={3}>
        {formState.errors?.root?.server && (
          <DialogContentText component="div" mb={3}>
            <Alert severity="error">{formState.errors.root.server.message}</Alert>
          </DialogContentText>
        )}
        {requestMessage && (
          <DialogContentText component="div" mb={3}>
            <Alert severity="success">{requestMessage}</Alert>
          </DialogContentText>
        )}
        <Box mt={1}>
          <KNTypography variant="displayMD_SB" color="primary.focus">
            {translation.createPredefinedScenarioFormName}
          </KNTypography>
          <Box data-test="scenario-id-dropdown" mt={1} mb={2} display="flex" flexDirection={'column'}>
            <KNFormDropdown
              sx={{
                maxWidth: 'sm',
              }}
              name="scenarioId"
              label={translation.scenarioId}
              control={control}
              rules={{
                required: translation.required,
              }}
              options={getScenarios()}
              description={true}
            />
          </Box>
          <KNTypography variant="textLG_SB">{translation.companyData}</KNTypography>
          <Box data-test="company-data" mt={1}>
            <KNFormText
              name="company.name"
              label={translation.companyNameLabel}
              sx={{
                maxWidth: 'sm',
              }}
              rules={{
                required: translation.required,
              }}
              control={control}
            />
          </Box>
          <Stack
            spacing={1}
            mt={1}
            mb={2}
            sx={{
              maxWidth: 'sm',
            }}
          >
            <KNFormCheckbox name="company.createCompany" control={control} label={translation.checkboxCreateCompany} />
            <KNFormCheckbox
              name="company.companyAcknowledgement"
              control={control}
              label={translation.checkboxCompanyAcknowledgement}
              rules={{
                required: translation.required,
              }}
            />
          </Stack>
          <KNTypography variant="textLG_SB">{translation.userData}</KNTypography>
          <Box mt={1}>
            <KNFormText
              name="user.email"
              label={translation.userEmailLabel}
              sx={{
                maxWidth: 'sm',
              }}
              rules={{
                pattern: {
                  value: regexEmail,
                  message: translation.invalidEmail,
                },
                required: translation.required,
              }}
              control={control}
            />
          </Box>
          <Box data-test="role-dropdown" mt={1}>
            <KNFormDropdown
              sx={{
                maxWidth: 'sm',
              }}
              name="user.role"
              label={translation.userRoleLabel}
              control={control}
              rules={{
                required: translation.required,
              }}
              options={getUserRoles()}
            />
          </Box>
          <Stack
            spacing={1}
            mt={1}
            sx={{
              maxWidth: 'sm',
            }}
          >
            <KNFormCheckbox name={'user.createUser'} control={control} label={translation.checkboxCreateUser} />
            <KNFormCheckbox
              name="user.userAcknowledgement"
              control={control}
              label={translation.checkboxUserAcknowledgement}
              rules={{
                required: translation.required,
              }}
            />
          </Stack>
        </Box>
        <Box>
          <KNButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={formState.isSubmitted && Object.keys(formState.errors).length > 0 && !formState.errors.root}
            onClick={handleSubmit(onSubmit)}
            dataAttribute="save"
          >
            {translation.saveButtonText}
          </KNButton>
        </Box>
      </Stack>
    </KNForm>
  )
}

export default CreatePlaygroundScenarioForm
