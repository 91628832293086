import { useContext } from 'react'
import { toDate, format, utcToZonedTime } from 'date-fns-tz'
import { ChartData, ChartOptions } from 'chart.js'
import i18n from 'i18n'

// @mui imports
import theme from 'assets/theme'

// Context
import { UserContext } from 'context/authentication/UserContext'

// Types
import { TemperatureDataSetProps, TemperatureThresholdProps } from './TemperatureChart.types'

// COLOR LIST //
const colors = theme.palette
const colorsForChart = [
  colors.primary.main,
  colors.secondary.main,
  colors.success.main,
  colors.error.main,
  colors.warning.main,
  colors.success.light,
  colors.primary.dark,
  colors.info.main,
]

export const mapToChartData = (
  temperatureDataSets: TemperatureDataSetProps[],
  filteredIds?: string[]
): ChartData<'scatter'> => {
  const mappedTemperatureDataSets = temperatureDataSets
    .map((temperatureDataSet) => temperatureDataSet.deviceId)
    .filter((value, index, device) => device.indexOf(value) === index)
    .sort(function (a, b) {
      return a.localeCompare(b)
    })

  const temperatureDataSetsWithValues = mappedTemperatureDataSets.map((dataSet) => {
    return {
      values: temperatureDataSets.filter((temperatureDataSet) => temperatureDataSet.deviceId === dataSet),
    }
  })

  const valuesForTemperatureChart = temperatureDataSetsWithValues.map((dataSet) =>
    dataSet.values.map((props) => {
      return {
        id: props.deviceId,
        graphValues: {
          x: toDate(props.timestamp).getTime(),
          y: Number(props.value),
        },
      }
    })
  )

  const chartDataValues = {
    datasets: valuesForTemperatureChart.map((device, index) => {
      return {
        label: device[0].id,
        fill: false,
        lineTension: 0.1,
        borderWidth: 1,
        data: filteredIds?.includes(device[0].id) ? [] : device.map((set) => set.graphValues),
        borderColor: colorsForChart[index] || colorsForChart[0],
        backgroundColor: colorsForChart[index] || colorsForChart[0],
        visibile: filteredIds ? !filteredIds.includes(device[0].id) : true,
      }
    }),
  }

  return chartDataValues
}

export const getHighestTemperatureValue = (dataSets: TemperatureDataSetProps[]): number =>
  Math.max(...dataSets.map((dataSet) => Number(dataSet.value)))

export const getLowestTemperatureValue = (dataSets: TemperatureDataSetProps[]): number =>
  Math.min(...dataSets.map((dataSet) => Number(dataSet.value)))

export const getHighestTimestampValue = (dataSets: TemperatureDataSetProps[], filteredDateTo?: Date): number =>
  filteredDateTo
    ? toDate(filteredDateTo).getTime()
    : Math.max(...dataSets.map((dataSet) => toDate(dataSet.timestamp).getTime()))

export const getLowestTimestampValue = (dataSets: TemperatureDataSetProps[], filteredDateFrom?: Date): number =>
  filteredDateFrom
    ? toDate(filteredDateFrom).getTime()
    : Math.min(...dataSets.map((dataSet) => toDate(dataSet.timestamp).getTime()))

export const getChartOptions = (
  lowestXValue: number,
  highestXValue: number,
  lowestYValue: number,
  highestYValue: number,
  temperatureLabelVisible: boolean,
  setTemperatureLabelVisible: (state: boolean) => void,
  temperatureThreshold?: TemperatureThresholdProps
): ChartOptions<'scatter'> => {
  const thresholdMin = temperatureThreshold?.min
  const thresholdMax = temperatureThreshold?.max
  const yMin = Math.min(thresholdMin ?? thresholdMax ?? lowestYValue + 1, Math.ceil(lowestYValue))
  const yMax = Math.max(thresholdMax ?? thresholdMin ?? highestYValue - 1, Math.floor(highestYValue))
  const annotations: any = {}

  // Context //
  const { userTimezone } = useContext(UserContext)

  const tooltipOptions = {
    backgroundColor: 'rgb(255,255,255)',
    bodyColor: 'rgba(0, 0, 0, 1)',
    borderColor: 'rgb(255,255,255)',
    borderWidth: 1,
    callbacks: {
      label: (context: any): string => {
        const id = `${i18n.t('modules.cv.temperature_chart.label.id')}: ${context.dataset.label as string}`
        const yAxis = `${i18n.t('modules.cv.temperature_chart.label.y_axis')}: ${Number(context.raw.y)}`
        const xAxisDate = toDate(context.raw.x).toUTCString()
        const xAxis = `${i18n.t('modules.cv.temperature_chart.label.x_axis')}: ${format(
          utcToZonedTime(new Date(xAxisDate), userTimezone),
          'dd/MM HH:mm'
        )}`
        return `${id} ${yAxis} ${xAxis}`
      },
    },
  }

  if (thresholdMin !== undefined) {
    annotations.boxMin = {
      type: 'box',
      yMin: yMin - 2,
      yMax: thresholdMin,
      backgroundColor: 'rgba(178, 69, 93, 0.45)',
      borderWidth: 0,
      enter: () => setTemperatureLabelVisible(true),
      leave: () => setTemperatureLabelVisible(false),
    }
    annotations.labelMin = {
      type: 'label',
      yValue: thresholdMin - 1, // at the top
      content: `${i18n.t('modules.cv.temperature_chart.minimum_temperature')} ${thresholdMin}`,
      font: {
        size: 16,
      },
      color: theme.palette.common.white,
      display: temperatureLabelVisible,
      enter: () => setTemperatureLabelVisible(temperatureLabelVisible),
    }
  }

  if (thresholdMax !== undefined) {
    annotations.boxMax = {
      type: 'box',
      yMin: thresholdMax,
      yMax: yMax + 2,
      backgroundColor: 'rgba(178, 69, 93, 0.45)',
      borderWidth: 0,
      enter: () => setTemperatureLabelVisible(true),
      leave: () => setTemperatureLabelVisible(false),
    }
    annotations.labelMax = {
      type: 'label',
      yValue: thresholdMax + 1, // at the bottom
      content: `${i18n.t('modules.cv.temperature_chart.maximum_temperature')} ${thresholdMax}`,
      font: {
        size: 16,
      },
      color: theme.palette.common.white,
      display: temperatureLabelVisible,
      enter: () => setTemperatureLabelVisible(temperatureLabelVisible),
    }
  }

  const options = {
    plugins: {
      annotation: {
        annotations: annotations,
      },
      tooltip: tooltipOptions,
      legend: {
        display: false,
      },
    },
    animation: {
      duration: 0, // general animation time
    },
    showLine: true,
    scales: {
      x: {
        min: lowestXValue - 100000,
        max: highestXValue + 100000,
        ticks: {
          callback: (value: string | number): string => {
            const dateValue = toDate(value).toUTCString()
            return format(utcToZonedTime(new Date(dateValue), userTimezone), 'dd/MM HH:mm')
          },
        },
        grid: {
          color: theme.palette.grey[100],
          borderColor: theme.palette.grey[300],
        },
      },
      y: {
        min: yMin - 2,
        max: yMax + 2,
        grid: {
          color: theme.palette.grey[100],
          borderColor: theme.palette.grey[300],
        },
      },
    },
  }
  return options
}
