import { useState, useCallback, ReactElement } from 'react'
import { useHistory } from 'react-router-dom'

// @mui imports
import Box from '@mui/material/Box'

// KN import
import KNDataTable from 'components/KN_Molecules/KNDataTable/KNDataTable'
import DeleteMonitoringRuleDialog from './DeleteMonitoringRuleDialog'

// Functional
import { getActions, getColumns } from './MonitoringRulesTable.helpers'

// Types
import { MonitoringRuleRow, MonitoringRulesTableProps } from './MonitoringRulesTable.types'

const MonitoringRulesTable = ({ monitoringRules, onChange }: MonitoringRulesTableProps): ReactElement => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [activeRow, setActiveRow] = useState<MonitoringRuleRow>()

  const handleDeleteClick = useCallback((row: MonitoringRuleRow) => {
    setActiveRow(row)
    setDeleteDialogOpen(true)
  }, [])

  const handleDeleteDialogAction = useCallback((): void => {
    setDeleteDialogOpen(false)
    onChange?.()
  }, [])

  const handleDeleteDialogClose = useCallback((): void => {
    setDeleteDialogOpen(false)
  }, [])

  return (
    <Box data-test="monitoring-rules-table">
      <KNDataTable
        columns={getColumns()}
        actions={getActions(handleDeleteClick)}
        data={monitoringRules}
        sx={{
          marginX: -2,
        }}
      />
      {activeRow && (
        <DeleteMonitoringRuleDialog
          payload={{
            monitoringRule: activeRow,
          }}
          open={deleteDialogOpen}
          onAction={handleDeleteDialogAction}
          onClose={handleDeleteDialogClose}
        />
      )}
    </Box>
  )
}

export default MonitoringRulesTable
