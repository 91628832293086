import { useEffect, useState, useMemo, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { debounce } from 'lodash'
import FadeIn from 'react-fade-in'
import endOfDay from 'date-fns/endOfDay'
import startOfDay from 'date-fns/startOfDay'

// @mui imports
import Box from '@mui/material/Box'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import useMediaQuery from '@mui/material/useMediaQuery'

// KN Components
import theme from 'assets/theme'
import KNCard from 'components/KN_Molecules/KNCard/KNCard'
import KNFeatureWrapper from 'components/KN_Molecules/KNFeatureWrapper/KNFeatureWrapper'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import MegaTable from 'components/KN_Molecules/MegaTable/MegaTable'
import MegaTableFilters from 'components/KN_Molecules/MegaTable/MegaTableFilters'
import MegaTableHeaders from 'components/KN_Molecules/MegaTable/MegaTableHeaders'

// Functional
import { getErrorMessage } from 'global/helpers/errorHandler'
import { getAllShipmentsData } from './ShipmentsInsightData.service'
import { setDataForCards, setDataForMegaTable } from './ShipmentsInsightData.helpers'
import { getSelectedCompany } from 'context/authentication/User.helpers'
import ScrollTo from 'components/Molecules/ScrollTo/ScrollTo'

// Data
import { shipmentsInsightTranslations } from './ShipmentsInsightData.data'

//Context
import { useDashboardContext } from 'context/dashboard/DashboardContext'
import { useNavigationContext } from 'context/navigation/NavigationContext'
import { UserContext } from 'context/authentication/UserContext'

// Types
import KNCardProps from 'components/KN_Molecules/KNCard/KNCard.types'
import { MegaFilter, MegaTableRow } from 'components/KN_Molecules/MegaTable/MegaTable.types'
import DashboardContextProps from 'context/dashboard/DashboardContext.types'

// Feature flags
import { notificationCenterFeatureFlag } from 'FeatureFlags'

interface ShipmentsInsightDataProps {
  setLoading?: (loadingState: boolean) => void
}

const ShipmentsInsightData: React.FC<ShipmentsInsightDataProps> = (props) => {
  const history = useHistory()
  // Props
  const { setLoading } = props
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))
  // Restrictions
  const maxLevelAvailable = getSelectedCompany()?.properties?.visibilityLevel === 'Max'
  // Translated Data
  const { translation } = shipmentsInsightTranslations()
  const { dashboardState, dispatchDashboardState } = useDashboardContext()
  const {
    pageNumber,
    idFilterValue,
    statusFilterValue,
    dateFilterValue,
    dateRangeValue,
    counterFilterValue,
    sorter,
    sortingDirection,
  } = dashboardState.dashboardContext
  const { navigationState, dispatch } = useNavigationContext()
  const [dataLoading, setDataLoading] = useState(false)
  // Context //
  const { userTimezone } = useContext(UserContext)

  // Feature flags
  const notificationCenterFlag = Boolean(notificationCenterFeatureFlag())

  useEffect(() => {
    setLoading && setLoading(dataLoading)
  }, [dataLoading])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string | null>(null)
  const [event, setEvent] = useState('')
  const [shipmentsCardsData, setShipmentsCardData] = useState<KNCardProps[]>([])
  const [shipmentsTableData, setShipmentsTableData] = useState<MegaTableRow[]>([])

  // Sorters
  const megaTableSorters = [
    {
      displayName: translation.table.tableColumnOrderId,
      value: 'display_reference',
      size: 2.4,
    },
    // {
    //   displayName: translation.table.tableColumnType,
    //   value: 'type',
    //   size: 1,
    // },
    {
      displayName: translation.table.tableColumnShipmentId,
      value: 'com_ref_id',
      size: 2.4,
    },
    {
      displayName: translation.table.tableColumnPickupLocation,
      value: 'pick_up_date',
      size: 3,
    },
    {
      displayName: translation.table.tableColumnDeliveryLocation,
      value: 'delivery_date',
      size: 3,
    },
  ]
  const [activeSorter, setActiveSorter] = useState<string>(sorter ?? '')
  const [activeSortingDirection, setActiveSortingDirection] = useState<string>(sortingDirection ?? '')

  // Pagination
  const [activePage, setActivePage] = useState<number>(pageNumber ?? 1)
  const [totalItems, setTotalItems] = useState<number>(0)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [nextPage, setNextPage] = useState<boolean>(false)

  // Filters
  const [localIdFilter, setLocalIdFilter] = useState(idFilterValue)
  const [idFilter, setIdFilter] = useState(idFilterValue)
  const [statusFilter, setStatusFilter] = useState(statusFilterValue)
  const [dateFilter, setDateFilter] = useState(dateFilterValue)
  const [dateRange, setDateRange] = useState(dateRangeValue)

  // Counters
  const [currentCounter, setCurrentCounter] = useState(counterFilterValue)

  const resetScrollPosition = (): void =>
    dispatch({
      type: 'setNavigationProps',
      payload: {
        ...navigationState.navigationContext,
        scrollPosition: 0,
      },
    })

  const handleChangePage = (event: any, newPage: number): void => {
    setActivePage(newPage)
    dispatchDashboardState({
      type: 'setDashboardProps',
      payload: {
        ...dashboardState.dashboardContext,
        pageNumber: newPage,
      },
    })
    resetScrollPosition()
  }

  const handleSortingChange = (value: string, filters?: DashboardContextProps): void => {
    const getSortingDirection = (): string => {
      if (value !== dashboardState.dashboardContext.sorter) {
        return 'DESC'
      }
      if (
        value === dashboardState.dashboardContext.sorter &&
        dashboardState.dashboardContext.sortingDirection === 'DESC'
      ) {
        return 'ASC'
      }
      if (
        value === dashboardState.dashboardContext.sorter &&
        dashboardState.dashboardContext.sortingDirection === 'ASC'
      ) {
        return ''
      }
      return ''
    }
    setActiveSorter(value)
    setActiveSortingDirection(getSortingDirection())
    if (activePage > 1) setActivePage(1)
    dispatchDashboardState({
      type: 'setDashboardProps',
      payload: {
        ...dashboardState.dashboardContext,
        ...filters,
        counterFilterValue: undefined,
        sorter: value,
        sortingDirection: getSortingDirection(),
        pageNumber: 1,
      },
    })
  }

  const idChangeHandler = (value): void => {
    dispatchDashboardState({
      type: 'setDashboardProps',
      payload: {
        ...dashboardState.dashboardContext,
        pageNumber: 1,
        counterFilterValue: undefined,
        idFilterValue: value,
      },
    })
    setIdFilter(value)
  }
  const debouncedIdFilter = useMemo(() => debounce(idChangeHandler, 800), [dashboardState.dashboardContext])

  const handleIdFilterChange = (value: string): void => {
    setLocalIdFilter(value)
    debouncedIdFilter(value)
    if (activePage > 1) setActivePage(1)
    resetScrollPosition()
  }

  const handleStatusFilterChange = (value: string): void => {
    setStatusFilter(value)
    if (activePage > 1) setActivePage(1)
    dispatchDashboardState({
      type: 'setDashboardProps',
      payload: {
        ...dashboardState.dashboardContext,
        counterFilterValue: undefined,
        statusFilterValue: value,
        pageNumber: 1,
      },
    })
    resetScrollPosition()
  }

  const handleDateFilterChange = (value: string): void => {
    setDateFilter(value), setDateRange({ startDate: undefined, endDate: undefined })
    if (activePage > 1) setActivePage(1)
    dispatchDashboardState({
      type: 'setDashboardProps',
      payload: {
        ...dashboardState.dashboardContext,
        counterFilterValue: undefined,
        dateFilterValue: value,
        dateRangeValue: { startDate: undefined, endDate: undefined },
        pageNumber: 1,
      },
    })
    resetScrollPosition()
  }

  const handleDateRangeFilterChange = (value: [Date, Date]): void => {
    const [start, end] = value
    const newDateRange = { startDate: start, endDate: end }
    setDateRange(newDateRange)
    if (activePage > 1) setActivePage(1)
    dispatchDashboardState({
      type: 'setDashboardProps',
      payload: {
        ...dashboardState.dashboardContext,
        dateRangeValue: newDateRange,
        pageNumber: 1,
      },
    })
    resetScrollPosition()
  }

  const shipmentInsightFilters = [
    {
      type: 'Text' as MegaFilter,
      label: translation.filters.names.reference,
      value: localIdFilter,
      setValue: (value): void => handleIdFilterChange(value),
    },
    {
      type: 'Dropdown' as MegaFilter,
      label: translation.filters.names.status,
      placeholder: translation.filters.filterPlaceholders.status,
      dropdownOptions: [
        { label: translation.filters.status.AWAITING_PICKUP, value: 'AWAITING_PICKUP' },
        {
          label: translation.filters.status.IN_PROGRESS,
          value: 'IN_PROGRESS',
        },
        { label: translation.filters.status.DELIVERED, value: 'DELIVERED' },
        { label: translation.filters.status.CANCELLED, value: 'CANCELLED' },
      ],
      value: statusFilter,
      setValue: (value): void => handleStatusFilterChange(value),
    },
    {
      type: 'Dropdown' as MegaFilter,
      label: translation.filters.names.dateType,
      placeholder: translation.filters.filterPlaceholders.dateType,
      dropdownOptions: maxLevelAvailable
        ? [
            { label: translation.filters.dateFilters.pickup, value: 'PICK_UP' },
            { label: translation.filters.dateFilters.delivery, value: 'DELIVERY' },
            { label: translation.filters.dateFilters.pairing, value: 'PAIRING' },
            {
              label: translation.filters.dateFilters.unpairing,
              value: 'UNPAIRING',
            },
          ]
        : [
            { label: translation.filters.dateFilters.pickup, value: 'PICK_UP' },
            { label: translation.filters.dateFilters.delivery, value: 'DELIVERY' },
          ],
      value: dateFilter,
      setValue: (value): void => handleDateFilterChange(value),
    },
    {
      type: 'DateRangePicker' as MegaFilter,
      label: translation.filters.names.dateFrom,
      labelTo: translation.filters.names.dateTo,
      placeholder: !dateFilter ? translation.filters.filterPlaceholders.datePicker : '',
      disabled: !dateFilter,
      setValue: (value): void => handleDateRangeFilterChange(value),
      value: dateRange,
    },
  ]

  // URL parameters
  const getUrlParameters = (): void => {
    if (!history.location.search) return
    const parameters = history.location.search.substring(1)
    const parametersParsed = JSON.parse(
      '{"' + decodeURI(parameters).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'
    )
    if (parametersParsed.pageNumber) handleChangePage(null, parseInt(parametersParsed.pageNumber))
    if (parametersParsed.idFilter) handleIdFilterChange(parametersParsed.idFilter)
    if (parametersParsed.statusFilter) handleStatusFilterChange(parametersParsed.statusFilter)
    if (parametersParsed.dateFilter) handleDateFilterChange(parametersParsed.dateFilter)
    if (parametersParsed.dateRange) handleDateRangeFilterChange(parametersParsed.dateRange)

    history.replace({
      pathname: '/visibility-dashboard',
      state: {},
    })
  }

  // Reset effects
  useEffect(() => {
    if (counterFilterValue) {
      setActiveSorter('')
      setActiveSortingDirection('')
      setLocalIdFilter('')
      setIdFilter(undefined)
      setDateFilter(undefined)
      setDateRange({ startDate: undefined, endDate: undefined })
      setStatusFilter(undefined)
      setActivePage(1)
      setCurrentCounter(counterFilterValue)
    } else setCurrentCounter(undefined)
  }, [counterFilterValue])

  // Get data
  useEffect(() => {
    getUrlParameters()
    const fetchData = async (): Promise<void> => {
      let filterAvailable = ''
      let filterId = ''
      let filterDate = ''
      let filterStatus = ''
      let filterString = ''
      let sort = ''
      setDataLoading(true)
      // Filter string general
      if ((dateFilter && dateRange) || statusFilter || idFilter || counterFilterValue) {
        filterAvailable = '&'
      }
      // Filter string date range
      if (dateFilter && dateRange) {
        filterDate = `dateField=${dateFilter}&start=${startOfDay(
          new Date(dateRange.startDate ?? '') ?? ''
        ).toUTCString()}&end=${endOfDay(new Date(dateRange.endDate ?? '') ?? '').toUTCString()}`
      }
      if (statusFilter) {
        filterStatus = `status=${statusFilter}`
      }
      if (idFilter) {
        filterId = `reference=${idFilter}`
      }
      if (activeSorter && activeSortingDirection) {
        sort = `&sort=${activeSorter},${activeSortingDirection}`
      }

      const filters = [filterDate, filterStatus, filterId]
      const filterDefined = filters.filter((element) => {
        return element !== ''
      })
      filterString = currentCounter ? String(`counterType=${currentCounter}`) : String(`${filterDefined.join('&')}`)
      const currentPage = activePage ?? 1

      try {
        const allShipmentsData = await getAllShipmentsData(
          currentPage - 1,
          String(`${filterAvailable}${filterString}${sort && !counterFilterValue ? `${sort}` : ''}`)
        )
        setTotalItems(allShipmentsData.totalElements)
        setTotalPages(allShipmentsData.totalPages)
        setNextPage(allShipmentsData.totalPages > currentPage)
        setTotalPages(allShipmentsData.totalPages)
        if (mobileView) {
          const dataForCards = setDataForCards(allShipmentsData.content, setEvent, userTimezone, notificationCenterFlag)
          setShipmentsCardData(dataForCards)
        } else {
          const dataForMegaTable = setDataForMegaTable(
            allShipmentsData.content,
            setEvent,
            userTimezone,
            idFilter,
            notificationCenterFlag
          )
          setShipmentsTableData(dataForMegaTable)
        }
      } catch (error) {
        setError(getErrorMessage(error))
      }
      setDataLoading(false)
    }
    if (dateRange?.startDate && !dateRange?.endDate) return
    if (!dataLoading) {
      void fetchData()
    }
  }, [
    event,
    activePage,
    idFilter,
    dateFilter,
    dateRange,
    statusFilter,
    activeSorter,
    activeSortingDirection,
    currentCounter,
  ])

  return (
    <>
      <ScrollTo />
      {mobileView && shipmentsCardsData && (
        <>
          <KNTypography variant="displaySM_SB" color="primary.focus">
            {translation.general.moduleName}
          </KNTypography>
          <FadeIn>
            <Box mt={2} mb={4}>
              <MegaTableFilters megaTableFilters={shipmentInsightFilters} />
            </Box>
            <Stack direction="column" spacing={3} sx={{ marginBottom: 3 }}>
              {shipmentsCardsData.map((shipment, index) => (
                <KNCard {...shipment} key={index} />
              ))}
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <KNTypography data-test="number-items-mobile" variant="textLG" color="text.light">
                    {translation.general.total_items}: {totalItems}
                  </KNTypography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                  <Pagination
                    data-test="pagination-cards"
                    page={activePage}
                    count={totalPages}
                    hideNextButton={!nextPage}
                    shape="rounded"
                    onChange={handleChangePage}
                    sx={{
                      display: 'flex',
                    }}
                  />
                </Box>
              </Box>
            </Stack>
          </FadeIn>
        </>
      )}
      {!mobileView && shipmentsTableData && (
        <KNFeatureWrapper>
          <KNTypography variant="displaySM_SB" color="primary.focus">
            {translation.general.moduleName}
          </KNTypography>
          <FadeIn>
            <Box mt={2} mb={4}>
              <MegaTableFilters megaTableFilters={shipmentInsightFilters} />
            </Box>
            <Box mb={2}>
              <MegaTableHeaders
                megaTableHeaders={megaTableSorters}
                activeDirection={activeSortingDirection}
                activeHeader={activeSorter}
                setActiveHeader={(val: string): void => handleSortingChange(val)}
              />
            </Box>
            <MegaTable
              tableRows={shipmentsTableData}
              paging={{
                activePage: activePage ?? 1,
                nextPage: nextPage,
                totalItems: totalItems,
                totalPages: totalPages,
                onChange: (newPage) => handleChangePage(null, newPage),
              }}
              dataTest="shipmentInsight"
            />
          </FadeIn>
        </KNFeatureWrapper>
      )}
    </>
  )
}

export default ShipmentsInsightData
