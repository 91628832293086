import { useState, useEffect, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import Chip from '@mui/material/Chip'
import DirectionsOutlinedIcon from '@mui/icons-material/DirectionsOutlined'

// KN imports
import { analyticsEvent } from 'global/helpers/analytics'
import { getRouteName } from 'global/helpers/activeRoute'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNConfirmationDialog from 'components/KN_Molecules/KNDialog/KNConfirmationDialog'
import useSuppressFlag from 'components/KN_Molecules/KNDialog/useSuppressFlag'
import { LegData } from './TripDetails.types'

export interface DirectionsProps {
  leg: LegData
}

const Directions = ({ leg }: DirectionsProps): ReactElement | null => {
  const { t } = useTranslation()
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)
  const { suppressFlagName, isSuppressed } = useSuppressFlag('google-maps-disclaimer')

  const openDirectionsUrl = useCallback(() => {
    const pickupStop = leg.wayPoints.find((waypoint) => waypoint.type === 'PUP')
    const deliveryStop = leg.wayPoints.find((waypoint) => waypoint.type === 'DEL')
    if (!pickupStop || !deliveryStop) {
      return
    }
    const pickupLocation = pickupStop.geoPoint
      ? `${pickupStop.geoPoint.latitude},${pickupStop.geoPoint.longitude}`
      : encodeURIComponent(
          [
            pickupStop.address.name.join(' '),
            pickupStop.address.street.join(', '),
            pickupStop.address.city.join(', '),
            pickupStop.address.zipCode,
            pickupStop.address.countryCode,
          ].join(', ')
        )
    const deliveryLocation = deliveryStop.geoPoint
      ? `${deliveryStop.geoPoint.latitude},${deliveryStop.geoPoint.longitude}`
      : encodeURIComponent(
          [
            deliveryStop.address.name.join(' '),
            deliveryStop.address.street.join(', '),
            deliveryStop.address.city.join(', '),
            deliveryStop.address.zipCode,
            deliveryStop.address.countryCode,
          ].join(', ')
        )
    window.open(
      `https://www.google.com/maps/dir/?api=1&origin=${pickupLocation}&destination=${deliveryLocation}&travelmode=driving&dir_action=navigate`,
      '_blank'
    )
    analyticsEvent('polestar_cs_leg_directions', [getRouteName(location.pathname)])
  }, [leg.wayPoints])

  const handleDirectionsClick = useCallback(() => {
    if (isSuppressed) {
      openDirectionsUrl()
    } else {
      setConfirmationDialogOpen(true)
    }
  }, [])

  const handleConfirmationDialogAction = useCallback((): void => {
    setConfirmationDialogOpen(false)
    openDirectionsUrl()
  }, [])

  const handleConfirmationDialogClose = useCallback((): void => {
    setConfirmationDialogOpen(false)
  }, [])

  return (
    <>
      <Chip
        icon={<DirectionsOutlinedIcon />}
        size="small"
        color="primary"
        variant="outlined"
        onClick={handleDirectionsClick}
        label={t('trip_details.directions.google_maps')}
      />

      <KNConfirmationDialog
        message={t('trip_details.directions.google_maps_disclaimer')}
        skipConfirmationFlag={suppressFlagName}
        open={confirmationDialogOpen}
        onAction={handleConfirmationDialogAction}
        onClose={handleConfirmationDialogClose}
      />
    </>
  )
}

export default Directions
