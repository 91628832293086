import { ReactElement } from 'react'

// @mui imports
import Box from '@mui/material/Box'

// Data
import { playgroundTranslations } from 'screens/Playground/Playground.data'

// Types
import { Scenario, DocumentType, PlaygroundResponse } from 'screens/Playground/Playground.types'
import { VisibilityLevel } from 'context/authentication/Company.types'
import { Role } from 'context/authentication/Role.types'

interface DropdownProps {
  label: string
  value: string
  description?: string
}

export const getScenarios = (): DropdownProps[] => {
  const { translation } = playgroundTranslations()

  return [
    {
      label: translation.scenarioVehicleLevelVisibilityAdvanced,
      value: Scenario.VehicleLevelVisibilityAdvanced,
      description: translation.scenarioVehicleLevelVisibilityAdvancedDescription,
    },
    {
      label: translation.scenarioCarrierSolutionRegressionSuite,
      value: Scenario.CarrierSolutionRegressionSuite,
      description: translation.scenarioCarrierSolutionRegressionSuiteDescription,
    },
  ]
}

export const getUserRoles = (): DropdownProps[] => {
  const { roles } = playgroundTranslations()

  return [
    {
      label: roles.viewer,
      value: Role.Viewer,
    },
    {
      label: roles.editor,
      value: Role.Editor,
    },
    {
      label: roles.admin,
      value: Role.Admin,
    },
  ]
}

export const getCompanyVisibilityLevels = (): DropdownProps[] => {
  const { visibilityLevels } = playgroundTranslations()

  return [
    {
      label: visibilityLevels.basic,
      value: VisibilityLevel.Basic,
    },
    {
      label: visibilityLevels.advanced,
      value: VisibilityLevel.Advanced,
    },
    {
      label: visibilityLevels.max,
      value: VisibilityLevel.Max,
    },
  ]
}

export const getDocumentTypes = (): DropdownProps[] => {
  const { translation } = playgroundTranslations()

  return [
    {
      label: translation.documentTypePOD,
      value: DocumentType.ProofOfDelivery,
    },
  ]
}

export const getPredefinedStatusCodes = (): string[] => [
  '0490',
  '0500',
  '0491',
  '1500',
  '1600',
  '2990',
  '3000',
  '2991',
  '3060',
]

export const getPlaygroundLogs = (response: PlaygroundResponse): ReactElement => {
  const { logsTranslations } = playgroundTranslations()

  return (
    <Box>
      {response.steps.map((stepElement, index) => (
        <Box key={index}>
          <b>{logsTranslations[stepElement.step]}: </b>
          {logsTranslations[stepElement.stepStatus]}
          {stepElement.errorMsg && ` - ${stepElement.errorMsg}`}
        </Box>
      ))}
    </Box>
  )
}
