import { useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'

// @mui imports
import DialogContentText from '@mui/material/DialogContentText'
import Alert from '@mui/material/Alert'

// KN imports
import { analyticsEvent } from 'global/helpers/analytics'
import { getRouteName } from 'global/helpers/activeRoute'
import { sleep } from 'global/helpers/sleep'
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNCaption from 'components/KN_Molecules/KNCaption/KNCaption'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import KNFormFile from 'components/KN_Molecules/KNForm/KNFormFile'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import { processServerErrorMessages } from 'global/helpers/form'
import DocumentsUploadFields from './DocumentsUploadFields'
import { LegData, StopData } from 'screens/TripDetails/TripDetails.types'
import { attachFiles } from './StatusManager.service'
import { getUpdatedStopForAttachFiles } from './StatusManager.helpers'
import { TripData } from 'screens/TripDashboard/TripDashboard.types'

interface DocumentsUploadDialogPayload {
  trip: TripData
  leg: LegData
  stop: StopData
  weblinkToken?: string
}

interface DocumentsUploadDialogProps {
  payload: DocumentsUploadDialogPayload
  open: boolean
  onAction: (updatedStops: StopData[]) => void
  onClose: () => void
}

export interface DocumentsUploadFormValues {
  popFiles?: string[]
  podFiles?: string[]
  photoFiles?: string[]
  palletFile?: string
}

const DocumentsUploadDialog = ({ payload, open, onAction, onClose }: DocumentsUploadDialogProps): ReactElement => {
  const { t } = useTranslation()
  const location = useLocation()
  const form = useForm<DocumentsUploadFormValues>({
    criteriaMode: 'all',
  })
  const { handleSubmit, reset, formState, setError } = form

  useEffect(() => {
    if (open) {
      reset()
    }
  }, [open])

  const onSubmit: SubmitHandler<DocumentsUploadFormValues> = async (data: DocumentsUploadFormValues) => {
    try {
      await attachFiles(payload.trip.entityId, payload.stop.wayPointCid, data, payload.weblinkToken)
      onAction([getUpdatedStopForAttachFiles(payload.stop, data)])
      analyticsEvent('polestar_cs_documents_uploaded', [getRouteName(location.pathname)])
    } catch (error) {
      setError('root', processServerErrorMessages(error))
    }
  }

  return (
    <FormProvider {...form}>
      <KNDialog
        data-test="documents-upload-dialog"
        open={open}
        onClose={onClose}
        closeLabel={t('general.cancel')}
        preventClosing={formState.isSubmitting}
        title={`${payload.leg.shipmentNumber} — ${t('trip_details.card.actions.upload_documents')}`}
        maxWidth="xs"
        actions={
          <>
            <KNLoadingButton
              data-test="upload-button-upload-form"
              type="submit"
              color="primary"
              variant="contained"
              loading={formState.isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              {t('trip_details.card.actions.upload_documents')}
            </KNLoadingButton>
          </>
        }
        onSubmit={handleSubmit(onSubmit)}
      >
        <KNDialogFormErrors errors={formState.errors?.root} />
        <DocumentsUploadFields
          payload={{
            stop: payload.stop,
            weblinkToken: payload.weblinkToken,
            noPhotos: payload.trip.sendingApplication === 'MACADAM',
          }}
        />
      </KNDialog>
    </FormProvider>
  )
}

export default DocumentsUploadDialog
