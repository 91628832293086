import { createContext, ReactElement, ReactNode, useContext, useEffect, useReducer, useState } from 'react'

// Functional
import {
  getAdvancedDetailsInfoData,
  getShipmentDetailsInfoData,
  getShipmentFiles,
  getTemperatureChartData,
  getVisibilityMapData,
} from './InsightDetails.service'
import { getSelectedCompany } from 'context/authentication/User.helpers'

// Types
import InsightDetailsProps, { EntityType, InsightDetailsDataProps } from './InsightDetails.types'

interface Action {
  type: 'setInsightDetailsProps'
  payload: InsightDetailsProps
}
type Dispatch = (action: Action) => void
interface State {
  insightDetailsContext: InsightDetailsProps
  dataLoading?: boolean
}
interface InsightDetailsContextProviderProps {
  children: ReactNode
}
interface InsightDetailsContextType {
  insightDetailsState: State
  dispatchInsightDetailsState: Dispatch
  dataLoading: boolean
}

const initialState: InsightDetailsProps = {
  shipmentId: undefined,
}

const InsightDetailsStateContext = createContext<InsightDetailsContextType | undefined>(undefined)

function insightDetailsReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'setInsightDetailsProps': {
      return { insightDetailsContext: action.payload }
    }
    default: {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function InsightDetailsContextProvider({ children }: InsightDetailsContextProviderProps): ReactElement {
  const [dataLoading, setDataLoading] = useState(true)
  const [insightDetailsState, dispatchInsightDetailsState] = useReducer(insightDetailsReducer, {
    insightDetailsContext: initialState,
  })

  const { shipmentId, entityType, token, pinCode } = insightDetailsState.insightDetailsContext

  const getShipmentDetailsInfo = async (shipmentid: string): Promise<void> => {
    let data: InsightDetailsDataProps
    let shipmentFiles
    let visibilityMapData
    let temperatureChartData
    setDataLoading(true)

    // Modules
    const selectedCompany = getSelectedCompany()
    const mapModuleActive = selectedCompany?.modules.location
    const tempModuleActive = selectedCompany?.modules.temperature

    try {
      data = await getShipmentDetailsInfoData(shipmentid, token, pinCode)
      try {
        shipmentFiles = await getShipmentFiles(shipmentid, token, pinCode)
      } catch (error) {
        console.log(error)
      }
      if (data.pairingId) {
        try {
          if (mapModuleActive) visibilityMapData = await getVisibilityMapData(data.pairingId, token, pinCode)
          if (tempModuleActive) temperatureChartData = await getTemperatureChartData(data.pairingId, token, pinCode)
        } catch (error) {
          console.log(error)
        }
      }
      dispatchInsightDetailsState({
        type: 'setInsightDetailsProps',
        payload: {
          shipmentId: shipmentId,
          comRefId: data.comRefId,
          entityType: entityType,
          data: {
            status: data.status,
            pairingId: data.pairingId,
            generalInfoListProps: data.generalInfoListProps,
            etaProps: data.etaProps,
            addressInfoProps: data.addressInfoProps,
            filesProps: shipmentFiles,
            cargoProps: data.cargoProps,
            trackingInfoProps: data.trackingInfoProps,
            visibilityMapInfoProps: visibilityMapData,
            temperatureChartProps: temperatureChartData,
          },
        },
      })
    } catch (error) {
      console.log(error)
      if (pinCode) {
        dispatchInsightDetailsState({
          type: 'setInsightDetailsProps',
          payload: {
            ...insightDetailsState.insightDetailsContext,
            shareError: true,
          },
        })
      }
    }
    setDataLoading(false)
  }

  const getPairingDetailsInfo = async (shipmentid: string): Promise<void> => {
    let data: InsightDetailsDataProps
    let visibilityMapData
    let temperatureChartData
    setDataLoading(true)
    try {
      data = await getAdvancedDetailsInfoData(shipmentid, token, pinCode)
      visibilityMapData = await getVisibilityMapData(shipmentid, token, pinCode)
      temperatureChartData = await getTemperatureChartData(shipmentid, token, pinCode)
      dispatchInsightDetailsState({
        type: 'setInsightDetailsProps',
        payload: {
          shipmentId: shipmentId,
          entityType: entityType,
          data: {
            pairingId: data.pairingId,
            generalInfoListProps: data.generalInfoListProps,
            etaProps: data.etaProps,
            addressInfoProps: data.addressInfoProps,
            cargoProps: data.cargoProps,
            trackingInfoProps: data.trackingInfoProps,
            visibilityMapInfoProps: visibilityMapData,
            temperatureChartProps: temperatureChartData,
          },
        },
      })
    } catch (error) {
      console.log(error)
      if (pinCode) {
        dispatchInsightDetailsState({
          type: 'setInsightDetailsProps',
          payload: {
            ...insightDetailsState.insightDetailsContext,
            shareError: true,
          },
        })
      }
    }
    setDataLoading(false)
  }

  useEffect(() => {
    if (shipmentId && entityType === EntityType.shipment) {
      const fetchData = async (): Promise<void> => {
        await getShipmentDetailsInfo(shipmentId)
      }
      fetchData().catch(console.error)
    }
    if (shipmentId && entityType === EntityType.pairing) {
      const fetchData = async (): Promise<void> => {
        await getPairingDetailsInfo(shipmentId)
      }
      fetchData().catch(console.error)
    }
  }, [shipmentId, entityType])

  const value = { insightDetailsState, dispatchInsightDetailsState, dataLoading }
  return <InsightDetailsStateContext.Provider value={value}>{children}</InsightDetailsStateContext.Provider>
}

function useInsightDetailsContext(): InsightDetailsContextType {
  const context = useContext(InsightDetailsStateContext)
  if (context === undefined) {
    throw new Error('error')
  }
  return context
}

export { InsightDetailsContextProvider, useInsightDetailsContext }
