import { useCallback, useState } from 'react'
import FadeIn from 'react-fade-in/lib/FadeIn'
import { useTranslation } from 'react-i18next'

// @mui imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Theme } from '@mui/material/styles/createTheme'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Tooltip } from '@mui/material'
import { SystemStyleObject } from '@mui/system'

// icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import MobiledataOffIcon from '@mui/icons-material/MobiledataOff'

// KN imports
import theme from 'assets/theme'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// types
import { MegaTableHeader, MegaTableHeaderProps } from './MegaTable.types'

const MegaTableHeaders: React.FC<MegaTableHeaderProps> = ({
  megaTableHeaders,
  activeHeader,
  activeDirection,
  setActiveHeader,
}) => {
  const [hovered, setHovered] = useState('')
  const handleUnhover = useCallback((): void => {
    setHovered('')
  }, [])
  const { t } = useTranslation()

  const getIcon = useCallback(
    (header: MegaTableHeader) => {
      if (header.value !== activeHeader && header.value === hovered) {
        return (
          <FadeIn>
            <UnfoldMoreIcon fontSize="small" />
          </FadeIn>
        )
      }
      if (header.value !== activeHeader && header.value === hovered) {
        return (
          <FadeIn>
            <UnfoldMoreIcon fontSize="small" />
          </FadeIn>
        )
      }
      if (header.value === activeHeader && header.value !== hovered && activeDirection === 'DESC') {
        return <ExpandMoreIcon fontSize="small" />
      }

      if (header.value === activeHeader && header.value === hovered && activeDirection === 'DESC') {
        return <ExpandLessIcon fontSize="small" />
      }

      if (header.value === activeHeader && header.value !== hovered && activeDirection === 'ASC') {
        return <ExpandLessIcon fontSize="small" />
      }

      if (header.value === activeHeader && header.value === hovered && activeDirection === 'ASC') {
        return <MobiledataOffIcon fontSize="small" />
      }
    },
    [activeDirection, activeHeader, hovered]
  )
  const handleClick = useCallback(
    (header: MegaTableHeader) => {
      setHovered('')
      if (header.value === activeHeader && activeDirection === 'ASC') {
        setActiveHeader('')
      } else {
        setActiveHeader(header.value)
      }
    },
    [activeDirection, activeHeader, hovered]
  )

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const getRowLabels = (header: MegaTableHeader): JSX.Element => {
    const tooltipText =
      header.value !== activeHeader
        ? t('shared.mega_table.sort_descending', { name: header.displayName })
        : activeDirection === 'DESC'
        ? t('shared.mega_table.sort_ascending', { name: header.displayName })
        : t('shared.mega_table.sort_remove')
    return (
      <Tooltip title={tooltipText}>
        <Box
          data-test="shipmentInsight-megatable-headers"
          sx={({ functions: { pxToRem } }: Theme): SystemStyleObject<Theme> => ({
            height: pxToRem(30),
            position: 'absolute',
            cursor: 'pointer',
            zIndex: 2,
            display: 'inline-flex',
          })}
          onMouseEnter={(): void => setHovered(header.value)}
          onMouseLeave={handleUnhover}
          onClick={(): void => handleClick(header)}
        >
          <KNTypography
            color="dark.main"
            variant={'textSM'}
            sx={({ typography }: Theme): SystemStyleObject<Theme> => ({
              fontWeight: typography.fontWeightBold,
            })}
          >
            {header.displayName}
          </KNTypography>
          {getIcon(header)}
        </Box>
      </Tooltip>
    )
  }
  return (
    <>
      {isDesktop && (
        <Box
          sx={{
            position: 'relative',
            width: '100%',
          }}
        >
          <Grid container mb={0.5} spacing={1} alignItems={'flex-start'}>
            <Grid item container spacing={1} md>
              {megaTableHeaders.map((header) => (
                <Grid item xs={12} md={header.size} key={header.value}>
                  <Box>{getRowLabels(header)}</Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  )
}

export default MegaTableHeaders
