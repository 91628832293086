// Functional //
import { getColorByIndex } from 'global/helpers/styling'

export const defaultIcon = (index: number): google.maps.Symbol => {
  return {
    path: 'M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0',
    fillColor: getColorByIndex(index),
    fillOpacity: 0.7,
    strokeWeight: 0,
    scale: 0.7,
  }
}

export const defaultWayPointIcon = process.env.PUBLIC_URL + '/img/map/markers/primary-main.png'
export const waypointIcons = [
  process.env.PUBLIC_URL + '/img/map/markers/primary-main.png',
  process.env.PUBLIC_URL + '/img/map/markers/secondary-main.png',
  process.env.PUBLIC_URL + '/img/map/markers/tertiary-main.png',
  process.env.PUBLIC_URL + '/img/map/markers/error-main.png',
  process.env.PUBLIC_URL + '/img/map/markers/warning-main.png',
  process.env.PUBLIC_URL + '/img/map/markers/success-main.png',
  process.env.PUBLIC_URL + '/img/map/markers/primary-dark.png',
  process.env.PUBLIC_URL + '/img/map/markers/info-main.png',
]
