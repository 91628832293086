// KN base
import colors from './colors'

// KN helper functions
import pxToRem from '../functions/pxToRem'

const { dark } = colors

// types
interface Types {
  fontFamily: string
  fontWeightLighter: number
  fontWeightLight: number
  fontWeightRegular: number
  fontWeightMedium: number
  fontWeightSemiBold: number
  fontWeightBold: number

  displayXXS: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }
  displayXXS_SB: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }

  displayXS: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }
  displayXS_SB: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }

  displaySM: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }
  displaySM_SB: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }

  displayMD: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }
  displayMD_SB: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }

  displayLG: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }
  displayLG_SB: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }

  textXS: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }

  textSM: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }

  textMD: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }
  textMD_SB: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }

  textLG: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }
  textLG_SB: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }

  h1: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    color: string
    lineHeight: string | number
  }
  h2: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    color: string
    lineHeight: number
  }
  h3: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    color: string
    lineHeight: string | number
  }
  h4: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    color: string
    lineHeight: number
  }
  h5: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    color: string
    lineHeight: number
  }
  h6: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    color: string
    lineHeight: number
  }
  subtitle1: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: number
  }
  subtitle2: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: number
  }
  body1: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: number
  }
  body2: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: number
  }
  button: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: number
    textTransform: any
  }
  caption: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: number
  }
  overline: {
    fontFamily: string
  }
  size: {
    xxs: string
    xs: string
    sm: string
    md: string
    lg: string
    xl: string
    '2xl': string
    '3xl': string
  }

  lineHeight: {
    sm: number
    md: number
    lg: number
  }
}

const baseProperties = {
  fontFamily: '"SuisseIntl", "Helvetica", "Arial", sans-serif',
  fontWeightLighter: 100,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  fontSizeXXS: pxToRem(8),
  fontSizeXS: pxToRem(10),
  fontSizeSM: pxToRem(12),
  fontSizeMD: pxToRem(14),
  fontSizeLG: pxToRem(16),
  fontSizeXL: pxToRem(18),
  fontSize2XL: pxToRem(20),
  fontSize3XL: pxToRem(24),
}

const baseHeadingProperties = {
  fontFamily: baseProperties.fontFamily,
  color: dark.main,
  fontWeight: baseProperties.fontWeightMedium,
}

// const baseDisplayProperties = {
//   fontFamily: baseProperties.fontFamily,
//   color: dark.main,
//   fontWeight: baseProperties.fontWeightLight,
//   lineHeight: 1.2
// }

const typography: Types = {
  fontFamily: baseProperties.fontFamily,
  fontWeightLighter: baseProperties.fontWeightLighter,
  fontWeightLight: baseProperties.fontWeightLight,
  fontWeightRegular: baseProperties.fontWeightRegular,
  fontWeightMedium: baseProperties.fontWeightMedium,
  fontWeightSemiBold: baseProperties.fontWeightSemiBold,
  fontWeightBold: baseProperties.fontWeightBold,

  displayXXS: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeLG,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: pxToRem(24),
  },
  displayXXS_SB: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeLG,
    fontWeight: baseProperties.fontWeightSemiBold,
    lineHeight: pxToRem(24),
  },

  displayXS: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXL,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: pxToRem(24),
  },
  displayXS_SB: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXL,
    fontWeight: baseProperties.fontWeightSemiBold,
    lineHeight: pxToRem(24),
  },

  displaySM: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSize2XL,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: pxToRem(24),
  },
  displaySM_SB: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSize2XL,
    fontWeight: baseProperties.fontWeightSemiBold,
    lineHeight: pxToRem(24),
  },

  displayMD: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSize3XL,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: pxToRem(32),
  },
  displayMD_SB: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSize3XL,
    fontWeight: baseProperties.fontWeightSemiBold,
    lineHeight: pxToRem(32),
  },

  displayLG: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(32),
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: pxToRem(32),
  },
  displayLG_SB: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(32),
    fontWeight: baseProperties.fontWeightSemiBold,
    lineHeight: pxToRem(32),
  },

  textXS: {
    fontWeight: baseProperties.fontWeightRegular,
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXXS,
    lineHeight: pxToRem(18),
  },

  textSM: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXS,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: pxToRem(20),
  },

  textMD: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: pxToRem(22),
  },
  textMD_SB: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    fontWeight: baseProperties.fontWeightSemiBold,
    lineHeight: pxToRem(22),
  },

  textLG: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: pxToRem(24),
  },
  textLG_SB: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    fontWeight: baseProperties.fontWeightSemiBold,
    lineHeight: pxToRem(24),
  },

  h1: {
    fontSize: pxToRem(48),
    lineHeight: 1.25,
    ...baseHeadingProperties,
  },

  h2: {
    fontSize: pxToRem(36),
    lineHeight: 1.3,
    ...baseHeadingProperties,
  },

  h3: {
    fontSize: pxToRem(30),
    lineHeight: 1.375,
    ...baseHeadingProperties,
  },

  h4: {
    fontSize: pxToRem(24),
    lineHeight: 1.375,
    ...baseHeadingProperties,
  },

  h5: {
    fontSize: pxToRem(20),
    lineHeight: 1.375,
    ...baseHeadingProperties,
  },

  h6: {
    fontSize: pxToRem(16),
    lineHeight: 1.625,
    ...baseHeadingProperties,
  },

  subtitle1: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXL,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.5,
  },

  subtitle2: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    fontWeight: baseProperties.fontWeightMedium,
    lineHeight: 1.5,
  },

  body1: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.625,
  },

  body2: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.625,
  },

  button: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    fontWeight: baseProperties.fontWeightBold,
    lineHeight: 1.625,
    textTransform: 'none',
  },

  caption: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXS,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.25,
  },

  overline: {
    fontFamily: baseProperties.fontFamily,
  },

  size: {
    xxs: baseProperties.fontSizeXXS,
    xs: baseProperties.fontSizeXS,
    sm: baseProperties.fontSizeSM,
    md: baseProperties.fontSizeMD,
    lg: baseProperties.fontSizeLG,
    xl: baseProperties.fontSizeXL,
    '2xl': baseProperties.fontSize2XL,
    '3xl': baseProperties.fontSize3XL,
  },

  lineHeight: {
    sm: 1.25,
    md: 1.5,
    lg: 2,
  },
}

export default typography
