import { useState, useEffect, useCallback, useContext, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'
import { VehicleListContext, VehicleFiltersValues } from 'context/vehicles/VehicleListContext'
import VehicleFilters from './VehicleFilters'
import VehicleTable from './VehicleTable'
import CreateOrEditVehicleDialog from './CreateOrEditVehicleDialog'

// Functional
import { analyticsEvent, analyticsPageView } from 'global/helpers/analytics'
import { hasRole } from 'global/helpers/authorization'
import { sleep } from 'global/helpers/sleep'
import { getVehicles, sortVehicles } from './VehicleManager.service'

// Types
import { Role } from 'context/authentication/Role.types'
import { VehicleData } from './VehicleManager.types'

const VehicleManager = (): ReactElement => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [vehiclesData, setVehiclesData] = useState<VehicleData[]>([])
  const [hasTelematics, setHasTelematics] = useState(true)
  const [filteredVehiclesData, setFilteredVehiclesData] = useState<VehicleData[]>([])
  const [vehicleListState, vehicleListDispatch] = useContext(VehicleListContext)
  const [createOrEditVehicleDialogOpen, setCreateOrEditVehicleDialogOpen] = useState(false)

  const fetchData = async (backgroundLoading = false): Promise<void> => {
    if (backgroundLoading) {
      vehicleListDispatch({ type: 'setBackgroundLoading', payload: true })
      await sleep(1000)
    } else {
      setLoading(true)
    }
    const vehicles = await getVehicles()
    setVehiclesData(vehicles)
    setHasTelematics(vehicles.some((vehicle) => vehicle.telematicsReady))
    if (backgroundLoading) {
      vehicleListDispatch({ type: 'setBackgroundLoading', payload: false })
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData()
    analyticsPageView('polestar/cs/vehicles_list')
  }, [])

  useEffect(() => {
    filterData(vehicleListState.filters)
  }, [vehiclesData, vehicleListState.filters])

  const handleOnChange = useCallback(
    async (updatedVehicle: VehicleData, action: string): Promise<void> => {
      if (action === 'create') {
        const updatedVehiclesData = [...vehiclesData, updatedVehicle].sort(sortVehicles)
        setVehiclesData(updatedVehiclesData)
      } else if (action === 'edit') {
        const updatedVehiclesData = vehiclesData.map((vehicle) =>
          updatedVehicle.id === vehicle.id ? updatedVehicle : vehicle
        )
        setVehiclesData(updatedVehiclesData)
      } else if (action === 'delete') {
        const updatedVehiclesData = vehiclesData.filter((vehicle) => updatedVehicle.id !== vehicle.id)
        setVehiclesData(updatedVehiclesData)
      }
      await fetchData(true)
    },
    [vehiclesData]
  )

  const filterData = (filters: VehicleFiltersValues): void => {
    setFilteredVehiclesData(
      vehiclesData.filter((vehicle) => {
        const lowercaseKeywords = filters.keywords?.map((keyword: string) => keyword.toLowerCase())
        let keywordsCondition = true
        if (lowercaseKeywords?.length) {
          const lowercaseValues = [vehicle.licensePlate, vehicle.displayLicensePlate].map((value: string) =>
            value.toLowerCase()
          )
          keywordsCondition = lowercaseValues
            .map((value) => {
              return lowercaseKeywords.map((keyword) => value.includes(keyword)).some((condition: boolean) => condition)
            })
            .some((condition: boolean) => condition)
        }
        let vehicleTypeCondition = true
        const statusFilters = vehicleListState.filters.vehicleType
        if (statusFilters?.length) {
          vehicleTypeCondition = statusFilters
            .map((vehicleType) => {
              return vehicle.vehicleType === vehicleType
            })
            .some((condition: boolean) => condition)
        }

        return [keywordsCondition, vehicleTypeCondition].every((condition: boolean) => condition)
      })
    )
  }

  const handleAddClick = useCallback((): void => {
    setCreateOrEditVehicleDialogOpen(true)
    analyticsEvent('polestar_cs_create_vehicle_button_clicked')
  }, [])

  const handleCreateOrEditDialogAction = useCallback(
    async (updatedVehicle: VehicleData, action: string): Promise<void> => {
      setCreateOrEditVehicleDialogOpen(false)
      await handleOnChange(updatedVehicle, action)
    },
    []
  )

  const handleCreateOrEditDialogClose = useCallback((): void => {
    setCreateOrEditVehicleDialogOpen(false)
  }, [])

  return (
    <Container maxWidth="xl" data-test="vehicles-container">
      {loading ? (
        <KNLoader>
          <KNTypography>{t('vehicle_manager.loading')}</KNTypography>
        </KNLoader>
      ) : (
        <>
          <Paper elevation={8} sx={{ padding: 2 }}>
            <Stack
              spacing={1}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
              alignItems={{ xs: 'start', md: 'center' }}
              mb={2}
            >
              <KNTypography variant="h4">
                {t('vehicle_manager.vehicles_count', { count: filteredVehiclesData.length })}
              </KNTypography>
              {hasRole(Role.Editor) && !hasTelematics && (
                <KNButton
                  onClick={handleAddClick}
                  data-test="add-new-vehicle-button"
                  variant="contained"
                  color="secondary"
                  size="small"
                >
                  {t('vehicle_manager.card.actions.new')}
                </KNButton>
              )}
            </Stack>
            <VehicleFilters />
            <VehicleTable vehicles={filteredVehiclesData} onChange={handleOnChange} />
          </Paper>
        </>
      )}
      {hasRole(Role.Editor) && !hasTelematics && (
        <CreateOrEditVehicleDialog
          data-test="edit-dialog"
          open={createOrEditVehicleDialogOpen}
          onAction={handleCreateOrEditDialogAction}
          onClose={handleCreateOrEditDialogClose}
        />
      )}
    </Container>
  )
}

export default VehicleManager
