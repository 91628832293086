import { useContext, useEffect, ReactElement } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'

// @mui imports
import Box from '@mui/material/Box'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'

// KN Components
import themeKN from 'assets/theme/index'
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'

// Screens
import Support from 'modules/Support/Support'
import Home from 'screens/Home/Home'
import Login from 'screens/Login/Login'
import ResetPassword from 'screens/ResetPassword/ResetPassword'
import Weblink from 'screens/Weblink/Weblink'
import ErrorPage from 'screens/ErrorPage/ErrorPage'
import ShareShipmentDetails from 'screens/ShareShipmentDetails/ShareShipmentDetails'

// Context
import { UserContext } from 'context/authentication/UserContext'
import { getStartPage } from 'context/authentication/User.helpers'
import { MenuContextProvider } from 'context/menu/MenuContext'
import { TripListProvider } from 'context/trips/TripListContext'

// Functional
import { isSentryEnabled } from 'global/helpers/environment'
import { getActiveRoute } from 'global/helpers/activeRoute'

const MonitoredRoute = isSentryEnabled() ? Sentry.withSentryRouting(Route) : Route

const App = (): ReactElement => {
  const history = useHistory()
  const { pathname, hash } = history.location
  const { user, userLoading, loadUser } = useContext(UserContext)

  useEffect(() => {
    void loadUser()
  }, [])

  // Standard redirects
  useEffect(() => {
    const externalAccessRoutes =
      !pathname.toLowerCase().startsWith('/reset/') &&
      !pathname.toLowerCase().startsWith('/wl/') &&
      !pathname.toLowerCase().startsWith('/share_shipment/')
    if (!user && !userLoading && !hash && externalAccessRoutes) {
      history.push('/login')
    }
    if (user && !userLoading && pathname === '/login') {
      history.push(getStartPage())
    }
    if (user && location.pathname !== '/') {
      const validRoute = getActiveRoute(location.pathname) ? true : false
      if (!validRoute) {
        history.push('/notfound')
      }
    }
  }, [user, userLoading])

  if (userLoading && pathname !== '/login') {
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <KNLoader />
      </Box>
    )
  }

  // Routes shared with the 'outside world' //
  const externalRouteWL = (): JSX.Element => {
    return (
      <MonitoredRoute exact path="/wl/:jwt">
        <Support moduleName="cs" />
        <TripListProvider>
          <Weblink />
        </TripListProvider>
      </MonitoredRoute>
    )
  }

  const externalRouteShipment = (): JSX.Element | undefined => {
    return (
      <MonitoredRoute exact path="/share_shipment/:entitytype/:shipmentid/:shipmentname/:token">
        <Support moduleName="cv" />
        <ShareShipmentDetails />
      </MonitoredRoute>
    )
  }

  return (
    <ThemeProvider theme={themeKN}>
      <MenuContextProvider>
        <CssBaseline />
        <Switch>
          {externalRouteWL()}
          {externalRouteShipment()}
          <MonitoredRoute path="/login">
            <Login />
          </MonitoredRoute>
          <MonitoredRoute path="/login/:action">
            <Login />
          </MonitoredRoute>
          <MonitoredRoute path="/reset">
            <ResetPassword />
          </MonitoredRoute>
          <Route path="/notfound">
            <ErrorPage />
          </Route>
          <MonitoredRoute path="/">
            <Home />
          </MonitoredRoute>
        </Switch>
      </MenuContextProvider>
    </ThemeProvider>
  )
}

export default App
