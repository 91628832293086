// Types
import { VisibilityLevel } from 'context/authentication/Company.types'
import { Role } from 'context/authentication/Role.types'

export interface CompanyProps {
  name: string
  visibilityLevel?: VisibilityLevel
  companyAcknowledgement: boolean
  createCompany: boolean
}

export interface UserProps {
  email: string
  role: Role
  userAcknowledgement: boolean
  createUser: boolean
}

export enum Scenario {
  VehicleLevelVisibilityAdvanced = 'VehicleLevelVisibilityAdvanced',
  CarrierSolutionRegressionSuite = 'CarrierSolutionRegressionSuite',
}

export enum DocumentType {
  ProofOfDelivery = 'ProofOfDelivery',
}

export interface CustomerAdress {
  name?: string
  country?: string
  postalCode?: string
  city?: string
  address?: string
}

export interface GeoPoint {
  latitude: number
  longitude: number
}

export interface PickUpDelivery {
  early: null
  late: null
  address: {
    name?: string
    country?: string
    postalCode?: string
    city?: string
    address?: string
    location?: GeoPoint
  }
}

export interface CreatePredefinedScenarioFormValues {
  company: CompanyProps
  user: UserProps
  scenarioId: Scenario
}

export interface StatusField {
  code: string
  dateTime: Date | null
  cityLocation?: string
}

export interface StatusFieldDialogProps {
  statusData?: StatusField
  dialogOpen: boolean
  handleClose: () => void
  handleSave: (data: StatusField) => void
}

export interface GeocodeAddressDialogProps {
  addressData?: PickUpDelivery
  dialogOpen: boolean
  handleClose: () => void
  handleSave: (data: PickUpDelivery) => void
}

export interface Telematics {
  licensePlate?: string
}

export interface Document {
  type?: DocumentType
  countryCode?: string
}

export interface TemperatureThreshold {
  min: number
  max: number
}

export interface Pairing {
  createPairing: {
    description?: string
    temperatureThreshold?: TemperatureThreshold | null
    violateTemperatureThreshold?: boolean
  }
  pairingEta: boolean
}

export interface PairingPayload {
  createPairing: {
    description?: string
    temperatureThreshold?: TemperatureThreshold | null
    violateTemperatureThreshold?: boolean
  }
  pairingEta: boolean
}

export interface CreateShipmentFormValues {
  company: CompanyProps
  user: UserProps
  shipment: {
    startDate: Date
    endDate: Date
    customerAddress: CustomerAdress
    pickUp: PickUpDelivery
    delivery: PickUpDelivery
    lastLocationGeoPoint?: GeoPoint | null
    statuses: StatusField[]
  }
  pairing: Pairing | null
  telematics: Telematics | null
  document: Document | null
}

export interface CreateShipmentPayloadValues {
  company: CompanyProps
  user: UserProps
  shipment: {
    startDate: Date
    endDate: Date
    customerAddress: CustomerAdress
    pickUp: PickUpDelivery
    delivery: PickUpDelivery
    lastLocationGeoPoint?: GeoPoint | null
    statuses: StatusField[]
  }
  pairing: PairingPayload | null
  telematics: Telematics | null
  document: Document | null
}

export enum StepStatus {
  OK = 'OK',
  ERROR = 'ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum StepType {
  COMPANY = 'COMPANY',
  USER = 'USER',
  CREATE_SHIPMENT = 'CREATE_SHIPMENT',
  CREATE_POD_DOCUMENT = 'CREATE_POD_DOCUMENT',
  GENERATE_TELEMATICS_DATA = 'GENERATE_TELEMATICS_DATA',
  GENERATE_CHORUS_DATA = 'GENERATE_CHORUS_DATA',
  CREATE_PAIRING = 'CREATE_PAIRING',
  CREATE_TRIP = 'CREATE_TRIP',
}

export interface Step {
  step: StepType
  stepStatus: StepStatus
  errorMsg?: string
}

export interface PlaygroundResponse {
  steps: Step[]
}
