// @mui imports
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

// KN modules
import CreatePlaygroundScenarioForm from 'modules/PlaygroundForms/CreatePlaygroundScenarioForm'
import CreatePlaygroundShipmentForm from 'modules/PlaygroundForms/CreatePlaygroundShipmentForm'
import { useState } from 'react'
import { playgroundTranslations } from './Playground.data'

const Playground = (): React.ReactElement => {
  const [activeTab, setActiveTab] = useState('shipment')
  const { translation } = playgroundTranslations()

  return (
    <Container maxWidth={false} data-test="playground-container">
      <Paper elevation={8} sx={{ padding: 2 }}>
        <TabContext value={activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'grey.300' }}>
            <Tabs value={activeTab} onChange={(e, value): void => setActiveTab(value)} aria-label="basic tabs example">
              <Tab label={translation.createShipmentFormName} value={'shipment'} data-test="shipment-tab" />
              <Tab
                label={translation.createPredefinedScenarioFormName}
                value={'predefinedScenario'}
                data-test="predefined-tab"
              />
            </Tabs>
          </Box>
          <Stack spacing={3} direction="column" mb={2}>
            <TabPanel value="shipment">
              <CreatePlaygroundShipmentForm />
            </TabPanel>
            <TabPanel value="predefinedScenario">
              <CreatePlaygroundScenarioForm />
            </TabPanel>
          </Stack>
        </TabContext>
      </Paper>
    </Container>
  )
}

export default Playground
