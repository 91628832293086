import { useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'

// @mui imports
import Stack from '@mui/material/Stack'
import InputLabel from '@mui/material/InputLabel'

// KN imports
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import KNForm from 'components/KN_Molecules/KNForm/KNForm'
import KNFormText from 'components/KN_Molecules/KNForm/KNFormText'
import KNFormDropdown from 'components/KN_Molecules/KNForm/KNFormDropdown'
import KNFormTags from 'components/KN_Molecules/KNForm/KNFormTags'
import KNFormCheckbox from 'components/KN_Molecules/KNForm/KNFormCheckbox'

// Functional
import { sleep } from 'global/helpers/sleep'
import { processServerErrorMessages, processDefaultValues } from 'global/helpers/form'
import { createCompany, editCompany } from './CompanyManager.service'
import { analyticsEvent } from 'global/helpers/analytics'

// Types
import { Company, CompanyModules, CompanyType, VisibilityLevel } from 'context/authentication/Company.types'

interface CreateOrEditCompanyDialogPayload {
  company: Company
}

interface CreateOrEditCompanyDialogProps {
  payload?: CreateOrEditCompanyDialogPayload
  open: boolean
  onAction: () => void
  onClose: () => void
}

interface CompanyProperties {
  address: string
  visibilityLevel: VisibilityLevel
}

export interface CreateOrEditCompanyFormValues {
  type: CompanyType
  displayName: string
  customerIdentifiers?: string[]
  properties?: Partial<CompanyProperties>
  modules?: CompanyModules | null
}

const CreateOrEditCompanyDialog = ({
  payload,
  open,
  onAction,
  onClose,
}: CreateOrEditCompanyDialogProps): ReactElement => {
  const { t } = useTranslation()
  const { handleSubmit, watch, reset, control, formState, setValue, setError } =
    useForm<CreateOrEditCompanyFormValues>()
  const watchedType = watch('type')
  const watchedVisibilityLevel = watch('properties.visibilityLevel')

  useEffect(() => {
    if (open) {
      reset(
        processDefaultValues({
          type: payload?.company.type,
          displayName: payload?.company.displayName,
          customerIdentifiers: payload?.company.customerIdentifiers ?? [],
          properties: processDefaultValues({
            visibilityLevel: payload?.company.properties?.visibilityLevel,
            address: payload?.company.properties?.address,
          }),
          modules: payload?.company.modules ?? null,
        })
      )
    }
  }, [open])

  useEffect(() => {
    if (watchedType) setValue('properties', {})
    if (watchedType === 'Carrier') setValue('modules', null)
    else
      setValue('modules', {
        location: false,
        temperature: false,
        temperature_prediction: false,
        eta: false,
      })
  }, [watchedType])

  const onSubmit: SubmitHandler<CreateOrEditCompanyFormValues> = async (data: CreateOrEditCompanyFormValues) => {
    try {
      if (payload) {
        await editCompany(payload.company.cid, data)
      } else {
        await createCompany(data)
      }
      // NOTE: avoid stale data
      await sleep(500)
      onAction()
      analyticsEvent(payload ? 'polestar_cv_company_edited' : 'polestar_cv_company_created')
    } catch (error) {
      setError('root', processServerErrorMessages(error))
    }
  }

  return (
    <KNForm onSubmit={handleSubmit(onSubmit)}>
      <KNDialog
        open={open}
        onClose={onClose}
        closeLabel={t('general.cancel')}
        preventClosing={formState.isSubmitting}
        title={
          payload
            ? `${payload.company.displayName} — ${t('company_manager.add_edit_company.edit_details')}`
            : t('company_manager.add_edit_company.new_company')
        }
        actions={
          <>
            <KNLoadingButton
              data-test="update-button"
              type="submit"
              color="primary"
              variant="contained"
              loading={formState.isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              {payload ? t('company_manager.add_edit_company.update') : t('company_manager.add_edit_company.create')}
            </KNLoadingButton>
          </>
        }
      >
        <KNDialogFormErrors errors={formState.errors?.root} />
        <Stack spacing={2}>
          <KNFormText
            name="displayName"
            label={t('company_manager.add_edit_company.display_name')}
            control={control}
            rules={{
              required: t('form.validation.required'),
            }}
          />
          <KNFormTags
            name="customerIdentifiers"
            label={t('company_manager.add_edit_company.customer_ids')}
            control={control}
            rules={{
              required: t('form.validation.required'),
            }}
          />
          <KNFormDropdown
            name="type"
            label={t('company_manager.add_edit_company.type')}
            control={control}
            rules={{
              required: t('form.validation.required'),
            }}
            options={Object.keys(CompanyType)
              .filter((type) => type !== 'Operator')
              .map((type) => {
                return {
                  value: type,
                  label: t(`company_manager.company_types.${type}`),
                }
              })}
          />
          {watchedType === 'Carrier' && (
            <KNFormText
              name="properties.address"
              label={t('company_manager.add_edit_company.address')}
              control={control}
            />
          )}
          {watchedType === 'Customer' && (
            <>
              <KNFormDropdown
                name="properties.visibilityLevel"
                label={t('company_manager.add_edit_company.visibility_level')}
                control={control}
                options={Object.keys(VisibilityLevel).map((level) => ({
                  value: level,
                  label: t(`company_manager.visibility_types.${level}`),
                }))}
              />
              {watchedVisibilityLevel && watchedVisibilityLevel !== 'Basic' && (
                <Stack data-test="access-modules" spacing={1}>
                  <InputLabel>{t('company_manager.add_edit_company.access_modules')}</InputLabel>
                  <KNFormCheckbox
                    name="modules.location"
                    control={control}
                    label={t('company_manager.add_edit_company.modules.map')}
                  />
                  <KNFormCheckbox
                    name="modules.temperature"
                    control={control}
                    label={t('company_manager.add_edit_company.modules.temperature_charts')}
                  />
                  <KNFormCheckbox
                    name="modules.temperature_prediction"
                    control={control}
                    label={t('company_manager.add_edit_company.modules.temperature_prediction')}
                  />
                  <KNFormCheckbox
                    name="modules.eta"
                    control={control}
                    label={t('company_manager.add_edit_company.modules.eta')}
                  />
                </Stack>
              )}
            </>
          )}
        </Stack>
      </KNDialog>
    </KNForm>
  )
}

export default CreateOrEditCompanyDialog
