import { useState, useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler } from 'react-hook-form'
import i18n from 'i18n'

// @mui imports
import Stack from '@mui/material/Stack'
import DialogContentText from '@mui/material/DialogContentText'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import Fade from '@mui/material/Fade'
import ListItem from '@mui/material/ListItem'

// KN imports
import { analyticsEvent } from 'global/helpers/analytics'
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import { processServerErrorMessages, processDefaultValues } from 'global/helpers/form'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import KNFormText from 'components/KN_Molecules/KNForm/KNFormText'
import KNFormRadioGroup from 'components/KN_Molecules/KNForm/KNFormRadioGroup'
import { BulletList } from './RequestAccountPromo'
import { regexEmail } from 'global/helpers/validators'
import { requestAccountInWeblink } from 'screens/Weblink/Weblink.service'

interface RequestAccountDialogPayload {
  weblinkToken: string
  promo?: boolean
}

export interface RequestAccountDialogProps {
  payload: RequestAccountDialogPayload
  open: boolean
  onAction: () => void
  onClose: () => void
}

export interface RequestAccountFormValues {
  name: string
  companyName: string
  email: string
  language?: string
}

const RequestAccountDialog = ({ payload, open, onAction, onClose }: RequestAccountDialogProps): ReactElement => {
  const { t } = useTranslation()
  const { handleSubmit, reset, control, formState, setError } = useForm<RequestAccountFormValues>()
  const [showConfirmationSnackbar, setShowConfirmationSnackbar] = useState(false)

  useEffect(() => {
    if (open) {
      reset(
        processDefaultValues({
          language: i18n.language ?? 'en',
        })
      )
    }
  }, [open])

  const onSubmit: SubmitHandler<RequestAccountFormValues> = async (data: RequestAccountFormValues) => {
    try {
      if (payload.weblinkToken) {
        await requestAccountInWeblink(data, payload.weblinkToken)
      }
      onAction()
      setShowConfirmationSnackbar(true)
      analyticsEvent('polestar_cs_account_request_sent')
    } catch (error) {
      setError('root', processServerErrorMessages(error))
    }
  }

  return (
    <>
      <KNDialog
        open={open}
        onClose={onClose}
        closeLabel={payload.promo ? t('general.skip') : t('general.cancel')}
        preventClosing={formState.isSubmitting}
        title={t('weblink.request_account.request_account')}
        actions={
          <>
            <KNLoadingButton
              color="primary"
              variant="contained"
              loading={formState.isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              {t('weblink.request_account.send_request')}
            </KNLoadingButton>
          </>
        }
        onSubmit={handleSubmit(onSubmit)}
      >
        <KNDialogFormErrors errors={formState.errors?.root} />
        {payload.promo && (
          <DialogContentText component="div" mb={3}>
            <KNTypography color="text.main">{t('weblink.request_account_promo.intro')}</KNTypography>
            <BulletList sx={{ color: 'text.main' }}>
              <ListItem>{t('weblink.request_account_promo.bullet_1')}</ListItem>
              <ListItem>{t('weblink.request_account_promo.bullet_2')}</ListItem>
              <ListItem>{t('weblink.request_account_promo.bullet_3')}</ListItem>
            </BulletList>
          </DialogContentText>
        )}
        <Stack spacing={1}>
          <KNFormText
            name="name"
            label={t('weblink.request_account.name')}
            control={control}
            rules={{
              required: t('form.validation.required'),
            }}
          />
          <KNFormText
            name="email"
            label={t('weblink.request_account.email')}
            control={control}
            rules={{
              pattern: {
                value: regexEmail,
                message: t('form.validation.invalid_email'),
              },
              required: t('form.validation.required'),
            }}
          />
          <KNFormText
            name="companyName"
            label={t('weblink.request_account.company')}
            control={control}
            rules={{
              required: t('form.validation.required'),
            }}
          />
        </Stack>
      </KNDialog>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showConfirmationSnackbar}
        onClose={(): void => setShowConfirmationSnackbar(false)}
        TransitionComponent={Fade}
        autoHideDuration={5000}
        key="requestAccountConfirmation"
      >
        <Alert variant="filled" severity="success">
          {t('weblink.request_account.confirmation')}
        </Alert>
      </Snackbar>
    </>
  )
}

export default RequestAccountDialog
