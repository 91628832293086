import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import i18n from 'i18next'
import { parseISO, isPast, isThisYear, isEqual, isSameDay, differenceInHours } from 'date-fns'

// @mui imports
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import QuestionMark from '@mui/icons-material/QuestionMark'

// KN imports
import { analyticsEvent } from 'global/helpers/analytics'
import { zonedDate } from 'global/helpers/dateFormatters'
import { getRouteName } from 'global/helpers/activeRoute'
import { KNCustomsIcon } from 'components/KN_Molecules/KNIcon/KNMaterialIcon'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNCaption from 'components/KN_Molecules/KNCaption/KNCaption'
import KNDetailsPopover from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopover'
import KNDetailsPopoverList from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopoverList'
import usePopoverState from 'components/KN_Molecules/KNDetailsPopover/usePopoverState'
import StopStatusChip, { getLabel } from 'screens/StatusManager/StopStatusChip'
import { StopData } from './TripDetails.types'

export interface StopHeaderProps {
  stop: StopData
  size?: 'large'
}

export const getTypeLabel = (type: 'PUP' | 'DEL' | 'CUS'): string => {
  switch (type) {
    case 'PUP':
      return i18n.t('waypoints.types.PUP')
    case 'DEL':
      return i18n.t('waypoints.types.DEL')
    case 'CUS':
      return i18n.t('waypoints.types.CUS')
    default:
      return i18n.t('waypoints.types.UNKNOWN')
  }
}

export const getTypeIcon = (type: 'PUP' | 'DEL' | 'CUS'): any => {
  switch (type) {
    case 'PUP':
      return FileUploadIcon
    case 'DEL':
      return FileDownloadIcon
    case 'CUS':
      return KNCustomsIcon
    default:
      return QuestionMark
  }
}

export const getTypeBackground = (type: 'PUP' | 'DEL' | 'CUS'): string => {
  switch (type) {
    case 'PUP':
      return 'linear-gradient(0deg, #008AC4 0%, #003369 100%)'
    case 'DEL':
      return 'linear-gradient(180deg, #008AC4 0%, #003369 100%)'
    case 'CUS':
    default:
      return 'linear-gradient(45deg, #008AC4 0%, #003369 100%)'
  }
}

const StopHeader = ({ stop, size }: StopHeaderProps): ReactElement => {
  const { t } = useTranslation()
  const location = useLocation()
  const parsedEarlyDateTime = parseISO(stop.earlyDateTime)
  const parsedLateDateTime = parseISO(stop.lateDateTime)
  const hasAnyStatuses = stop.statuses.length > 0
  const latestStatus = hasAnyStatuses ? stop.statuses[0] : undefined
  const isDelayed = !latestStatus && isPast(parsedLateDateTime)
  const isUpcoming = !latestStatus && !isDelayed && Math.abs(differenceInHours(parsedEarlyDateTime, new Date())) <= 8
  const {
    anchorEl: statusDetailsAnchorEl,
    open: statusDetailsOpen,
    handleClick: handleStatusDetailsClick,
    handleClose: handleStatusDetailsClose,
  } = usePopoverState()

  const TypeIcon = getTypeIcon(stop.type)

  const getDateMessage = (): string | null => {
    const fullDateFormat = isThisYear(parsedEarlyDateTime) ? 'full_no_year' : 'full'
    const mediumDateFormat = isThisYear(parsedEarlyDateTime) ? 'medium_no_year' : 'medium'
    if (stop.type === 'CUS') {
      if (latestStatus) {
        return t('trip_details.card.executed_on', {
          date: zonedDate(latestStatus.createdAt, fullDateFormat),
        })
      }
      return null
    }
    if (latestStatus) {
      return t('trip_details.card.executed_on', {
        date: zonedDate(latestStatus.createdAt, fullDateFormat),
      })
    } else if (isEqual(parsedEarlyDateTime, parsedLateDateTime)) {
      return t('trip_details.card.expected_on_same_time', {
        date: zonedDate(stop.earlyDateTime, fullDateFormat),
      })
    } else if (isSameDay(parsedEarlyDateTime, parsedLateDateTime)) {
      return t('trip_details.card.expected_on_same_day', {
        date: zonedDate(stop.earlyDateTime, mediumDateFormat),
        hour1: zonedDate(stop.earlyDateTime, 'time'),
        hour2: zonedDate(stop.lateDateTime, 'time'),
      })
    } else {
      return t('trip_details.card.expected_on', {
        date1: zonedDate(stop.earlyDateTime, fullDateFormat),
        date2: zonedDate(stop.lateDateTime, fullDateFormat),
      })
    }
  }
  const dateMessage = getDateMessage()

  return (
    <>
      <Stack data-test="pickup-delivery-message" direction="row" spacing={1} alignItems="center">
        <Avatar variant="rounded" sx={{ background: getTypeBackground(stop.type) }}>
          <TypeIcon />
        </Avatar>
        <Stack direction="column">
          <Stack spacing={1} direction="row" alignItems="center">
            <KNTypography color="text.main" variant={size === 'large' ? 'displaySM' : 'displayXXS_SB'}>
              {getTypeLabel(stop.type)}
            </KNTypography>
            {latestStatus && (
              <StopStatusChip
                statusId={latestStatus.id}
                onClick={(event: React.MouseEvent<HTMLElement>): void => {
                  handleStatusDetailsClick(event)
                  analyticsEvent('polestar_cs_leg_status_history_popover', [getRouteName(location.pathname)])
                }}
              />
            )}
          </Stack>
          {dateMessage && (
            <KNTypography
              variant="textMD"
              sx={{ lineHeight: '1rem' }}
              color={isUpcoming ? 'warning' : isDelayed ? 'error' : 'primary.light'}
            >
              {dateMessage}
            </KNTypography>
          )}
        </Stack>
      </Stack>

      {hasAnyStatuses && (
        <KNDetailsPopover open={statusDetailsOpen} onClose={handleStatusDetailsClose} anchorEl={statusDetailsAnchorEl}>
          {stop.statuses.map((status) => (
            <KNDetailsPopoverList
              key={status.id}
              items={[
                {
                  label: t('trip_details.status_details.status'),
                  value: getLabel(status.id),
                },
                {
                  label: t('trip_details.status_details.executed_on'),
                  value: zonedDate(status.createdAt, 'full') ?? undefined,
                },
                ...(status.comment
                  ? [
                      {
                        label: t('trip_details.status_details.remarks'),
                        value: status.comment,
                        stacked: true,
                      },
                    ]
                  : []),
              ]}
            />
          ))}
        </KNDetailsPopover>
      )}
    </>
  )
}

export default StopHeader
