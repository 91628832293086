import { useState, useEffect, useContext, useCallback, ReactElement } from 'react'
import i18n from 'i18n'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// @mui imports
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import LanguageIcon from '@mui/icons-material/Language'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

// KN imports
import { isMeaBranch } from 'global/helpers/regions'
import { TripListContext } from 'context/trips/TripListContext'
import { StopsViewProvider } from 'context/trips/StopsViewContext'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNMenu from 'components/KN_Molecules/KNMenu/KNMenu'
import KNMenuItem from 'components/KN_Molecules/KNMenu/KNMenuItem'
import useMenuState from 'components/KN_Molecules/KNMenu/useMenuState'
import { isViewSwitcherAvailable } from 'screens/TripDetails/TripDetails.helpers'
import ShipmentsView from 'screens/TripDetails/ShipmentsView'
import StopsView from 'screens/TripDetails/StopsView'
import TripSummary from 'screens/TripDetails/TripSummary'
import ViewSwitcher from 'screens/TripDetails/ViewSwitcher'
import RequestAccountPromo from './RequestAccountPromo'

// Functional
import { getLanguages } from 'screens/ProfilePage/ProfilePage.helpers'
import { getWeblinkToken, sendFingerprint } from './Weblink.service'
import { getLogo } from 'global/helpers/getLogo'
import { getTripLegs } from 'screens/TripDetails/TripDetails.service'
import { getTrips } from 'screens/TripDashboard/TripDashboard.service'
import { analyticsEvent, analyticsPageView } from 'global/helpers/analytics'

// Types
import { LegData, StopData } from 'screens/TripDetails/TripDetails.types'
import { TripData } from 'screens/TripDashboard/TripDashboard.types'

const Weblink = (): ReactElement => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const { jwt } = useParams<{
    jwt: string
  }>()
  const [weblinkToken, setWeblinkToken] = useState<string>()
  const [tripData, setTripData] = useState<TripData>()
  const [legsData, setLegsData] = useState<LegData[]>([])
  const [weblinkError, setWeblinkError] = useState<string>()
  const [loading, setLoading] = useState(true)
  const [tripListState, tripListDispatch] = useContext(TripListContext)
  const [currentLanguage, setCurrentLanguage] = useState<string>()
  const {
    anchorEl: languagesAnchorEl,
    open: languagesOpen,
    handleClick: handleLanguagesClick,
    handleClose: handleLanguagesClose,
  } = useMenuState()
  const languages = getLanguages()

  const fetchTripDetailsData = async (): Promise<void> => {
    setWeblinkError(undefined)
    setLoading(true)
    try {
      const { token } = await getWeblinkToken(jwt)
      setWeblinkToken(token)
      const trips = await getTrips(token)
      setTripData(trips[0])
      const legs = await getTripLegs(trips[0].entityId, token)
      setLegsData(legs)
    } catch (error) {
      // TODO: replace with error codes when ready
      setWeblinkError(error === 'Weblink is not valid or has expired' ? error : 'Error, please contact administrator.')
    }
    setLoading(false)
  }

  const handleOnChange = useCallback(async (): Promise<void> => {
    await fetchTripDetailsData()
  }, [])

  const getGeolocation = useCallback(() => {
    if (!navigator.geolocation) {
      return
    }
    if (localStorage.getItem('geolocation')) {
      return
    }
    // NOTE: tripData is present at this point
    if (!isMeaBranch(tripData!.logicalSender)) {
      return
    }

    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        localStorage.setItem('geolocation', 'permission_granted')
        analyticsEvent('polestar_cs_geolocation', ['permission_granted'])
      },
      (error: GeolocationPositionError) => {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            localStorage.setItem('geolocation', 'permission_denied')
            analyticsEvent('polestar_cs_geolocation', ['permission_denied'])
            break
          case error.POSITION_UNAVAILABLE:
            localStorage.setItem('geolocation', 'position_unavailable')
            analyticsEvent('polestar_cs_geolocation', ['position_unavailable'])
            break
          case error.TIMEOUT:
            localStorage.setItem('geolocation', 'timeout')
            analyticsEvent('polestar_cs_geolocation', ['timeout'])
            break
          default:
            localStorage.setItem('geolocation', 'unknown_error')
            analyticsEvent('polestar_cs_geolocation', ['unknown_error'])
            break
        }
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    )
  }, [isMobile, tripData])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchTripDetailsData()

    analyticsPageView('polestar/cs/weblink')
  }, [])

  useEffect(() => {
    if (tripData) {
      void sendFingerprint(tripData.entityId)
    }

    if (isMobile && tripData) {
      getGeolocation()
      if (isViewSwitcherAvailable(tripData)) {
        tripListDispatch({ type: 'setDetailsView', payload: 'stops' })
      }
    }
  }, [isMobile, tripData])

  useEffect(() => {
    const newLanguage = languages.find((language) => language.languageCode === i18n.language.slice(0, 2))
    setCurrentLanguage(newLanguage?.label ?? '')
  }, [i18n.language])

  const handleLanguageClick = async (languageCode: string): Promise<void> => {
    await i18n.changeLanguage(languageCode)
    analyticsEvent('polestar_change_language', [languageCode])
  }

  return (
    <Container maxWidth="xl" sx={{ paddingTop: 2, paddingBottom: 4 }}>
      {loading ? (
        <KNLoader>
          <KNTypography>{t('trip_details.loading')}</KNTypography>
        </KNLoader>
      ) : (
        <>
          {weblinkError ? (
            <Alert severity="error">{weblinkError}</Alert>
          ) : (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  marginBottom: 2,
                }}
              >
                {getLogo('symbol', '32px', '32px')}
                <KNButton
                  variant="text"
                  endIcon={<LanguageIcon />}
                  onClick={handleLanguagesClick}
                  sx={{
                    paddingY: 0,
                    paddingX: 0.5,
                  }}
                >
                  {currentLanguage}
                </KNButton>
              </Stack>

              {isViewSwitcherAvailable(tripData!) && !isMobile && <ViewSwitcher />}
              <Stack
                data-test="weblink-container"
                direction={{ xs: 'column-reverse', lg: 'row' }}
                spacing={{ xs: 2, lg: 4 }}
                alignItems={{ xs: 'stretch', lg: 'start' }}
                sx={{
                  position: 'relative',
                  marginTop: 2,
                  marginX: { xs: -1, md: 0 },
                }}
              >
                {tripListState.detailsView === 'shipments' && (
                  <ShipmentsView
                    trip={tripData!}
                    legs={legsData}
                    weblinkToken={weblinkToken}
                    onChange={handleOnChange}
                  />
                )}
                {tripListState.detailsView === 'stops' && (
                  <StopsViewProvider>
                    <StopsView trip={tripData!} legs={legsData} weblinkToken={weblinkToken} onChange={handleOnChange} />
                  </StopsViewProvider>
                )}
                <Stack
                  spacing={4}
                  sx={{
                    position: { lg: 'sticky' },
                    width: { lg: '360px' },
                    flexShrink: 0,
                    top: { xs: '56px', md: '64px' },
                  }}
                >
                  <TripSummary trip={tripData!} legs={legsData} weblinkToken={weblinkToken} onChange={handleOnChange} />
                  {!isMobile && <RequestAccountPromo weblinkToken={weblinkToken!} />}
                </Stack>
              </Stack>

              <KNMenu
                open={languagesOpen}
                onClose={handleLanguagesClose}
                anchorEl={languagesAnchorEl}
                rightAligned
                bottomAligned
              >
                {languages.map((language) => (
                  <KNMenuItem
                    key={language.languageCode}
                    label={language.label}
                    onClick={async (): Promise<void> => await handleLanguageClick(language.languageCode)}
                  />
                ))}
              </KNMenu>
            </>
          )}
        </>
      )}
    </Container>
  )
}

export default Weblink
