/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Fragment, useState, useCallback, useEffect, useContext } from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'

// @mui imports
import Box from '@mui/material/Box'

// Subcomponents
import {
  airportMarker,
  detailedLocationMarker,
  deviceLocationMarker,
  firstLocationMarker,
  lastLocationMarker,
  planeMarker,
  visibilityMapTooltip,
} from './VisibilityMapItems'

// Types
import VisibilityMapProps, { CoordsProps } from './VisibilityMap.types'

// Context
import { useInsightDetailsContext } from 'context/detailsNext/InsightDetails'
import { UserContext } from 'context/authentication/UserContext'

// Functional
import { getCenterLocation, getDefaultCenterLocation, setDevicesForMap } from './VisibilityMap.helpers'

// Data
import {
  visibilityMapContainerStyleFull,
  visibilityMapContainerStyleHalf,
  visibilityMapContainerStyleMobile,
  visibilityMapStyles,
} from './VisibilityMap.styles'

const VisibilityMap: React.FC<VisibilityMapProps> = ({
  filteredIds,
  filteredDateFrom,
  filteredDateTo,
  size,
  visible,
}) => {
  const { insightDetailsState } = useInsightDetailsContext()
  const { userTimezone } = useContext(UserContext)
  // Google Map config
  const mapKey = process.env.REACT_APP_MAPS_API_KEY ? process.env.REACT_APP_MAPS_API_KEY : ''

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: mapKey,
    libraries: ['drawing'],
  })

  const [map, setMap] = useState<{
    center?: google.maps.LatLng | google.maps.LatLngLiteral | undefined
  }>({})

  const onLoad = useCallback(function callback(map: any) {
    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback() {
    setMap({})
  }, [])

  // Props //
  const { data } = insightDetailsState.insightDetailsContext
  const pickup = data?.addressInfoProps?.pickup?.address
  const delivery = data?.addressInfoProps?.delivery?.address
  const locationProps = data?.visibilityMapInfoProps

  // Air shipment data //
  const airInfo = data?.addressInfoProps?.airInfo
  const departureAirport = airInfo?.departure.address
  const arrivalAirport = airInfo?.arrival.address
  const [plane, setPlane] = useState<any>()

  const [planeInfoLoading, setPlaneInfoLoading] = useState(false)

  const fetchAirData = (withLoader?: boolean): void => {
    const url = `https://airlabs.co/api/v9/flights?api_key=384bc9b5-9c81-46f9-9c69-71ec33c6199e&flight_iata=${airInfo?.flightNumber}`
    withLoader && setPlaneInfoLoading(true)
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then((apiResponse) => {
        setPlane({
          location: { latitude: apiResponse?.response[0].lat, longitude: apiResponse?.response[0].lng },
          direction: apiResponse?.response[0].dir,
        })
        withLoader && setPlaneInfoLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        setPlaneInfoLoading(false)
      })
  }

  useEffect(() => {
    if (airInfo) {
      fetchAirData(true)
    }
  }, [])

  // live air info refresh //
  useEffect(() => {
    if (airInfo) {
      fetchAirData()
    }
    const intervalId = setInterval(() => {
      fetchAirData()
    }, 300000)
    return () => clearInterval(intervalId)
  }, [])

  // Module state //
  const [deviceLocations] = useState<any>(locationProps ?? [])
  const [devices, setDevices] = useState<any>([])

  useEffect(() => {
    setDevices(setDevicesForMap(deviceLocations, filteredIds, filteredDateFrom, filteredDateTo))
    setMap({})
  }, [deviceLocations, filteredIds, filteredDateFrom, filteredDateTo])

  // Map specific - center //
  const centerLocation = getCenterLocation(deviceLocations)
  const defaultCenter = plane?.location
    ? getDefaultCenterLocation(plane.location)
    : pickup?.location
    ? getDefaultCenterLocation(pickup.location)
    : delivery?.location
    ? getDefaultCenterLocation(delivery.location)
    : undefined

  // Tooltip //
  const [tooltip, setTooltip] = useState<{
    tooltipActive: boolean
    tooltipPosition?: CoordsProps
    tooltipTitle?: string
    tooltipBody?: string
    tooltipCaption?: string
  }>({
    tooltipActive: false,
  })

  const { tooltipActive, tooltipPosition, tooltipTitle, tooltipBody, tooltipCaption } = tooltip

  if ((devices.length === 0 && !pickup?.location && !delivery?.location) || !visible) return null
  return (
    <Box data-test="devices-map" sx={{ width: '100%' }}>
      {isLoaded && !planeInfoLoading && (
        <GoogleMap
          onLoad={onLoad}
          onUnmount={onUnmount}
          mapContainerStyle={
            size === 'full'
              ? visibilityMapContainerStyleFull
              : size === 'half'
              ? visibilityMapContainerStyleHalf
              : visibilityMapContainerStyleMobile
          }
          zoom={defaultCenter ? 8 : 13}
          center={map.center ?? centerLocation ?? defaultCenter}
          options={{
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            styles: visibilityMapStyles,
            zoomControl: size === 'full' ? false : true,
          }}
        >
          {devices?.map((device, i) => {
            if (device.details.filter((dev) => dev.visible)) {
              return (
                <Fragment key={i}>
                  {firstLocationMarker(
                    device,
                    i,
                    (tooltip: {
                      tooltipActive: boolean
                      tooltipPosition: CoordsProps
                      tooltipTitle: string
                      tooltipBody: string
                    }) => setTooltip(tooltip),
                    userTimezone
                  )}
                  {lastLocationMarker(
                    device,
                    i,
                    (tooltip: {
                      tooltipActive: boolean
                      tooltipPosition: CoordsProps
                      tooltipTitle: string
                      tooltipBody: string
                    }) => setTooltip(tooltip),
                    userTimezone
                  )}
                </Fragment>
              )
            }
          })}
          {devices.map((device, i) =>
            deviceLocationMarker(
              device,
              i,
              (tooltip: {
                tooltipActive: boolean
                tooltipPosition: CoordsProps
                tooltipTitle: string
                tooltipBody: string
              }) => setTooltip(tooltip),
              userTimezone
            )
          )}
          {pickup?.location &&
            detailedLocationMarker(
              pickup,
              'pickup',
              (tooltip: {
                tooltipActive: boolean
                tooltipPosition: CoordsProps
                tooltipTitle: string
                tooltipBody: string
              }) => setTooltip(tooltip)
            )}

          {delivery?.location &&
            detailedLocationMarker(
              delivery,
              'delivery',
              (tooltip: {
                tooltipActive: boolean
                tooltipPosition: CoordsProps
                tooltipTitle: string
                tooltipBody: string
              }) => setTooltip(tooltip)
            )}

          {departureAirport &&
            airportMarker(
              departureAirport,
              'departure',
              (tooltip: {
                tooltipActive: boolean
                tooltipPosition: CoordsProps
                tooltipTitle: string
                tooltipBody: string
              }) => setTooltip(tooltip)
            )}
          {arrivalAirport &&
            airportMarker(
              arrivalAirport,
              'arrival',
              (tooltip: {
                tooltipActive: boolean
                tooltipPosition: CoordsProps
                tooltipTitle: string
                tooltipBody: string
              }) => setTooltip(tooltip)
            )}
          {plane && planeMarker(plane, plane?.direction)}

          {tooltipActive &&
            tooltipPosition &&
            visibilityMapTooltip(
              tooltipPosition,
              () => setTooltip({ tooltipActive: false }),
              tooltipTitle ?? '',
              tooltipBody ?? '',
              tooltipCaption ?? ''
            )}
        </GoogleMap>
      )}
    </Box>
  )
}

export default VisibilityMap
