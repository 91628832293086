import { FC, memo, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

// @mui imports
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import { Theme } from '@mui/material/styles/createTheme'
import Tooltip from '@mui/material/Tooltip'
import useMediaQuery from '@mui/material/useMediaQuery'
import { SystemStyleObject } from '@mui/system'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Types
import KNCardProps, { KNCardIndicator } from './KNCard.types'

const KNCard: FC<KNCardProps> = ({
  title,
  link,
  highlighted,
  label,
  mobileOnly = useMediaQuery('(max-width:400px)') && true,
  leftIndicators,
  rightIndicators,
  gridItems,
  menuOptions,
}) => {
  const [cardHovered, cardHoveredSet] = useState(false)
  const handleCardHover = useCallback(() => cardHoveredSet(!cardHovered), [cardHovered])

  // CARD MENU
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const cardMenuOpen = Boolean(anchorEl)
  const handleCardMenuClose = useCallback(() => setAnchorEl(null), [])
  const handleCardMenuClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget),
    []
  )

  // MODAL
  const [cardModal, openCardModal] = useState(false)
  const handleModalOpen = useCallback(() => openCardModal(true), [])

  const IndicatorTemplate: FC<{ indicator: KNCardIndicator }> = memo(({ indicator }) => {
    return (
      <Tooltip title={indicator.description}>
        <Box
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            typography: { size },
          }: Theme): SystemStyleObject<Theme> => ({
            borderRadius: borderRadius.round,
            backgroundColor: indicator.backgroundColor,
            color: indicator.color,
            width: pxToRem(30),
            height: pxToRem(30),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: size.sm,
            mr: indicator.noMargin ? 0 : 1,
          })}
        >
          {indicator.icon}
        </Box>
      </Tooltip>
    )
  })

  return (
    <Box
      sx={({
        borders: { borderRadius, borderWidth },
        boxShadows,
        palette: { white, primary, secondary },
      }: Theme): SystemStyleObject<Theme> => ({
        backgroundColor: white.main,
        borderRadius: borderRadius.lg,
        border: `${borderWidth[1]} ${highlighted ? secondary.dark : (white.main as string)} solid`,
        boxShadow: boxShadows.standardBoxShadow,
        position: 'relative',
        py: 2,
        px: 3,
        transition: 'all 200ms ease-in-out',
        '&:hover': {
          border: `${borderWidth[1]} ${primary.light} solid`,
        },
      })}
    >
      <Stack data-test="shipments-mobile-card" direction={mobileOnly ? 'column' : 'row'} justifyContent="space-between">
        <Stack
          direction={mobileOnly ? 'row' : 'column'}
          sx={{ minWidth: mobileOnly ? '100%' : '30%' }}
          justifyContent={mobileOnly ? 'space-between' : 'unset'}
        >
          <Link to={link}>
            <KNTypography
              variant="displayXS_SB"
              color={'secondary.main'}
              sx={{
                cursor: 'pointer',
                textDecoration: 'underline',
                transition: 'all 0.5s ease-out',
              }}
            >
              {title}
            </KNTypography>
          </Link>
          {label && (
            <Box mb={1}>
              <KNTypography
                data-test="trip-status-mobile"
                variant="textLG"
                sx={({
                  borders: { borderRadius },
                  functions: { pxToRem },
                  palette: { white },
                }: Theme): SystemStyleObject<Theme> => ({
                  borderRadius: borderRadius.xl,
                  backgroundColor: label.color,
                  color: white.main,
                  height: pxToRem(16),
                  py: pxToRem(1),
                  px: 1,
                  width: 'fit-content',
                  lineHeight: pxToRem(14),
                })}
              >
                {label.text}
              </KNTypography>
            </Box>
          )}
        </Stack>
        <Grid
          container
          spacing={2}
          wrap={mobileOnly ? undefined : 'nowrap'}
          mt={mobileOnly ? 0 : undefined}
          mb={mobileOnly ? 2.5 : undefined}
        >
          {gridItems?.map((gridItem, index) =>
            !gridItem.valueMultiline ? (
              <Grid item xs={12} key={index} zeroMinWidth>
                <Stack direction={mobileOnly ? 'row' : 'column'}>
                  <KNTypography
                    variant="textLG"
                    sx={({ palette: { primary } }: Theme): SystemStyleObject<Theme> => ({
                      color: primary.light,
                    })}
                    mr={0.5}
                  >
                    {gridItem.property}
                  </KNTypography>
                  <KNTypography
                    data-test="shipment-id"
                    variant="textLG"
                    sx={({ palette: { dark } }: Theme): SystemStyleObject<Theme> => ({
                      color: dark.main,
                    })}
                    noWrap
                  >
                    {gridItem.value}
                  </KNTypography>
                </Stack>
              </Grid>
            ) : (
              <Grid item xs={12} key={index} zeroMinWidth>
                <Stack direction={'column'}>
                  <KNTypography
                    data-test="test"
                    variant="textLG"
                    sx={({ palette: { primary } }: Theme): SystemStyleObject<Theme> => ({
                      color: primary.light,
                    })}
                    mr={0.5}
                  >
                    {gridItem.property}
                  </KNTypography>
                  <KNTypography
                    data-test="shipment-pickup-delivery"
                    variant="textLG"
                    sx={({ palette: { dark } }: Theme): SystemStyleObject<Theme> => ({
                      color: dark.main,
                    })}
                  >
                    {gridItem.value}
                  </KNTypography>
                </Stack>
              </Grid>
            )
          )}
        </Grid>
      </Stack>
      {menuOptions && menuOptions.length > 0 && (
        <Box sx={{ position: 'absolute', top: 2, right: 0, mt: 1, mr: 0 }}>
          <IconButton
            data-test="shipments-list-option-button"
            size="small"
            color="primary"
            id="cardMenu-iconButton"
            aria-controls={cardMenuOpen ? 'cardMenu' : undefined}
            aria-haspopup="true"
            aria-expanded={cardMenuOpen ? 'true' : undefined}
            onClick={handleCardMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="cardMenu"
            anchorEl={anchorEl}
            open={cardMenuOpen}
            onClose={handleCardMenuClose}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            transformOrigin={{
              horizontal: 'left',
              vertical: 'bottom',
            }}
            PaperProps={{ sx: { mt: 5 } }}
          >
            {menuOptions.map((menuOption, index) => (
              <div key={index}>
                <MenuItem onClick={menuOption.withModal ? handleModalOpen : (): void => menuOption.onClick?.()}>
                  {menuOption.icon && (
                    <ListItemIcon>
                      <Icon>{menuOption.icon}</Icon>
                    </ListItemIcon>
                  )}
                  <ListItemText disableTypography>{menuOption.text}</ListItemText>
                </MenuItem>
                {cardModal && (
                  <Dialog open={cardModal} onClose={handleModalOpen}>
                    <Box my={2}>{menuOption.withModal?.()}</Box>
                  </Dialog>
                )}
              </div>
            ))}
          </Menu>
        </Box>
      )}
      {leftIndicators && (
        <Stack direction="row" sx={{ position: 'absolute' }}>
          {leftIndicators.map((indicator, index) => {
            return <IndicatorTemplate key={index} indicator={indicator} />
          })}
        </Stack>
      )}
      {rightIndicators && (
        <Stack
          direction="row"
          sx={({ functions: { pxToRem } }: Theme): SystemStyleObject<Theme> => ({
            position: 'absolute',
            right: pxToRem(24),
          })}
        >
          {rightIndicators.map((indicator, index) => {
            return <IndicatorTemplate key={index} indicator={{ ...indicator, noMargin: true }} />
          })}
        </Stack>
      )}
    </Box>
  )
}

export default memo(KNCard)
