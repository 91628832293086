import { useEffect, ReactElement, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'

// @mui imports
import Stack from '@mui/material/Stack'

// KN imports
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import KNForm from 'components/KN_Molecules/KNForm/KNForm'
import KNFormText from 'components/KN_Molecules/KNForm/KNFormText'
import KNFormRadioGroup from 'components/KN_Molecules/KNForm/KNFormRadioGroup'

// Context
import { UserContext } from 'context/authentication/UserContext'

// Functional
import { analyticsEvent } from 'global/helpers/analytics'
import { regexEmail } from 'global/helpers/validators'
import { sleep } from 'global/helpers/sleep'
import { isCompanyType } from 'global/helpers/authorization'
import { getSelectedCompany } from 'context/authentication/User.helpers'
import { processServerErrorMessages, processDefaultValues } from 'global/helpers/form'
import { createUser, editUser } from './UserManager.service'

// Types
import { CompanyType, VisibilityLevel } from 'context/authentication/Company.types'
import { Role } from 'context/authentication/Role.types'
import { UserData } from './UserManager.types'

interface CreateOrEditUserDialogPayload {
  user: UserData
}

interface CreateOrEditUserDialogProps {
  payload?: CreateOrEditUserDialogPayload
  open: boolean
  onAction: () => void
  onClose: () => void
}

export interface CreateOrEditUserFormValues {
  email?: string
  displayName?: string
  companyCid?: string
  role?: string
}

const CreateOrEditUserDialog = ({ payload, open, onAction, onClose }: CreateOrEditUserDialogProps): ReactElement => {
  const { t } = useTranslation()
  const { handleSubmit, reset, control, formState, setValue, setError } = useForm<CreateOrEditUserFormValues>()

  const selectedCompany = getSelectedCompany()
  const isCompanyLevel = selectedCompany && selectedCompany?.type !== CompanyType.Operator
  const isMaxCompany = selectedCompany && selectedCompany.properties?.visibilityLevel === VisibilityLevel.Max

  const { user } = useContext(UserContext)
  const isSelf = payload?.user.email === user?.email

  useEffect(() => {
    if (!open) return
    if (isCompanyLevel)
      reset(
        processDefaultValues({
          email: payload?.user.email,
          displayName: payload?.user.displayName,
          companyCid: selectedCompany.cid,
          role: payload?.user.roles[selectedCompany.cid],
        })
      )
    else
      reset(
        processDefaultValues({
          email: payload?.user.email,
          displayName: payload?.user.displayName,
        })
      )
  }, [open])

  const onSubmit: SubmitHandler<CreateOrEditUserFormValues> = async (data: CreateOrEditUserFormValues) => {
    try {
      if (payload) {
        await editUser(payload.user.cid, data)
      } else {
        await createUser(data)
      }
      // NOTE: avoid stale data
      await sleep(500)
      onAction()
      if (!isCompanyType(CompanyType.Operator)) {
        analyticsEvent(payload ? 'polestar_user_updated' : 'polestar_user_created')
      }
    } catch (error) {
      setError('root', processServerErrorMessages(error))
    }
  }

  return (
    <KNForm onSubmit={handleSubmit(onSubmit)}>
      <KNDialog
        open={open}
        onClose={onClose}
        closeLabel={t('general.cancel')}
        preventClosing={formState.isSubmitting}
        title={
          payload
            ? `${payload.user.displayName} — ${t('user_manager.add_edit_user.edit_details')}`
            : t('user_manager.add_edit_user.new_user')
        }
        actions={
          <>
            <KNLoadingButton
              data-test="edit-details-button"
              type="submit"
              color="primary"
              variant="contained"
              loading={formState.isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              {payload ? t('user_manager.add_edit_user.edit_details') : t('user_manager.add_edit_user.new_user')}
            </KNLoadingButton>
          </>
        }
      >
        <KNDialogFormErrors errors={formState.errors?.root} />
        <Stack spacing={2}>
          <KNFormText
            name="email"
            label={t('user_manager.add_edit_user.email')}
            control={control}
            rules={{
              pattern: {
                value: regexEmail,
                message: t('form.validation.invalid_email'),
              },
              required: t('form.validation.required'),
            }}
            disabled={!!payload}
          />
          <KNFormText name="displayName" label={t('user_manager.add_edit_user.display_name')} control={control} />
          {isCompanyLevel && !isSelf && (
            <KNFormRadioGroup
              name="role"
              label={t('user_manager.grant_role.role')}
              control={control}
              rules={{
                required: t('form.validation.required'),
              }}
              options={Object.keys(Role)
                .filter((role) => (!isMaxCompany ? role !== Role.Pairer : role))
                .map((role) => ({
                  value: role,
                  label: t(`user_manager.roles.${role}`),
                }))}
            />
          )}
        </Stack>
      </KNDialog>
    </KNForm>
  )
}

export default CreateOrEditUserDialog
