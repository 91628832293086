import i18n from 'i18n'

export const notificationCenterTranslations = (): Record<any, any> => {
  const notificationCenter = 'modules.cv.notification_center'

  return {
    translation: {
      screenName: i18n.t(`${notificationCenter}.screen_name`),
      addNew: i18n.t(`${notificationCenter}.add_new`),
      ruleName: i18n.t(`${notificationCenter}.monitoring_rules_table.rule_name`),
      type: i18n.t(`${notificationCenter}.monitoring_rules_table.type`),
      settings: i18n.t(`${notificationCenter}.monitoring_rules_table.settings`),
      status: i18n.t(`${notificationCenter}.monitoring_rules_table.status`),
      created: i18n.t(`${notificationCenter}.monitoring_rules_table.created`),
      delete: i18n.t(`${notificationCenter}.monitoring_rules_table.delete`),
      cancelDelete: i18n.t(`${notificationCenter}.monitoring_rules_table.cancel_delete`),
      deleteMessage: i18n.t(`${notificationCenter}.monitoring_rules_table.delete_message`),
    },
    status: {
      AWAITING_PICKUP: i18n.t(`shared.aggregatedStatus.AWAITING_PICKUP`),
      IN_PROGRESS: i18n.t(`shared.aggregatedStatus.IN_PROGRESS`),
      DELIVERED: i18n.t(`shared.aggregatedStatus.DELIVERED`),
      CANCELLED: i18n.t(`shared.aggregatedStatus.CANCELLED`),
      UNKNOWN: i18n.t(`shared.aggregatedStatus.UNKNOWN`),
    },
    type: {
      pick_up_by_status_monitoring_rule_trigger: i18n.t(
        `${notificationCenter}.monitoring_rule_type.PickUpByStatusMonitoringRuleTrigger`
      ),
      delivery_by_status_monitoring_rule_trigger: i18n.t(
        `${notificationCenter}.monitoring_rule_type.DeliveryByStatusMonitoringRuleTrigger`
      ),
      shipment_status_monitoring_rule_trigger: i18n.t(
        `${notificationCenter}.monitoring_rule_type.ShipmentStatusMonitoringRuleTrigger`
      ),
      pairing_health_on_error_monitoring_rule_trigger: i18n.t(
        `${notificationCenter}.monitoring_rule_type.PairingHealthOnErrorMonitoringRuleTrigger`
      ),
    },
  }
}
