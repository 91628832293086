// Functional
import { fetchGet } from 'global/helpers/fetchWrapper'
import { getSelectedCompany } from 'context/authentication/User.helpers'

// Translation
import { generalInfoListTranslations } from './InsightDetails.data'

// KN Components
import colors from 'assets/theme/base/colors'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Types
import { GeneralInfoListItemProps, InsightDetailsDataProps, ShipmentDataResponse } from './InsightDetails.types'
import { ShipmentFileProps } from 'modules/ShipmentFiles/ShipmentFiles.types'
import { DevicesLocationProps } from 'modules/VisibilityMap/VisibilityMap.types'
import { TemperatureDataSetProps } from 'modules/TemperatureChart/TemperatureChart.types'

// Icons
import EditNoteIcon from '@mui/icons-material/EditNote'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart'
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel'

export const getShipmentDetailsInfoData = async (
  shipmentId: string,
  token?: string,
  pinCode?: string
): Promise<InsightDetailsDataProps> => {
  const shipmentData: ShipmentDataResponse = await fetchGet(
    `shipments/${shipmentId}`,
    token && pinCode
      ? {
          Authorization: `Bearer ${token}:${pinCode}`,
        }
      : {},
    'si'
  )

  // Modules
  const selectedCompany = getSelectedCompany()
  const etaModuleActive = selectedCompany?.modules.eta

  // General info list building //
  const { translation } = generalInfoListTranslations()
  const getGeneralInfoList = (): GeneralInfoListItemProps[] => {
    const generalInfoListItems: GeneralInfoListItemProps[] = [
      {
        icon: (
          <KNTypography color="white.main" variant="displayXS_SB" sx={{ display: 'flex', justifyContent: 'center' }}>
            ID
          </KNTypography>
        ),
        propertyName: translation.shipmentID,
        propertyValue: shipmentData.comRefId,
        additionalInfo: shipmentData.references && {
          text: translation.additionalRefInfo,
          info: shipmentData.references,
        },
      },
    ]
    if (shipmentData?.pairing?.reference) {
      const pairingCidListItem = {
        icon: <InsertLinkIcon sx={{ width: 25, height: 25 }} />,
        propertyName: translation.pairingID,
        propertyValue: shipmentData.pairing.reference,
      }
      generalInfoListItems.push(pairingCidListItem)
    }
    if (shipmentData.pairing?.description) {
      const descriptionListItem = {
        icon: <EditNoteIcon sx={{ width: 25, height: 25 }} />,
        propertyName: translation.description,
        propertyValue: shipmentData.pairing.description,
      }
      generalInfoListItems.push(descriptionListItem)
    }
    if (shipmentData?.pairing?.health) {
      const health = shipmentData?.pairing?.health
      const healthListItem = {
        icon: <MonitorHeartIcon sx={{ width: 25, height: 25 }} />,
        propertyName: translation.health,
        propertyValue: translation.healthStatus[health],
        color: health === 'OK' ? colors.success.main : undefined,
      }
      generalInfoListItems.push(healthListItem)
    }
    if (shipmentData?.airInfo) {
      const shipmentTypeIcon = {
        icon: <ModeOfTravelIcon sx={{ width: 25, height: 25 }} />,
        propertyName: translation.transportationType,
        propertyValue: `${translation.transportationTypes.road as string} ${
          shipmentData.airInfo ? `& ${translation.transportationTypes.air as string}` : ''
        }`,
      }
      generalInfoListItems.push(shipmentTypeIcon)
    }
    return generalInfoListItems
  }

  return {
    // IDS
    pairingId: shipmentData.pairingCid,
    comRefId: shipmentData.comRefId,
    // Status
    status: shipmentData.status,
    // General info list
    generalInfoListProps: {
      followed: shipmentData.hasMonitoringRules,
      completionDate: shipmentData.pairing?.completionDate,
      level: shipmentData.pairing?.level,
      listItems: getGeneralInfoList(),
    },
    // ETA
    etaProps: etaModuleActive
      ? {
          latestEta: shipmentData.pairing?.latestEta,
          delivered: shipmentData.status === 'DELIVERED',
        }
      : undefined,
    // Address info
    addressInfoProps: {
      pickup: shipmentData.pickUp,
      delivery: shipmentData.delivery,
      airInfo: shipmentData.airInfo,
    },
    // Cargo
    cargoProps: {
      cargoSummary: shipmentData.cargoSummary,
      cargoDetails: shipmentData.cargoDetails,
    },
    // Tracking info
    trackingInfoProps: {
      ...shipmentData.pairing,
      temperatureThreshold: shipmentData.pairing?.temperatureThreshold
        ? shipmentData.pairing?.temperatureThreshold
        : shipmentData.temperatureThreshold,
      sendingApplication: shipmentData.sendingApplication,
      trackingNumber: shipmentData.trackingNumber,
    },
  }
}

export const getAdvancedDetailsInfoData = async (
  shipmentId: string,
  token?: string,
  pinCode?: string
): Promise<any> => {
  const pairingData = await fetchGet(
    `pairings/${shipmentId}`,
    token && pinCode
      ? {
          Authorization: `Bearer ${token}:${pinCode}`,
        }
      : {},
    'si'
  )

  const { translation } = generalInfoListTranslations()
  const getGeneralInfoList = (): GeneralInfoListItemProps[] => {
    const generalInfoListItems = [
      {
        icon: <InsertLinkIcon sx={{ width: 25, height: 25 }} />,
        propertyName: translation.pairingID,
        propertyValue: pairingData.reference,
      },
    ]
    if (pairingData.shipmentID) {
      const shipmentIdListItem = {
        icon: (
          <KNTypography color="white.main" variant="displayXS_SB" sx={{ display: 'flex', justifyContent: 'center' }}>
            ID
          </KNTypography>
        ),
        propertyName: translation.shipmentID,
        propertyValue: pairingData.comRefId,
      }
      generalInfoListItems.push(shipmentIdListItem)
    }
    if (pairingData.description) {
      const descriptionListItem = {
        icon: <EditNoteIcon sx={{ width: 25, height: 25 }} />,
        propertyName: translation.description,
        propertyValue: pairingData.description,
      }
      generalInfoListItems.push(descriptionListItem)
    }
    if (pairingData.health) {
      const health = pairingData.health
      const healthListItem = {
        icon: <MonitorHeartIcon sx={{ width: 25, height: 25 }} />,
        propertyName: translation.health,
        propertyValue: translation.healthStatus[health],
        color: health === 'OK' ? colors.success.main : undefined,
      }
      generalInfoListItems.push(healthListItem)
    }
    return generalInfoListItems
  }

  return {
    shipmentid: pairingData.shipmentCid,
    // Pairing ID
    pairingId: pairingData.cid,
    // General info list
    generalInfoListProps: {
      followed: pairingData.hasMonitoringRules,
      completionDate: pairingData.completionDate,
      level: pairingData.level,
      listItems: getGeneralInfoList(),
    },
    // Tracking info
    trackingInfoProps: {
      cid: pairingData.cid,
      creationDate: pairingData.creationDate,
      completionDate: pairingData.completionDate,
      latestEta: pairingData.latestEta,
      level: pairingData.level,
      deviceIds: pairingData.deviceIds,
      temperatureThreshold: pairingData.temperatureThreshold,
      sendingApplication: pairingData.sendingApplication,
      trackingNumber: pairingData.trackingNumber,
    },
  }
}

export const getShipmentFiles = async (
  shipmentId: string,
  token?: string,
  pinCode?: string
): Promise<ShipmentFileProps[]> =>
  await fetchGet(
    `shipments/${shipmentId}/documents`,
    token && pinCode
      ? {
          Authorization: `Bearer ${token}:${pinCode}`,
        }
      : {},
    'si'
  )

export const getVisibilityMapData = async (
  pairingId: string,
  token?: string,
  pinCode?: string
): Promise<DevicesLocationProps[]> => {
  const type = 'location'
  const threshold = 10

  try {
    const data = await fetchGet(
      `pairings/${pairingId}/datapoints/${type}?threshold=${threshold}`,
      token && pinCode
        ? {
            Authorization: `Bearer ${token}:${pinCode}`,
          }
        : {},
      'si'
    )
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getTemperatureChartData = async (
  pairingcid: string,
  token?: string,
  pinCode?: string
): Promise<TemperatureDataSetProps[]> => {
  const type = 'temperature'
  const threshold = 0

  try {
    const data = await fetchGet(
      `pairings/${pairingcid}/datapoints/${type}?threshold=${threshold}`,
      token && pinCode
        ? {
            Authorization: `Bearer ${token}:${pinCode}`,
          }
        : {},
      'si'
    )
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}
