import { useState, useCallback, useContext, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import Paper from '@mui/material/Paper'

// KN imports
import { analyticsPageView } from 'global/helpers/analytics'
import { TripListContext } from 'context/trips/TripListContext'
import KNButtonGroup from 'components/KN_Molecules/KNButtonGroup/KNButtonGroup'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

const ViewSwitcher = (): ReactElement => {
  const { t } = useTranslation()
  const [tripListState, tripListDispatch] = useContext(TripListContext)

  const handleViewChange = useCallback((value) => {
    tripListDispatch({ type: 'setDetailsView', payload: value })
  }, [])

  return (
    <KNButtonGroup
      value={tripListState.detailsView}
      onChange={handleViewChange}
      options={['shipments', 'stops', 'map'].map((view) => ({
        value: view,
        label: t(`trip_details.views.${view}`),
      }))}
      fullWidth={false}
    />
  )
}

export default ViewSwitcher
