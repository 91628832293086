import { useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'

// @mui material imports
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'

// KN Components
import { processServerErrorMessages, processDefaultValues } from 'global/helpers/form'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import KNForm from 'components/KN_Molecules/KNForm/KNForm'
import KNFormCheckbox from 'components/KN_Molecules/KNForm/KNFormCheckbox'
import { getUserPreferences, updateNotificationPreferences } from 'screens/UserManager/UserManager.service'

export interface NotificationsFormValues {
  wantsNewTripEmail?: boolean
  wantsUpdateTripEmail?: boolean
  wantsTripCancellationEmail?: boolean
}

const Notifications = (): ReactElement => {
  const { t } = useTranslation()
  const { handleSubmit, reset, control, formState, setError } = useForm<NotificationsFormValues>({
    defaultValues: processDefaultValues({
      wantsNewTripEmail: false,
      wantsUpdateTripEmail: false,
      wantsTripCancellationEmail: false,
    }),
    criteriaMode: 'all',
  })

  const fetchPreferences = async (): Promise<void> => {
    const preferences = await getUserPreferences()
    reset({
      wantsNewTripEmail: preferences.wantsNewTripEmail ?? false,
      wantsUpdateTripEmail: preferences.wantsUpdateTripEmail ?? false,
      wantsTripCancellationEmail: preferences.wantsTripCancellationEmail ?? false,
    })
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchPreferences()
  }, [])

  const onSubmit: SubmitHandler<NotificationsFormValues> = async (data: NotificationsFormValues) => {
    try {
      await updateNotificationPreferences(data)
    } catch (error) {
      setError('root', processServerErrorMessages(error))
    }
  }

  return (
    <Paper
      elevation={8}
      sx={{
        padding: 2,
      }}
    >
      <KNTypography mb={2} fontWeight={500}>
        {t('profile.email_notifications')}
      </KNTypography>
      <KNForm onSubmit={onSubmit}>
        <Stack direction="column" alignItems="start" spacing={1} mb={2}>
          <KNFormCheckbox name="wantsNewTripEmail" control={control} label={t('profile.new_trip_notification')} />
          <KNFormCheckbox name="wantsUpdateTripEmail" control={control} label={t('profile.trip_update_notification')} />
          <KNFormCheckbox
            name="wantsTripCancellationEmail"
            control={control}
            label={t('profile.trip_cancellation_notification')}
          />
        </Stack>
        <KNLoadingButton
          type="submit"
          color="primary"
          variant="contained"
          size="small"
          disabled={!formState.isDirty}
          loading={formState.isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          {t('general.save')}
        </KNLoadingButton>
      </KNForm>
    </Paper>
  )
}

export default Notifications
