import i18n from 'i18n'

export const bruceBannerTranslations = (date?: string | 0, liveDate?: string | 0): Record<any, any> => {
  const bruce_banner = 'modules.shared.bruce_banner'
  return {
    translation: {
      information_msg_past: i18n.t(`${bruce_banner}.information_msg_past`, {
        date: date,
      }),
      information_msg_future: i18n.t(`${bruce_banner}.information_msg_future`, {
        date: date,
      }),
      information_msg_maintenance_future: i18n.t(`${bruce_banner}.information_msg_maintenance_future`, {
        date: date,
        liveDate: liveDate,
      }),
      information_msg_maintenance_in_progress: i18n.t(`${bruce_banner}.information_msg_maintenance_in_progress`, {
        date: date,
      }),
      information_msg_downtime: i18n.t(`${bruce_banner}.information_msg_downtime`, {
        date: date,
      }),
      help_info: i18n.t(`${bruce_banner}.help_info`),
    },
  }
}
