import { Driver, PopoverDOM, driver } from 'driver.js'
import 'driver.js/dist/driver.css'

import i18n from 'i18n'

// Functional
import { postUserPreferences } from 'screens/UserManager/UserManager.service'

// Types
import { WelcomeGuideStatus } from 'screens/UserManager/UserManager.types'

export const getWelcomeGuide = (updateUserPreferences: boolean): Driver => {
  const welcomeGuide = driver({
    showProgress: true,
    showButtons: ['next'],
    nextBtnText: i18n.t('general.next'),
    doneBtnText: i18n.t('general.close'),
    onPopoverRender: (popover: PopoverDOM) => {
      if (welcomeGuide.isLastStep()) {
        return
      }
      const skipButton = document.createElement('button')
      skipButton.innerText = i18n.t('general.skip')
      popover.footerButtons.appendChild(skipButton)
      skipButton.addEventListener('click', async () => {
        welcomeGuide.destroy()
        updateUserPreferences && (await postUserPreferences({ welcomeGuideTripList: WelcomeGuideStatus.SKIPPED }))
      })
    },
    steps: [
      {
        element: '[data-guide="predefined-filters"]',
        popover: {
          title: i18n.t('trip_dashboard.guide.predefined_filters.title'),
          description: i18n.t('trip_dashboard.guide.predefined_filters.description'),
          side: 'bottom',
          align: 'center',
        },
      },
      {
        element: '[data-guide="trip-filters"]',
        popover: {
          title: i18n.t('trip_dashboard.guide.trip_filters.title'),
          description: i18n.t('trip_dashboard.guide.trip_filters.description'),
          side: 'top',
          align: 'center',
        },
      },
      {
        element: '[data-guide="datatable"] tr[data-index="0"]',
        popover: {
          title: i18n.t('trip_dashboard.guide.trip_row.title'),
          description: i18n.t('trip_dashboard.guide.trip_row.description'),
          side: 'top',
          align: 'center',
        },
        onHighlightStarted: (element, step, options) => {
          document.querySelector('[data-guide="datatable"] tr[data-index="0"]')?.classList.add('forced-hover')
        },
        onDeselected: (element) => {
          document.querySelector('[data-guide="datatable"] tr[data-index="0"]')?.classList.remove('forced-hover')
        },
      },
      {
        element: '[data-guide="datatable"] tr[data-index="0"] [data-guide="trip-summary"]',
        popover: {
          title: i18n.t('trip_dashboard.guide.trip_summary.title'),
          description: i18n.t('trip_dashboard.guide.trip_summary.description'),
          side: 'top',
          align: 'center',
        },
        onHighlightStarted: (element, step, options) => {
          document.querySelector('[data-guide="datatable"] tr[data-index="0"]')?.classList.add('forced-hover')
        },
        onDeselected: (element) => {
          document.querySelector('[data-guide="datatable"] tr[data-index="0"]')?.classList.remove('forced-hover')
        },
      },
      {
        element: '[data-guide="datatable"] tr[data-index="0"] [data-guide="driver-vehicle-actions"]',
        popover: {
          title: i18n.t('trip_dashboard.guide.driver_vehicle_actions.title'),
          description: i18n.t('trip_dashboard.guide.driver_vehicle_actions.description'),
          side: 'top',
          align: 'center',
        },
        onHighlightStarted: (element, step, options) => {
          document.querySelector('[data-guide="datatable"] tr[data-index="0"]')?.classList.add('forced-hover')
        },
        onDeselected: (element) => {
          document.querySelector('[data-guide="datatable"] tr[data-index="0"]')?.classList.remove('forced-hover')
        },
      },
      {
        element: '[data-guide="datatable"] tr[data-index="0"] [data-guide="datatable-actions"]',
        popover: {
          title: i18n.t('trip_dashboard.guide.trip_actions.title'),
          description: i18n.t('trip_dashboard.guide.trip_actions.description'),
          side: 'top',
          align: 'center',
        },
        onHighlightStarted: (element, step, options) => {
          document.querySelector('[data-guide="datatable"] tr[data-index="0"]')?.classList.add('forced-hover')
        },
        onDeselected: (element) => {
          document.querySelector('[data-guide="datatable"] tr[data-index="0"]')?.classList.remove('forced-hover')
        },
      },
      {
        element: '[data-guide="menu-profile"]',
        popover: {
          title: i18n.t('trip_dashboard.guide.menu_profile.title'),
          description: i18n.t('trip_dashboard.guide.menu_profile.description'),
          side: 'right',
          align: 'center',
        },
      },
      {
        element: '[data-guide="feedback"]',
        popover: {
          title: i18n.t('trip_dashboard.guide.feedback.title'),
          description: i18n.t('trip_dashboard.guide.feedback.description'),
          side: 'left',
          align: 'center',
        },
      },
    ],
    onDestroyStarted: async () => {
      if (welcomeGuide.isLastStep() && updateUserPreferences) {
        try {
          await postUserPreferences({ welcomeGuideTripList: WelcomeGuideStatus.DONE })
        } catch {
          //
        }
      }
      welcomeGuide.destroy()
    },
  })

  return welcomeGuide
}
