import i18n from 'i18n'

export const shipmentDetailsMenuTranslations = (): Record<any, any> => {
  const shipmentDetailsMenu = 'modules.cv.shipment_details_menu'
  return {
    translation: {
      followShipment: i18n.t(`${shipmentDetailsMenu}.followShipment`),
      unFollowShipment: i18n.t(`${shipmentDetailsMenu}.unfollowShipment`),
      shareShipment: i18n.t(`${shipmentDetailsMenu}.shareShipment`),
      shareShipmentMessage: i18n.t(`${shipmentDetailsMenu}.shareShipmentMessage`),
      editPairing: i18n.t(`${shipmentDetailsMenu}.editPairing`),
      unpairShipment: i18n.t(`${shipmentDetailsMenu}.unpairShipment`),
      geofence: i18n.t(`${shipmentDetailsMenu}.geofence`),
    },
  }
}
