import { ReactElement } from 'react'
import { Controller, FieldValues } from 'react-hook-form'

// @mui imports
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import FormHelperText from '@mui/material/FormHelperText'

// KN imports
import { getFieldErrorMessages } from 'global/helpers/form'
import { KNFormRadioGroupProps } from './types'

const KNFormRadioGroup = <T extends FieldValues>({
  name,
  control,
  rules,
  label,
  options,
  size,
  sx,
  row,
  disabled,
}: KNFormRadioGroupProps<T>): ReactElement => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field: { onChange, value, name, ref }, fieldState: { invalid, error }, formState }): ReactElement => (
      <FormControl fullWidth sx={sx} error={invalid} disabled={formState.isSubmitting}>
        {label && <FormLabel sx={{ marginBottom: 1 }}>{label}</FormLabel>}
        <RadioGroup
          data-test="role-radio-group"
          value={value}
          onChange={(event): void => onChange(event.target.value)}
          row={row}
        >
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio color={option.color?.componentColor || 'primary'} size={size} />}
              label={option.label}
              sx={{ color: value === option.value ? option.color?.color || 'primary.main' : undefined }}
            />
          ))}
        </RadioGroup>
        {error?.message && <FormHelperText>{getFieldErrorMessages(error)}</FormHelperText>}
      </FormControl>
    )}
  />
)

export default KNFormRadioGroup
