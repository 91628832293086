import { useState, useMemo, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNDataTable from 'components/KN_Molecules/KNDataTable/KNDataTable'
import CreateOrEditVehicleDialog from './CreateOrEditVehicleDialog'
import DeleteVehicleDialog from './DeleteVehicleDialog'
import TrackingLabel from './TrackingLabel'

// Functional
import { analyticsEvent } from 'global/helpers/analytics'
import { hasRole } from 'global/helpers/authorization'

// Types
import { VehicleData } from './VehicleManager.types'
import { Role } from 'context/authentication/Role.types'

interface VehicleTableProps {
  vehicles: VehicleData[]
  onChange?: (updatedVehicle: VehicleData, action: string) => void
}

const VehicleTable = ({ vehicles, onChange }: VehicleTableProps): ReactElement => {
  const { t } = useTranslation()
  const [activeVehicle, setActiveVehicle] = useState<VehicleData>()
  const [createOrEditVehicleDialogOpen, setCreateOrEditVehicleDialogOpen] = useState(false)
  const [deleteVehicleDialogOpen, setDeleteVehicleDialogOpen] = useState(false)

  const handleEditClick = useCallback((row: VehicleData) => {
    setActiveVehicle(row)
    setCreateOrEditVehicleDialogOpen(true)
    analyticsEvent('polestar_cs_edit_vehicle_button_clicked')
  }, [])

  const handleDeleteClick = useCallback((row: VehicleData) => {
    setActiveVehicle(row)
    setDeleteVehicleDialogOpen(true)
    analyticsEvent('polestar_cs_delete_vehicle_button_clicked')
  }, [])

  const isNotTelematicsReady = useCallback((row: VehicleData): boolean => !row.telematicsReady, [])

  const columns = useMemo(
    () => [
      {
        name: 'displayLicensePlate',
        label: t('vehicle_manager.card.columns.license_plate'),
        getValue: (row: VehicleData) => (
          <Stack spacing={1} direction="row" alignItems="center">
            <Box>{row.displayLicensePlate}</Box>
            {row.telematicsReady && <TrackingLabel />}
          </Stack>
        ),
        sx: { width: { xs: '24rem', sm: 'auto' } },
      },
      {
        name: 'vehicleType',
        label: t('vehicle_manager.card.columns.vehicle_type'),
        getValue: (row: VehicleData) => t(`vehicle_manager.vehicle_types.${row.vehicleType}`),
        sx: { width: '12rem' },
      },
      {
        name: 'weightCapacity',
        label: t('vehicle_manager.card.columns.weight_capacity'),
        getValue: (row: VehicleData) =>
          row.weightCapacity ? (
            <>
              {row.weightCapacity} <small>kg</small>
            </>
          ) : (
            <KNTypography color="primary.light">—</KNTypography>
          ),
        sx: { width: '12rem' },
      },
      {
        name: 'fuelType',
        label: t('vehicle_manager.card.columns.fuel_type'),
        getValue: (row: VehicleData) =>
          row.fuelType ? (
            t(`vehicle_manager.fuel_types.${row.fuelType}`)
          ) : (
            <KNTypography color="primary.light">—</KNTypography>
          ),
        sx: { width: '12rem' },
      },
      {
        name: 'makeModel',
        label: t('vehicle_manager.card.columns.make_model'),
        getValue: (row: VehicleData) => row.makeModel ?? <KNTypography color="primary.light">—</KNTypography>,
        sx: { width: '12rem' },
      },
    ],
    []
  )

  const actions = useMemo(
    () => [
      {
        name: 'edit',
        label: t('vehicle_manager.card.actions.edit_short'),
        icon: <EditIcon />,
        onClick: handleEditClick,
        isVisible: isNotTelematicsReady,
      },
      {
        name: 'delete',
        label: t('vehicle_manager.card.actions.delete'),
        icon: <DeleteIcon />,
        onClick: handleDeleteClick,
        isVisible: isNotTelematicsReady,
      },
    ],
    []
  )

  const handleCreateOrEditDialogAction = useCallback((updatedVehicle: VehicleData, action: string): void => {
    setCreateOrEditVehicleDialogOpen(false)
    onChange?.(updatedVehicle, action)
  }, [])

  const handleCreateOrEditDialogClose = useCallback((): void => {
    setCreateOrEditVehicleDialogOpen(false)
  }, [])

  const handleDeleteDialogAction = useCallback((updatedVehicle: VehicleData, action: string): void => {
    setDeleteVehicleDialogOpen(false)
    onChange?.(updatedVehicle, action)
  }, [])

  const handleDeleteDialogClose = useCallback((): void => {
    setDeleteVehicleDialogOpen(false)
  }, [])

  return (
    <>
      <KNDataTable
        columns={columns}
        actions={hasRole(Role.Editor) ? actions : []}
        data={vehicles}
        sx={{
          marginX: -2,
        }}
      />
      {activeVehicle && hasRole(Role.Editor) && (
        <>
          <CreateOrEditVehicleDialog
            payload={{
              vehicle: activeVehicle,
            }}
            open={createOrEditVehicleDialogOpen}
            onAction={handleCreateOrEditDialogAction}
            onClose={handleCreateOrEditDialogClose}
          />
          <DeleteVehicleDialog
            payload={{
              vehicle: activeVehicle,
            }}
            open={deleteVehicleDialogOpen}
            onAction={handleDeleteDialogAction}
            onClose={handleDeleteDialogClose}
          />
        </>
      )}
    </>
  )
}

export default VehicleTable
