import React, { useState, useEffect, useContext, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import DoneIcon from '@mui/icons-material/Done'
import Collapse from '@mui/material/Collapse'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

// KN imports
import { analyticsEvent } from 'global/helpers/analytics'
import { ErrorMessage } from 'global/helpers/errorHandler'
import { StopsViewContext } from 'context/trips/StopsViewContext'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import { KNDataCardAction } from 'components/KN_Molecules/KNDataCard/types'
import KNConfirmationDialog from 'components/KN_Molecules/KNDialog/KNConfirmationDialog'
import KNAttribute from 'components/KN_Molecules/KNAttribute/KNAttribute'
import LegHeader from './LegHeader'
import LegCargo from './LegCargo'
import StopHeader, { getTypeLabel } from './StopHeader'
import PrimaryActions from './PrimaryActions'
import GroupedStopsCardPrimaryStop from './GroupedStopsCardPrimaryStop'
import GroupedStopsCardCustomsStop from './GroupedStopsCardCustomsStop'
import { GroupedStopsCardProps } from './GroupedStopsCard.types'
import BulkStatusUpdateDialog from 'screens/StatusManager/BulkStatusUpdateDialog'
import { getStopsGroupColor } from './TripDetails.helpers'
import { StopData } from './TripDetails.types'
import LocationLink from 'screens/TripDashboard/LocationLink'

const GroupedStopsCard = ({ trip, group, weblinkToken, onChange, minimal }: GroupedStopsCardProps): ReactElement => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const [stopsViewState, stopsViewDispatch] = useContext(StopsViewContext)
  const [bulkPickupStatusUpdateDialogOpen, setBulkPickupStatusUpdateDialogOpen] = useState(false)
  const [bulkPickupStatusUpdateConfirmationDialogOpen, setBulkPickupStatusUpdateConfirmationDialogOpen] =
    useState(false)
  const [bulkDeliveryStatusUpdateDialogOpen, setBulkDeliveryStatusUpdateDialogOpen] = useState(false)
  const [bulkDeliveryStatusUpdateConfirmationDialogOpen, setBulkDeliveryStatusUpdateConfirmationDialogOpen] =
    useState(false)
  const [bulkCustomsStatusUpdateDialogOpen, setBulkCustomsStatusUpdateDialogOpen] = useState(false)
  const [bulkCustomsStatusUpdateConfirmationDialogOpen, setBulkCustomsStatusUpdateConfirmationDialogOpen] =
    useState(false)
  const pickupStopLegPairs = group.stopLegPairs.filter((stopLegPair) => stopLegPair.stop.type === 'PUP')
  const deliveryStopLegPairs = group.stopLegPairs.filter((stopLegPair) => stopLegPair.stop.type === 'DEL')
  const customsStopLegPairs = group.stopLegPairs.filter((stopLegPair) => stopLegPair.stop.type === 'CUS')
  const pickupStopLegPairsToUpdate = pickupStopLegPairs.filter(
    (stopLegPair) => stopLegPair.stop.availableStatuses.length > 0
  )
  const deliveryStopLegPairsToUpdate = deliveryStopLegPairs.filter(
    (stopLegPair) => stopLegPair.stop.availableStatuses.length > 0
  )
  const customsStopLegPairsToUpdate = customsStopLegPairs.filter(
    (stopLegPair) => stopLegPair.stop.availableStatuses.length > 0
  )

  const handleHeaderClick = useCallback(() => {
    stopsViewDispatch({
      type: 'setActiveStopsGroupSequence',
      payload: group.sequence,
    })
  }, [])

  const handleBulkPickupStatusUpdateClick = useCallback((): void => {
    setBulkPickupStatusUpdateConfirmationDialogOpen(true)
  }, [])
  const handleBulkPickupStatusUpdateAction = useCallback((updatedStops: StopData[]): void => {
    setBulkPickupStatusUpdateDialogOpen(false)
    onChange?.(updatedStops)
  }, [])
  const handleBulkPickupStatusUpdateClose = useCallback((): void => {
    setBulkPickupStatusUpdateDialogOpen(false)
  }, [])

  const handleBulkPickupStatusUpdateConfirmationDialogAction = useCallback((): void => {
    setBulkPickupStatusUpdateConfirmationDialogOpen(false)
    stopsViewDispatch({
      type: 'clearStopsErrors',
    })
    setBulkPickupStatusUpdateDialogOpen(true)
  }, [])
  const handleBulkPickupStatusUpdateConfirmationDialogClose = useCallback((): void => {
    setBulkPickupStatusUpdateConfirmationDialogOpen(false)
  }, [])

  const handleBulkDeliveryStatusUpdateClick = useCallback((): void => {
    setBulkDeliveryStatusUpdateConfirmationDialogOpen(true)
  }, [])
  const handleBulkDeliveryStatusUpdateAction = useCallback((updatedStops: StopData[]): void => {
    setBulkDeliveryStatusUpdateDialogOpen(false)
    onChange?.(updatedStops)
  }, [])
  const handleBulkDeliveryStatusUpdateClose = useCallback((): void => {
    setBulkDeliveryStatusUpdateDialogOpen(false)
  }, [])

  const handleBulkDeliveryStatusUpdateConfirmationDialogAction = useCallback((): void => {
    setBulkDeliveryStatusUpdateConfirmationDialogOpen(false)
    stopsViewDispatch({
      type: 'clearStopsErrors',
    })
    setBulkDeliveryStatusUpdateDialogOpen(true)
  }, [])
  const handleBulkDeliveryStatusUpdateConfirmationDialogClose = useCallback((): void => {
    setBulkDeliveryStatusUpdateConfirmationDialogOpen(false)
  }, [])

  const handleBulkCustomsStatusUpdateClick = useCallback((): void => {
    setBulkCustomsStatusUpdateConfirmationDialogOpen(true)
  }, [])
  const handleBulkCustomsStatusUpdateAction = useCallback((updatedStops: StopData[]): void => {
    setBulkCustomsStatusUpdateDialogOpen(false)
    onChange?.(updatedStops)
  }, [])
  const handleBulkCustomsStatusUpdateClose = useCallback((): void => {
    setBulkCustomsStatusUpdateDialogOpen(false)
  }, [])

  const handleBulkCustomsStatusUpdateConfirmationDialogAction = useCallback((): void => {
    setBulkCustomsStatusUpdateConfirmationDialogOpen(false)
    stopsViewDispatch({
      type: 'clearStopsErrors',
    })
    setBulkCustomsStatusUpdateDialogOpen(true)
  }, [])
  const handleBulkCustomsStatusUpdateConfirmationDialogClose = useCallback((): void => {
    setBulkCustomsStatusUpdateConfirmationDialogOpen(false)
  }, [])

  const handleBulkStatusUpdateError = useCallback((errors: ErrorMessage[], updatedStops: StopData[]): void => {
    setBulkPickupStatusUpdateDialogOpen(false)
    setBulkDeliveryStatusUpdateDialogOpen(false)
    setBulkCustomsStatusUpdateDialogOpen(false)
    stopsViewDispatch({
      type: 'setStopsErrors',
      payload: errors,
    })
    onChange?.(updatedStops)
  }, [])

  const actions: KNDataCardAction[] = []
  if (pickupStopLegPairsToUpdate.length > 0) {
    actions.push({
      label: t('trip_details.card.actions.bulk_pickup_update_status'),
      handler: handleBulkPickupStatusUpdateClick,
    })
  }
  if (deliveryStopLegPairsToUpdate.length > 0) {
    actions.push({
      label: t('trip_details.card.actions.bulk_delivery_update_status'),
      handler: handleBulkDeliveryStatusUpdateClick,
    })
  }
  if (customsStopLegPairsToUpdate.length > 0) {
    actions.push({
      label: t('trip_details.card.actions.bulk_customs_update_status'),
      handler: handleBulkCustomsStatusUpdateClick,
    })
  }

  return (
    <>
      <Stack
        direction="row"
        spacing={{ xs: 1, md: 2 }}
        justifyContent="stretch"
        sx={{
          position: 'relative',
        }}
      >
        {!minimal && (
          <Stack
            direction="column"
            alignItems="center"
            sx={{
              width: { xs: '2rem', md: '2.5rem' },
              paddingTop: { xs: '.75rem', md: '0.5rem' },
            }}
          >
            <Avatar
              sx={{
                backgroundColor: getStopsGroupColor(group.state),
                width: { xs: '2rem', md: '2.5rem' },
                height: { xs: '2rem', md: '2.5rem' },
              }}
            >
              {group.state === 'completed' ? <DoneIcon /> : group.sequence}
            </Avatar>
            <Divider
              orientation="vertical"
              sx={{
                position: 'absolute',
                top: '2.75rem',
                borderColor: getStopsGroupColor(group.state),
                borderStyle: group.state === 'pending' ? 'dashed' : 'solid',
                borderRightWidth: '2px',
                '.MuiStack-root:last-child > .MuiStack-root > &': {
                  display: 'none',
                },
                zIndex: -1,
              }}
            />
          </Stack>
        )}
        <Paper
          elevation={8}
          sx={{
            padding: 2,
            width: '100%',
          }}
        >
          <Stack
            data-test="stops"
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'start', md: 'center' }}
            justifyContent="space-between"
            sx={{
              cursor: 'pointer',
              flexWrap: 'wrap',
              margin: -2,
              padding: 2,
            }}
            onClick={handleHeaderClick}
          >
            <Stack data-test="country" direction="column">
              <KNTypography color="text.main" variant="displayXXS_SB">
                {group.stopLegPairs[0].stop.address.name.join(' ') || getTypeLabel(group.stopLegPairs[0].stop.type)}
              </KNTypography>
              <LocationLink
                countryCode={group.stopLegPairs[0].stop.address.countryCode}
                city={group.stopLegPairs[0].stop.address.city.join(', ')}
                zipCode={group.stopLegPairs[0].stop.address.zipCode}
                street={group.stopLegPairs[0].stop.address.street.join(', ')}
                marker={
                  group.stopLegPairs[0].stop.geoPoint
                    ? {
                        coords: {
                          lat: group.stopLegPairs[0].stop.geoPoint.latitude,
                          lng: group.stopLegPairs[0].stop.geoPoint.longitude,
                        },
                        type: group.stopLegPairs[0].stop.type,
                      }
                    : undefined
                }
                lineBreaks={isMobile ? 'all' : 'city'}
                hideMap={stopsViewState.activeStopsGroupSequence !== group.sequence}
              />
            </Stack>
            <Stack spacing={2} direction="row" alignItems="center" justifyContent={{ xs: 'start', md: 'end' }}>
              {pickupStopLegPairs.length > 0 && (
                <KNTypography color="text.main" variant="textLG">
                  {t('trip_details.card.pickups_count', { count: pickupStopLegPairs.length })}
                </KNTypography>
              )}
              {deliveryStopLegPairs.length > 0 && (
                <KNTypography color="text.main" variant="textLG">
                  {t('trip_details.card.deliveries_count', { count: deliveryStopLegPairs.length })}
                </KNTypography>
              )}
              {customsStopLegPairs.length > 0 && (
                <KNTypography color="text.main" variant="textLG">
                  {t('trip_details.card.customs_count', { count: customsStopLegPairs.length })}
                </KNTypography>
              )}
            </Stack>
          </Stack>

          <Collapse in={minimal ? true : stopsViewState.activeStopsGroupSequence === group.sequence} timeout={200}>
            <Divider orientation="horizontal" sx={{ my: 2, mx: -2 }} flexItem />
            <Stack
              direction="column"
              spacing={2}
              divider={<Divider orientation="horizontal" flexItem />}
              sx={{
                margin: -2,
                padding: 2,
                maxHeight: minimal ? '13rem' : undefined,
                overflowY: 'scroll',
              }}
            >
              {group.stopLegPairs.map((stopLegPair) => {
                if (stopLegPair.stop.type === 'PUP' || stopLegPair.stop.type === 'DEL') {
                  return (
                    <GroupedStopsCardPrimaryStop
                      key={stopLegPair.stop.wayPointCid}
                      trip={trip}
                      leg={stopLegPair.leg}
                      stop={stopLegPair.stop}
                      weblinkToken={weblinkToken}
                      onChange={onChange}
                    />
                  )
                } else if (stopLegPair.stop.type === 'CUS') {
                  return (
                    <GroupedStopsCardCustomsStop
                      key={stopLegPair.stop.wayPointCid}
                      trip={trip}
                      leg={stopLegPair.leg}
                      stop={stopLegPair.stop}
                      weblinkToken={weblinkToken}
                      onChange={onChange}
                    />
                  )
                }
              })}
            </Stack>
            {actions.length > 0 && (
              <>
                <Divider orientation="horizontal" sx={{ my: 2, mx: -2 }} flexItem />
                <PrimaryActions actions={actions} />
              </>
            )}
          </Collapse>
        </Paper>
      </Stack>

      {pickupStopLegPairsToUpdate.length > 0 && (
        <>
          <BulkStatusUpdateDialog
            payload={{
              trip,
              stopLegPairs: pickupStopLegPairsToUpdate,
              weblinkToken,
            }}
            open={bulkPickupStatusUpdateDialogOpen}
            onAction={handleBulkPickupStatusUpdateAction}
            onError={handleBulkStatusUpdateError}
            onClose={handleBulkPickupStatusUpdateClose}
          />

          <KNConfirmationDialog
            message={t('trip_details.update_status.bulk_pickup_status_update_disclaimer')}
            continueLabel={t('general.confirm')}
            open={bulkPickupStatusUpdateConfirmationDialogOpen}
            onAction={handleBulkPickupStatusUpdateConfirmationDialogAction}
            onClose={handleBulkPickupStatusUpdateConfirmationDialogClose}
          />
        </>
      )}

      {deliveryStopLegPairsToUpdate.length > 0 && (
        <>
          <BulkStatusUpdateDialog
            payload={{
              trip,
              stopLegPairs: deliveryStopLegPairsToUpdate,
              weblinkToken,
            }}
            open={bulkDeliveryStatusUpdateDialogOpen}
            onAction={handleBulkDeliveryStatusUpdateAction}
            onError={handleBulkStatusUpdateError}
            onClose={handleBulkDeliveryStatusUpdateClose}
          />

          <KNConfirmationDialog
            message={t('trip_details.update_status.bulk_delivery_status_update_disclaimer')}
            continueLabel={t('general.confirm')}
            open={bulkDeliveryStatusUpdateConfirmationDialogOpen}
            onAction={handleBulkDeliveryStatusUpdateConfirmationDialogAction}
            onClose={handleBulkDeliveryStatusUpdateConfirmationDialogClose}
          />
        </>
      )}

      {customsStopLegPairsToUpdate.length > 0 && (
        <>
          <BulkStatusUpdateDialog
            payload={{
              trip,
              stopLegPairs: customsStopLegPairsToUpdate,
              weblinkToken,
            }}
            open={bulkCustomsStatusUpdateDialogOpen}
            onAction={handleBulkCustomsStatusUpdateAction}
            onError={handleBulkStatusUpdateError}
            onClose={handleBulkCustomsStatusUpdateClose}
          />

          <KNConfirmationDialog
            message={t('trip_details.update_status.bulk_customs_status_update_disclaimer')}
            continueLabel={t('general.confirm')}
            open={bulkCustomsStatusUpdateConfirmationDialogOpen}
            onAction={handleBulkCustomsStatusUpdateConfirmationDialogAction}
            onClose={handleBulkCustomsStatusUpdateConfirmationDialogClose}
          />
        </>
      )}
    </>
  )
}

export default GroupedStopsCard
