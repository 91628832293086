import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const strokeWidth = 2

export const KNRoadIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M22 4 2 60M42 4l20 56" />
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M32 12v10m0 8v10m0 8v10" />
  </SvgIcon>
)

export const KNLoopIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M15.998 53.997A8.251 8.251 0 0 1 8 45.75v-27.5A8.25 8.25 0 0 1 16.25 10H37"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M48 10.004a8.25 8.25 0 0 1 8 8.246v27.5A8.25 8.25 0 0 1 47.75 54H27"
    />
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="m28 18 9-8-9-8" />
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="m36 46-9 8 9 8" />
  </SvgIcon>
)

export const KNTruckIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M38 19h14l6 12 4 4v8" />
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M2 43V7h36v36" />
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M44 51H20M8 51H2v-8h60v8h-6" />
    <circle fill="none" stroke="currentColor" strokeWidth={strokeWidth} cx="14" cy="51" r="6" />
    <circle fill="none" stroke="currentColor" strokeWidth={strokeWidth} cx="50" cy="51" r="6" />
  </SvgIcon>
)

export const KNProfileIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M28 8H8v54h48V8H36" />
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M28 2h8v10h-8z" />
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M44 48H20m16 8H20" />
    <circle fill="none" stroke="currentColor" strokeWidth={strokeWidth} cx="32" cy="25" r="6" />
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M42 41a10 10 0 1 0-20 0z" />
  </SvgIcon>
)

export const KNPairingIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M37 30.3l.8.8a9.3 9.3 0 0 0 13.2 0l8.3-8.3a9.3 9.3 0 0 0 0-13.2l-5-5a9.3 9.3 0 0 0-13.2 0L32.8 13a9.3 9.3 0 0 0 0 13.2l.8.8m3.4-3.3l-.8-.8a4.7 4.7 0 0 1 0-6.6L44.4 8A4.7 4.7 0 0 1 51 8l5 5a4.7 4.7 0 0 1 0 6.6l-8.3 8.3a4.7 4.7 0 0 1-6.6 0l-.8-.8M27 33.7l-.8-.8a9.3 9.3 0 0 0-13.2 0l-8.3 8.2a9.3 9.3 0 0 0 0 13.2l5 5a9.3 9.3 0 0 0 13.2 0l8.3-8.3a9.3 9.3 0 0 0 0-13.2l-.8-.8M27 40.3l.8.8a4.7 4.7 0 0 1 0 6.6L19.6 56a4.7 4.7 0 0 1-6.6 0l-5-5a4.7 4.7 0 0 1 0-6.6l8.3-8.3a4.7 4.7 0 0 1 6.6 0l.8.8"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M45.4 18.6a2.3 2.3 0 0 1 0 3.3l-24 24a2.3 2.3 0 0 1-3.3 0 2.3 2.3 0 0 1 0-3.3l24-24a2.3 2.3 0 0 1 3.3 0z"
    />
  </SvgIcon>
)

export const KNUnpairingIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M14.9 26h-13m22-11V2m-8 16L6.7 8.8M48.9 38h13m-22 11v13m8-16l9.2 9.2"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M27 41.1a4.7 4.7 0 0 1 0 6.6L18.7 56a4.7 4.7 0 0 1-6.6 0l-4-4a4.7 4.7 0 0 1 0-6.6l8.3-8.3a4.7 4.7 0 0 1 6.6 0l2.2-4.2a9.4 9.4 0 0 0-12.2.9l-8.2 8.3a9.3 9.3 0 0 0 0 13.2l4 4a9.3 9.3 0 0 0 13.2 0l8.3-8.3a9.4 9.4 0 0 0 .9-12.2zm10.2-18.2a4.7 4.7 0 0 1 0-6.6L45.5 8a4.7 4.7 0 0 1 6.6 0l4 4a4.7 4.7 0 0 1 0 6.6l-8.3 8.3a4.7 4.7 0 0 1-6.6 0L39 31.1a9.4 9.4 0 0 0 12.2-.9l8.3-8.3a9.3 9.3 0 0 0 0-13.2l-4-4a9.3 9.3 0 0 0-13.2 0L33.9 13a9.4 9.4 0 0 0-.9 12.2z"
    />
  </SvgIcon>
)

export const KNVisibilityIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M32 8V2" />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M22 28v34m20-34v34m-28 0h36M18 28h28m-20 0V16m12 12V16m-14 0h16m-14 0v-2a6 6 0 1 1 12 0v2"
    />
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M16 16L4 10m12 12L4 28m44-6l12 6M48 16l12-6" />
  </SvgIcon>
)

export const KNUserIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M46 26c0 6.1-3.4 11.5-7 14.8V44c0 2 1 5.1 11 7a15.5 15.5 0 0 1 12 11H2a13.4 13.4 0 0 1 11-11c10-1.8 12-5 12-7v-3.2c-3.6-3.3-7-8.6-7-14.8v-9.6C18 6 25.4 2 32 2s14 4 14 14.4z"
    />
  </SvgIcon>
)

export const KNUsersIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M27.4 12.2c.9-3.3 3.2-7.4 9-7.4 0 0 16-5.1 16 11.2 0 13.7 3.1 15.2 6.4 18.5A56.6 56.6 0 0 1 46 36v1.6c0 1.6-.3 4.8 7.7 6.4s8.3 8 8.3 8H41"
      data-name="layer2"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M38.1 51.2c-8-1.1-8-4-8-5.6v-.9a14.6 14.6 0 0 0 4.2-7c2.4 0 3-5.1 3-5.8s-.2-3.6-1.3-3.9c4-14-8-20-18.4-12.8-4.4 0-5.8 6.4-4.2 12.8-1.1 0-1.3 3-1.3 3.8s.6 5.8 3 5.8c.8 2.9 3 5.3 4.9 7v1c0 1.6-.6 4.6-8.7 5.6S2 60 2 60h44s.1-7.7-7.9-8.8z"
    />
  </SvgIcon>
)

export const KNCompaniesIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <circle cx="32" cy="39" r="7" fill="none" stroke="currentColor" strokeWidth={strokeWidth} />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M32 46a12.1 12.1 0 0 0-12 12v2h24v-2a12.1 12.1 0 0 0-12-12z"
    />
    <circle cx="52" cy="10" r="6" fill="none" stroke="currentColor" strokeWidth={strokeWidth} />
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M62 28c0-7.5-4.5-12-10-12s-10 4.5-10 12z" />
    <circle cx="12" cy="10" r="6" fill="none" stroke="currentColor" strokeWidth={strokeWidth} />
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M22 28c0-7.5-4.5-12-10-12S2 20.5 2 28z" />
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M12 34l8 8m32-8l-8 8M24 14h16" />
  </SvgIcon>
)

export const KNCarriersIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <circle cx="9.12" cy="51.19" r="5.44" fill="none" strokeWidth={strokeWidth} stroke="currentColor" />
    <circle cx="47.31" cy="51.19" r="5.44" fill="none" strokeWidth={strokeWidth} stroke="currentColor" />
    <path
      fill="none"
      strokeWidth={strokeWidth}
      d="M42.04 52.14H14.56M3.46 44.25h24.41M25.45 7.37l2.42 4.84v39.92M37.55 36.41h-2.42M32.71 36.41h-2.42M48.44 36.41V25.52M41.18 25.52v10.89h7.26l10.16 2a2.41 2.41 0 0 1 1.94 2.37v11.36h-7.26"
      stroke="currentColor"
    />
    <path
      fill="none"
      strokeWidth={strokeWidth}
      d="M27.87 17.05v8.47h20.57c0-3.33-7.44-8.39-20.57-8.47zM8.08 39.25h11.15v5H8.08z"
      stroke="currentColor"
    />
  </SvgIcon>
)

export const KNTripIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M11 44l-8 8 8 8m42-40l8-8-8-8" />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M60.8 12H12A10 10 0 0 0 2 22a10 10 0 0 0 10 10h40a10 10 0 0 1 10 10 10 10 0 0 1-10 10H3"
    />
  </SvgIcon>
)

export const KNDriverIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <circle cx="32" cy="32" r="30" fill="none" stroke="currentColor" strokeWidth={strokeWidth} />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M32 22c9.1 0 16.3 4 21.3 4A21.6 21.6 0 0 0 32 10a21.6 21.6 0 0 0-21.3 16c4.9 0 12.2-4 21.3-4zm6 14c-2.6 3.6-1.3 11.1.9 16.9C47.4 50 53.6 43.4 54 34c-4.3 0-14.4-.3-16 2zm-12 0c2.6 3.6 1.3 11.1-.9 16.9C16.6 50 10.3 43.4 10 34c4.3 0 14.4-.3 16 2z"
    />
  </SvgIcon>
)

export const KNHideMenuIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path fill="none" stroke="currentColor" strokeWidth={4} d="M34 18H6m28 14H6m28 14H6" />
    <path fill="none" stroke="currentColor" strokeWidth={4} d="M58 20L44 31.994 58 44" />
  </SvgIcon>
)

export const KNRevealIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path fill="none" stroke="currentColor" strokeWidth={4} d="M30 18h28M30 32h28M30 46h28" />
    <path fill="none" stroke="currentColor" strokeWidth={4} d="M6 20l14 11.994L6 44" />
  </SvgIcon>
)

export const KNHomeIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path fill="none" stroke="currentColor" strokeWidth={4} d="M2 31L32 5l30 26" />
    <path fill="none" stroke="currentColor" strokeWidth={4} d="M54 19v-9h-9" />
    <path fill="none" stroke="currentColor" strokeWidth={4} d="M10 32.167V62h14V42h16v20h14V32.083" />
  </SvgIcon>
)

export const KNReleaseNotesIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M2 13h10m-7.5 4.3l5-8.6m0 8.6l-5-8.6M2 31h10m-7.5 4.3l5-8.6m0 8.6l-5-8.6M2 49h10m-7.5 4.3l5-8.6m0 8.6l-5-8.6"
    />
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M22 13h40M22 31h40M22 49h40" />
  </SvgIcon>
)

export const KNHelpAndInfoIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M22 21.41c0-3.6 2.758-9.41 10.587-9.41C40.417 12 42 19.035 42 21.41s-.738 5.116-5.07 9.448C32.595 35.19 32 37.44 32 39.5V42"
    />
    <circle cx="32" cy="49" r="3" fill="none" stroke="currentColor" strokeWidth={strokeWidth} />
  </SvgIcon>
)

export const KNShipmentIdIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path
      strokeWidth="2"
      className="cls-1"
      d="M32.1,62V12m-12,14h24m-34,14a22,22,0,0,0,44,0"
      fill="none"
      stroke="currentColor"
    />
    <circle strokeWidth="5" className="cls-1" cx="32.1" cy="7" r="5" fill="none" stroke="currentColor" />
    <path strokeWidth="5" className="cls-1" d="M9.9,38.7,5.5,45.2" fill="none" stroke="currentColor" />
    <path strokeWidth="5" className="cls-1" d="M58.5,45.2l-4.4-6.5" fill="none" stroke="currentColor" />
    <path strokeWidth="5" className="cls-1" d="M53.9,40l-5.5,3.7" fill="none" stroke="currentColor" />
    <path strokeWidth="5" className="cls-1" d="M15.6,43.7,10.1,40" fill="none" stroke="currentColor" />
  </SvgIcon>
)

export const KNShipmentDescriptionIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path
      data-name="layer2"
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M57 2v60"
      strokeLinejoin="miter"
      strokeLinecap="butt"
    />
    <path
      data-name="layer1"
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M7 10v8h4v-4h11.5v36H15v4h20v-4h-7.5V14H39v4h4v-8H7z"
      strokeLinejoin="miter"
      strokeLinecap="butt"
    />
  </SvgIcon>
)
