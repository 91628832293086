import { useState, useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler } from 'react-hook-form'

// @mui imports
import Stack from '@mui/material/Stack'
import DialogContentText from '@mui/material/DialogContentText'
import Alert from '@mui/material/Alert'

// KN imports
import { analyticsEvent } from 'global/helpers/analytics'
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import { processServerErrorMessages, processDefaultValues } from 'global/helpers/form'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import KNFormText from 'components/KN_Molecules/KNForm/KNFormText'
import { TripData } from './TripDashboard.types'
import { sendSmsToDriver } from './TripDashboard.service'

interface SmsDriverDialogPayload {
  trip: TripData
}

interface SmsDriverDialogProps {
  payload: SmsDriverDialogPayload
  open: boolean
  onAction: () => void
  onClose: () => void
}

export interface SmsDriverFormValues {
  body: string
  phoneNumber: string
}

const SmsDriverDialog = ({ payload, open, onAction, onClose }: SmsDriverDialogProps): ReactElement => {
  const { t } = useTranslation()
  const { handleSubmit, reset, control, formState, setError } = useForm<SmsDriverFormValues>()

  useEffect(() => {
    if (open) {
      reset(
        processDefaultValues({
          phoneNumber: payload.trip.assignedDriver?.contactNumber,
          body: null,
        })
      )
    }
  }, [open])

  const onSubmit: SubmitHandler<SmsDriverFormValues> = async (data: SmsDriverFormValues) => {
    try {
      await sendSmsToDriver(payload.trip.entityId, data)
      onAction()
      analyticsEvent('polestar_cs_sms_sent')
    } catch (error) {
      setError('root', processServerErrorMessages(error))
    }
  }

  return (
    <KNDialog
      open={open}
      onClose={onClose}
      closeLabel={t('general.cancel')}
      preventClosing={formState.isSubmitting}
      title={t('trip_dashboard.sms_driver.title')}
      maxWidth="xs"
      actions={
        <>
          <KNLoadingButton
            type="submit"
            color="primary"
            variant="contained"
            loading={formState.isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            {t('form.send')}
          </KNLoadingButton>
        </>
      }
      onSubmit={handleSubmit(onSubmit)}
    >
      <KNDialogFormErrors errors={formState.errors?.root} />
      <Stack spacing={2}>
        <KNFormText
          name="body"
          label={t('trip_dashboard.sms_driver.message')}
          control={control}
          rows={2}
          rules={{
            required: t('form.validation.required'),
          }}
        />
      </Stack>
    </KNDialog>
  )
}

export default SmsDriverDialog
