// @mui imports
import DownloadIcon from '@mui/icons-material/Download'
import UploadIcon from '@mui/icons-material/Upload'
import AirlineStopsIcon from '@mui/icons-material/AirlineStops'
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart'

// Functional
import { notificationCenterTranslations } from 'screens/NotificationCenter/NotificationCenter.data'
import { monitoringRuleTranslations } from 'screens/MonitoringRuleCreateOrEdit/MonitoringRuleCreateOrEdit.data'
import { getSelectedCompany } from 'context/authentication/User.helpers'

// Types
import { MonitoringRuleSettingsOption, MonitoringRuleEventTypeOption } from './MonitoringRuleForm.types'

export const getSettings = (type?: string): MonitoringRuleSettingsOption[] => {
  const { status } = notificationCenterTranslations()
  switch (type) {
    case 'shipment_status_monitoring_rule_trigger':
      return [
        { label: status.AWAITING_PICKUP, value: 'AWAITING_PICKUP' },
        { label: status.IN_PROGRESS, value: 'IN_PROGRESS' },
        { label: status.DELIVERED, value: 'DELIVERED' },
        { label: status.CANCELLED, value: 'CANCELLED' },
      ]
    default:
      return []
  }
}

export const getEventTypes = (): MonitoringRuleEventTypeOption[] => {
  const { eventTypes } = monitoringRuleTranslations()
  const eventTypesOptions = [
    {
      label: eventTypes.departures,
      value: 'pick_up_by_status_monitoring_rule_trigger',
      icon: <UploadIcon />,
    },
    {
      label: eventTypes.arrivals,
      value: 'delivery_by_status_monitoring_rule_trigger',
      icon: <DownloadIcon />,
    },
    {
      label: eventTypes.milestoneUpdates,
      value: 'shipment_status_monitoring_rule_trigger',
      icon: <AirlineStopsIcon />,
    },
  ]

  const selectedCompany = getSelectedCompany()
  const tempModuleActive = selectedCompany?.modules?.temperature
  const tempThresholdEventType = {
    label: eventTypes.pairingHealthOnError,
    value: 'pairing_health_on_error_monitoring_rule_trigger',
    icon: <MonitorHeartIcon />,
  }

  return tempModuleActive ? [...eventTypesOptions, tempThresholdEventType] : eventTypesOptions
}
