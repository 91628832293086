import { matchPath } from 'react-router-dom'

interface MenuRoute {
  path: string
  exact?: boolean
  strict?: boolean
  name: string
  menu: string
  withBreadcrumb?: boolean
  withSearch?: boolean
  withNotifications?: boolean
}

// NOTE: keep up-to-date with available routes
const routes: MenuRoute[] = [
  {
    path: '/login',
    exact: true,
    name: 'login',
    menu: 'login',
  },
  {
    path: '/profile',
    exact: true,
    name: 'profile',
    menu: 'profile',
  },
  {
    path: '/trips',
    exact: true,
    name: 'trips_list',
    menu: 'trips',
  },
  {
    path: '/trips/:tripCid',
    exact: true,
    name: 'trip_details',
    menu: 'trips',
  },
  {
    path: '/drivers',
    exact: true,
    name: 'drivers_list',
    menu: 'drivers',
  },
  {
    path: '/vehicles',
    exact: true,
    name: 'vehicles_list',
    menu: 'vehicles',
  },
  {
    path: '/wl/:jwt',
    exact: true,
    name: 'weblink',
    menu: 'weblink',
  },
  {
    path: '/visibility-dashboard',
    exact: true,
    name: 'visibility_dashboard',
    menu: 'visibility',
    withBreadcrumb: true,
    withSearch: true,
    withNotifications: true,
  },
  {
    path: '/shipment-details/:entitytype/:shipmentid/:shipmentname/:submodule?',
    exact: true,
    name: 'shipment_details',
    menu: 'visibility',
    withBreadcrumb: true,
    withSearch: true,
    withNotifications: true,
  },
  {
    path: '/share_shipment/:entitytype/:shipmentid/:shipmentname/:token',
    exact: true,
    name: 'share_shipment',
    menu: 'visibility',
  },
  {
    path: '/shipment-report',
    exact: true,
    name: 'shipment_report',
    menu: 'shipmentReport',
    withBreadcrumb: true,
    withSearch: true,
    withNotifications: true,
  },
  {
    path: '/pairing',
    exact: true,
    name: 'pairing',
    menu: 'pairing',
  },
  {
    path: '/scanner',
    exact: true,
    name: 'scanner',
    menu: 'scanner',
  },
  {
    path: '/scanning_device',
    exact: true,
    name: 'scanner',
    menu: 'scanner',
  },
  {
    path: '/pair-devices',
    exact: true,
    name: 'pair-devices',
    menu: 'pair-devices',
  },
  {
    path: '/users',
    exact: true,
    name: 'users_list',
    menu: 'users',
  },
  {
    path: '/companies',
    exact: true,
    name: 'companies_list',
    menu: 'companies',
  },
  {
    path: '/carriers',
    exact: true,
    name: 'carriers_list',
    menu: 'carriers',
  },
  {
    path: '/help&info',
    exact: true,
    name: 'release_notes',
    menu: 'releasenotes',
  },
  {
    path: '/playground',
    exact: true,
    name: 'playground',
    menu: 'playground',
  },
  {
    path: '/notification-center',
    exact: true,
    name: 'notificationcenter',
    menu: 'notificationcenter',
  },
  {
    path: '/notification-center/:action/:ruleCid?',
    exact: true,
    name: 'notificationcenter',
    menu: 'notificationcenter',
  },
]

export const getActiveRoute = (location: string): MenuRoute | undefined =>
  routes.find(
    (route) =>
      matchPath(location, {
        path: route.path,
        exact: route.exact ?? false,
        strict: route.strict ?? false,
      }) !== null
  )

export const getRouteName = (location: string): string => {
  const activeRoute = getActiveRoute(location)
  return activeRoute?.name ?? 'unknown'
}

export const getActiveMenu = (location: string): string | null => {
  const activeRoute = getActiveRoute(location)
  return activeRoute?.menu ?? null
}

export const getBreadcrumbActive = (location: string): boolean => {
  const activeRoute = getActiveRoute(location)
  return !!activeRoute?.withBreadcrumb
}

export const getSearchActive = (location: string): boolean => {
  const activeRoute = getActiveRoute(location)
  return !!activeRoute?.withSearch
}

export const getNotificationsActive = (location: string): boolean => {
  const activeRoute = getActiveRoute(location)
  return !!activeRoute?.withNotifications
}

export const getHelpActive = (location: string): boolean => {
  const activeRoute = getActiveRoute(location)
  return activeRoute?.name === 'visibility_dashboard'
}
