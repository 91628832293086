import { useEffect, ReactElement, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'

// @mui imports
import Stack from '@mui/material/Stack'
import CreateIcon from '@mui/icons-material/Create'
import { Theme } from '@mui/material/styles/createTheme'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import useMediaQuery from '@mui/material/useMediaQuery'
import InputLabel from '@mui/material/InputLabel'
import DialogContentText from '@mui/material/DialogContentText'
import Alert from '@mui/material/Alert'
import DeleteIcon from '@mui/icons-material/Delete'

// KN imports
import KNDataTable from 'components/KN_Molecules/KNDataTable/KNDataTable'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import KNForm from 'components/KN_Molecules/KNForm/KNForm'
import KNFormText from 'components/KN_Molecules/KNForm/KNFormText'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNFormChipGroup from 'components/KN_Molecules/KNForm/KNFormChipGroup'
import KNFormIconButtonGroup from 'components/KN_Molecules/KNForm/KNFormIconButtonGroup'
import MonitoringRuleFormTextField from './MonitoringRuleFormTextField'
import theme from 'assets/theme'

// Functional
import { getErrorMessage } from 'global/helpers/errorHandler'
import { processDefaultValues } from 'global/helpers/form'
import {
  getSingleShipmentMonitoringRule,
  createMonitoringRule,
  editMonitoringRule,
} from 'screens/MonitoringRuleCreateOrEdit/MonitoringRuleCreateOrEdit.service'
import { getEventTypes, getSettings } from './MonitoringRuleForm.helpers'
import { analyticsEvent } from 'global/helpers/analytics'
import { sleep } from 'global/helpers/sleep'
import { valueToZonedDatetimeWithoutOffset } from 'global/helpers/dataTransformation'

// Types
import {
  CreateOrEditMonitoringRuleFormValues,
  CreateOrEditMonitoringRuleProps,
  MonitoringRuleSettingsOption,
  shipmentConditions,
} from './MonitoringRuleForm.types'
import { KNDataTableColumn } from 'components/KN_Molecules/KNDataTable/types'

// Data
import { monitoringRuleTranslations } from 'screens/MonitoringRuleCreateOrEdit/MonitoringRuleCreateOrEdit.data'
import { notificationCenterTranslations } from 'screens/NotificationCenter/NotificationCenter.data'
import { deleteMonitoringRule } from 'modules/MonitoringRulesTable/MonitoringRulesTable.service'

const MonitoringRuleForm = ({ payload, modalMode, forceRefresh }: CreateOrEditMonitoringRuleProps): ReactElement => {
  const history = useHistory()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [settings, setSettings] = useState<MonitoringRuleSettingsOption[]>([])
  const { handleSubmit, reset, control, formState, getValues, resetField, setError, clearErrors, watch } =
    useForm<CreateOrEditMonitoringRuleFormValues>()
  const [monitoringRules, setMonitoringRules] = useState<any>()

  // Translated data //
  const { formTranslations } = monitoringRuleTranslations()
  const { type: typeTranslations, translation: notificationTranslations } = notificationCenterTranslations()

  useEffect(() => {
    reset(
      processDefaultValues({
        name: payload?.name ?? '',
        trigger: payload?.monitoringRuleTrigger ?? {},
        condition: payload?.shipmentConditions ?? {},
        channels: ['MAIL', 'INAPP'],
      })
    )
  }, [])

  const onSubmit: SubmitHandler<CreateOrEditMonitoringRuleFormValues> = async (
    data: CreateOrEditMonitoringRuleFormValues
  ) => {
    if (!modalMode) {
      let condition: shipmentConditions = {}

      const pickupNotEmpty =
        data.condition.pickUp && Object.values(data.condition.pickUp).some((x) => x !== undefined && x !== '')
      const deliveryNotEmpty =
        data.condition.delivery && Object.values(data.condition.delivery).some((x) => x !== undefined && x !== '')

      if (pickupNotEmpty) condition = { pickUp: data.condition.pickUp }
      if (deliveryNotEmpty) condition = { ...condition, delivery: data.condition.delivery }

      data.condition = condition
    }
    try {
      if (payload) {
        await editMonitoringRule(payload.cid, data)
      } else {
        const postData = {
          name: !modalMode ? data.name : undefined,
          trigger: {
            type: data.trigger.type,
            status: data.trigger.status,
          },
          condition: !modalMode
            ? {
                addresses: !modalMode
                  ? {
                      pickUp: data.condition.pickUp,
                      delivery: data.condition.delivery,
                    }
                  : undefined,
              }
            : {
                sendingApplication: modalMode.sendingApplication,
                trackingNumber: modalMode.trackingNumber,
                cid: modalMode.cid,
              },
        }

        // Remove the properties if it is undefined
        if (postData.name === undefined) {
          delete postData.name
        }
        if (postData.condition.addresses === undefined) {
          delete postData.condition.addresses
        }
        await createMonitoringRule(postData, !modalMode ? 'rule' : 'singleShipment')
        await sleep(1000)
        if (forceRefresh) {
          history.go(0)
        } else {
          history.push('/notification-center')
        }
      }
      analyticsEvent(payload ? 'polestar_cv_monitoring_rule_edited' : 'polestar_cv_monitoring_rule_created')
    } catch (error) {
      setError('root.server', {
        message: getErrorMessage(error),
      })
    }
  }

  const watchTrigger = watch('trigger.type')

  useEffect(() => {
    resetField('trigger.status')
    setSettings(getSettings(getValues('trigger.type' ?? '')))
  }, [watchTrigger])

  useEffect(() => {
    if (modalMode?.cid) {
      const getMonitoringRules = async () => {
        try {
          const response = await getSingleShipmentMonitoringRule(modalMode.cid)
          setMonitoringRules(response)
        } catch (error) {
          console.log(error)
        }
      }

      void getMonitoringRules()
    }
  }, [modalMode])

  const monitoringRulesColumns: KNDataTableColumn<any>[] = [
    {
      name: 'monitoringRuleTrigger.type',
      label: notificationTranslations.type,
      getValue: (row): ReactElement => {
        return <>{typeTranslations[row.monitoringRuleTrigger?.type]}</>
      },
    },
    {
      name: 'createdAt',
      label: notificationTranslations.created,
      getValue: (row): ReactElement => {
        return <>{valueToZonedDatetimeWithoutOffset(row.createdAt)}</>
      },
    },
  ]

  const monitoringRulesActions = useMemo(
    () => [
      {
        name: 'remove',
        label: notificationTranslations.delete,
        icon: <DeleteIcon />,
        onClick: (row: { cid: string }): void => {
          void deleteMonitoringRule(row.cid)
        },
      },
    ],
    []
  )

  return (
    <KNForm onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {modalMode && monitoringRules && monitoringRules.length > 0 && (
          <Box mt={1}>
            <KNTypography variant="displayXS_SB" color="dark.main">
              {notificationTranslations.screenName}
            </KNTypography>
          </Box>
        )}
        {modalMode && monitoringRules && monitoringRules.length > 0 && (
          <Box mt={1}>
            <KNDataTable
              columns={monitoringRulesColumns}
              actions={monitoringRulesActions}
              data={monitoringRules}
              sx={{
                marginX: -2,
              }}
            />
          </Box>
        )}
        {!modalMode && (
          <Box mt={1}>
            <InputLabel>
              <KNTypography variant="textMD" color="black.main">
                {formTranslations.ruleNameLabel}
              </KNTypography>
            </InputLabel>
            <KNFormText
              name="name"
              sx={{
                maxWidth: 'sm',
              }}
              rules={{
                required: formTranslations.required,
              }}
              control={control}
            />
          </Box>
        )}
        {modalMode && (
          <Box mt={1}>
            <KNTypography variant="displayXS_SB" color="primary.main">
              {notificationTranslations.addNew}
            </KNTypography>
          </Box>
        )}
        <Box>
          <KNTypography color="black.main" variant="textLG_SB">
            {formTranslations.eventTypeLabel}
          </KNTypography>
          <KNFormIconButtonGroup
            sx={{ mt: 2 }}
            name="trigger.type"
            direction="row"
            control={control}
            options={getEventTypes()}
            rules={{
              required: formTranslations.required,
            }}
          />
        </Box>
        {settings.length > 0 && (
          <Box>
            <KNTypography color="black.main" variant="textLG_SB">
              {formTranslations.settingsLabel}
            </KNTypography>
            <KNFormChipGroup
              sx={{ marginTop: 2 }}
              name="trigger.status"
              direction="row"
              control={control}
              options={settings}
              rules={{
                required: formTranslations.required,
              }}
            />
          </Box>
        )}
        {!modalMode && (
          <Stack>
            <KNTypography color="black.main" variant="textLG_SB">
              {formTranslations.locationLabel}
            </KNTypography>
            <KNTypography color="grey.500" variant="textMD">
              {formTranslations.locationCaption}
            </KNTypography>
            <Box
              sx={{
                backgroundColor: ({ palette: { light } }: Theme): string => light.light,
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                padding: 2,
                mt: 2,
              }}
            >
              <Box
                sx={{
                  width: isMobile ? '100%' : '50%',
                  mr: isMobile ? 0 : 2,
                }}
              >
                <Stack spacing={2}>
                  <KNTypography color="black.main" variant="textLG_SB">
                    {formTranslations.pickup}
                  </KNTypography>
                  <MonitoringRuleFormTextField
                    label={formTranslations.pickupNameLabel}
                    placeholder={formTranslations.pickupNamePlaceholder}
                    name="condition.pickUp.name"
                    control={control}
                  />
                  <MonitoringRuleFormTextField
                    label={formTranslations.pickupCountryLabel}
                    placeholder={formTranslations.pickupCountryPlaceholder}
                    name="condition.pickUp.country"
                    control={control}
                  />
                  <MonitoringRuleFormTextField
                    label={formTranslations.pickupPostalCodeLabel}
                    placeholder={formTranslations.pickupPostalCodePlaceholder}
                    name="condition.pickUp.postalCode"
                    control={control}
                  />
                  <MonitoringRuleFormTextField
                    label={formTranslations.pickupCityLabel}
                    placeholder={formTranslations.pickupCityPlaceholder}
                    name="condition.pickUp.city"
                    control={control}
                  />
                  <MonitoringRuleFormTextField
                    label={formTranslations.pickupAddressLabel}
                    placeholder={formTranslations.pickupAddressPlaceholder}
                    name="condition.pickUp.address"
                    control={control}
                  />
                </Stack>
              </Box>
              <Divider orientation="vertical" flexItem variant="middle" />
              <Box
                sx={{
                  width: isMobile ? '100%' : '50%',
                  ml: isMobile ? 0 : 2,
                }}
              >
                <Stack spacing={2}>
                  <KNTypography color="black.main" variant="textLG_SB">
                    {formTranslations.delivery}
                  </KNTypography>
                  <MonitoringRuleFormTextField
                    label={formTranslations.deliveryNameLabel}
                    placeholder={formTranslations.deliveryNamePlaceholder}
                    name="condition.delivery.name"
                    control={control}
                  />
                  <MonitoringRuleFormTextField
                    label={formTranslations.deliveryCountryLabel}
                    placeholder={formTranslations.deliveryCountryPlaceholder}
                    name="condition.delivery.country"
                    control={control}
                  />
                  <MonitoringRuleFormTextField
                    label={formTranslations.deliveryPostalCodeLabel}
                    placeholder={formTranslations.deliveryPostalCodePlaceholder}
                    name="condition.delivery.postalCode"
                    control={control}
                  />
                  <MonitoringRuleFormTextField
                    label={formTranslations.deliveryCityLabel}
                    placeholder={formTranslations.deliveryCityPlaceholder}
                    name="condition.delivery.city"
                    control={control}
                  />
                  <MonitoringRuleFormTextField
                    label={formTranslations.deliveryAddressLabel}
                    placeholder={formTranslations.deliveryAddressPlaceholder}
                    name="condition.delivery.address"
                    control={control}
                  />
                </Stack>
              </Box>
            </Box>
          </Stack>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <KNLoadingButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={formState.isSubmitted && Object.keys(formState.errors).length > 0 && !formState.errors.root}
            startIcon={<CreateIcon />}
            loading={formState.isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            {formTranslations.saveButtonText}
          </KNLoadingButton>
        </Box>
        {formState.errors?.root?.server && (
          <DialogContentText component="div" mb={3}>
            <Alert severity="error">{formState.errors.root.server.message}</Alert>
          </DialogContentText>
        )}
      </Stack>
    </KNForm>
  )
}

export default MonitoringRuleForm
