import { useEffect, useState, ReactElement, useContext } from 'react'
import i18n from 'i18n'

// @mui imports //
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Icon from '@mui/material/Icon'
import Stack from '@mui/material/Stack'
import { Theme } from '@mui/material/styles/createTheme'
import useMediaQuery from '@mui/material/useMediaQuery'

// KN Components //
import theme from 'assets/theme'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNChip from 'components/KN_Components/Base/KNChip/KNChip'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Context //
import { useInsightDetailsContext } from 'context/detailsNext/InsightDetails'
import { UserContext } from 'context/authentication/UserContext'

// Functional //
import { getEventTimelineData } from './EventTimeline.service'
import { getErrorMessage } from 'global/helpers/errorHandler'
import { mapEvents } from './EventTimeline.helpers'

// Data //
import { eventTimelineTranslations } from './EventTimeline.data'

// Types //
import EventTimelineProps, { EventProps } from './EventTimeline.types'

const EventTimeline = ({ token, pinCode }: EventTimelineProps): ReactElement | null => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { insightDetailsState, dataLoading } = useInsightDetailsContext()
  const { userTimezone } = useContext(UserContext)

  // Props //
  const { shipmentId, data } = insightDetailsState.insightDetailsContext
  const status = data?.status

  // Module state//
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [eventsCollapsed, setEventsCollapsed] = useState(false)
  const [eventsData, setEventsData] = useState<EventProps[]>()

  // Translated Data //
  const { translation } = eventTimelineTranslations()

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true)
      try {
        const events = await getEventTimelineData(shipmentId ?? '', token, pinCode)
        setEventsData(mapEvents(events, userTimezone))
      } catch (error) {
        setError(getErrorMessage(error))
      }
      setLoading(false)
    }
    if (shipmentId && !dataLoading) {
      void fetchData()
    }
  }, [])

  const getEvent = (event: EventProps, index: number): ReactElement => {
    const getDivider = (): ReactElement | undefined => {
      const divider = (
        <Box
          sx={{
            backgroundColor: ({ palette: { grey } }: Theme): string => `${grey[300]} !important`,
            position: 'absolute',
            width: ({ functions: { pxToRem } }: Theme): number => pxToRem(1),
            height: '120%',
            left: ({ functions: { pxToRem } }: Theme): number => pxToRem(7),
            top: ({ functions: { pxToRem } }: Theme): number => pxToRem(10),
          }}
        />
      )
      if (eventsData && eventsData.length <= 3) {
        if (index + 1 !== eventsData?.length) {
          return divider
        }
      } else if (eventsData && eventsData.length > 3) {
        if (!eventsCollapsed && index + 1 !== 3) {
          return divider
        }
        if (eventsCollapsed && index + 1 !== eventsData?.length) {
          return divider
        }
      }
    }
    return (
      <Box sx={{ position: 'relative' }} key={index}>
        <Box data-test="event-timeline-status" sx={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            sx={{
              mr: 1,
              fontSize: ({ functions: { pxToRem } }: Theme): number => pxToRem(16),
              color: ({ palette: { grey } }: Theme): string => grey[300],
            }}
          >
            circle
          </Icon>
          <KNChip label={i18n.t(`${event.statusCode}`)} variant="gradient" color="secondary" size="small" />
        </Box>
        {getDivider()}
        <Stack pl={4} mt={1} mb={3} spacing={1}>
          <KNTypography data-test="event-timeline-date" variant="textLG" color="dark.main">
            {event.dateTime}
          </KNTypography>
          <KNTypography data-test="event-timeline-place" variant="textLG" color="primary.light">
            {event.location?.name}
          </KNTypography>
        </Stack>
      </Box>
    )
  }

  if (!status || !eventsData) return null
  return (
    <>
      <Box>
        {status > '' && (
          <Box data-test="shipment-status">
            <KNTypography variant="displayXS_SB" sx={{ display: 'block' }}>
              {translation.shipment_status}
            </KNTypography>
            <Box data-test="shipment-aggregated-status" mt={1}>
              <KNTypography variant="displayXXS" color="dark.main">
                {translation.aggregated_status}
              </KNTypography>
              <KNTypography variant="displayXXS_SB" color="dark.main">
                : {i18n.t(`shared.aggregatedStatus.${status}`)}
              </KNTypography>
            </Box>
          </Box>
        )}
        <Box mt={1.5} sx={{ maxHeight: isMobile ? '600px' : 'auto', overflow: isMobile ? 'scroll' : 'hidden' }}>
          {eventsData?.slice(0, 3).map((event, i) => getEvent(event, i))}
          <Collapse in={eventsCollapsed} mountOnEnter>
            <>{eventsData?.slice(3, eventsData.length).map((event, i) => getEvent(event, 3 + i))}</>
          </Collapse>
          {!eventsCollapsed && eventsData && eventsData?.length > 3 && (
            <KNButton
              variant="text"
              onClick={(): void => setEventsCollapsed(!eventsCollapsed)}
              dataAttribute="view-all-events"
            >
              {translation.viewAllEventsButton} <ExpandMoreIcon sx={{ ml: 1 }} />
            </KNButton>
          )}
          {eventsCollapsed && eventsData && eventsData?.length > 3 && (
            <KNButton
              variant="text"
              onClick={(): void => setEventsCollapsed(!eventsCollapsed)}
              dataAttribute="collapse-all-events"
            >
              {translation.collapseEventsButton} <ExpandLessIcon sx={{ ml: 1 }} />
            </KNButton>
          )}
        </Box>
      </Box>
    </>
  )
}

export default EventTimeline
