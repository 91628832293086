import { useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'

// @mui imports
import Stack from '@mui/material/Stack'
import DialogContentText from '@mui/material/DialogContentText'
import Alert from '@mui/material/Alert'
import WarningIcon from '@mui/icons-material/Warning'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

// KN imports
import { analyticsEvent } from 'global/helpers/analytics'
import { getRouteName } from 'global/helpers/activeRoute'
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNCaption from 'components/KN_Molecules/KNCaption/KNCaption'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import { processServerErrorMessages, processDefaultValues } from 'global/helpers/form'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import StatusUpdateFields from './StatusUpdateFields'
import DocumentsUploadFields from './DocumentsUploadFields'
import { getStatusUpdateLabel, getDefaultStatus, rewriteTimezone } from './StatusUpdateDialog'
import { updateStopStatusWithAttachments } from './StatusManager.service'
import { getUpdatedStopForUpdateStopStatusWithAttachments } from './StatusManager.helpers'
import { LegData, StopData } from 'screens/TripDetails/TripDetails.types'
import { TripData } from 'screens/TripDashboard/TripDashboard.types'

interface StatusUpdateWithAttachmentsDialogPayload {
  trip: TripData
  leg: LegData
  stop: StopData
  weblinkToken?: string
  useDeviceTime: boolean
}

interface StatusUpdateWithAttachmentsDialogProps {
  payload: StatusUpdateWithAttachmentsDialogPayload
  open: boolean
  onAction: (updatedStops: StopData[]) => void
  onClose: () => void
}

export interface StatusUpdateWithAttachmentsFormValues {
  wayPointType: 'PUP' | 'CUS' | 'DEL'
  statusId: string
  statusCode: string
  reasonCode?: string
  createdAt?: string
  createdAtDate?: Date | null
  createdAtTime?: Date | null
  shipmentNumber: string
  comment?: string
  signee?: string
  paperless?: boolean
  signature?: string
  popFiles?: string[]
  podFiles?: string[]
  photoFiles?: string[]
  palletFile?: string
}

const StatusUpdateWithAttachmentsDialog = ({
  payload,
  open,
  onAction,
  onClose,
}: StatusUpdateWithAttachmentsDialogProps): ReactElement => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const location = useLocation()
  const form = useForm<StatusUpdateWithAttachmentsFormValues>({
    criteriaMode: 'all',
  })
  const { handleSubmit, reset, formState, setError } = form

  const isPaperlessAvailable = (): boolean => {
    return isMobile && Boolean(payload.weblinkToken) && payload.leg.paperlessAvailable && !payload.stop.isProofAttached
  }

  useEffect(() => {
    if (open) {
      reset(
        processDefaultValues({
          wayPointType: payload.stop.type,
          statusId: getDefaultStatus(payload.stop.type),
          createdAtDate: payload.stop.earlyDateTime ? new Date(payload.stop.earlyDateTime) : new Date(),
          createdAtTime: null,
          shipmentNumber: payload.leg.shipmentNumber,
          paperless: isPaperlessAvailable(),
        })
      )
    }
  }, [open])

  const onSubmit: SubmitHandler<StatusUpdateWithAttachmentsFormValues> = async (
    data: StatusUpdateWithAttachmentsFormValues
  ) => {
    if (!payload.useDeviceTime) {
      // NOTE: rewrites input timezone to stop's timezone
      if (data.createdAtDate && data.createdAtTime) {
        data.createdAt = rewriteTimezone(data.createdAtDate, data.createdAtTime, payload.stop.earlyDateTime)
      }
    }
    try {
      await updateStopStatusWithAttachments(payload.trip.entityId, payload.stop.wayPointCid, data, payload.weblinkToken)
      onAction([getUpdatedStopForUpdateStopStatusWithAttachments(payload.stop, data)])
      analyticsEvent('polestar_cs_status_with_attachments_updated', [
        getRouteName(location.pathname),
        data.paperless ? 'paperless' : 'regular',
      ])
    } catch (error) {
      setError('root', processServerErrorMessages(error))
    }
  }

  return (
    <FormProvider {...form}>
      <KNDialog
        open={open}
        onClose={onClose}
        closeLabel={t('general.cancel')}
        preventClosing={formState.isSubmitting}
        title={`${payload.leg.shipmentNumber} — ${getStatusUpdateLabel(payload.stop.type)}`}
        actions={
          <>
            <KNLoadingButton
              data-test="update-status-button"
              type="submit"
              color="primary"
              variant="contained"
              loading={formState.isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              {t('trip_details.update_status.update_status')}
            </KNLoadingButton>
          </>
        }
        onSubmit={handleSubmit(onSubmit)}
      >
        <KNDialogFormErrors errors={formState.errors?.root} />
        <DialogContentText component="div" mb={2}>
          <KNCaption color="warning" icon={<WarningIcon />}>
            {t('trip_details.update_status.warning')}
          </KNCaption>
        </DialogContentText>
        <Stack spacing={2}>
          <StatusUpdateFields
            payload={{
              stop: payload.stop,
              leg: payload.leg,
              weblinkToken: payload.weblinkToken,
            }}
          />
          <DocumentsUploadFields
            payload={{
              stop: payload.stop,
              weblinkToken: payload.weblinkToken,
            }}
          />
        </Stack>
      </KNDialog>
    </FormProvider>
  )
}

export default StatusUpdateWithAttachmentsDialog
