import i18n from 'i18n'

export const playgroundTranslations = (): Record<any, any> => {
  const playground = 'modules.cv.playground'
  const userRoles = 'user_manager.roles'
  const visibilityLevels = 'company_manager.visibility_types'

  return {
    translation: {
      createShipmentFormName: i18n.t(`${playground}.create_shipment_form_name`),
      createPredefinedScenarioFormName: i18n.t(`${playground}.create_predefined_scenario_form_name`),
      required: i18n.t('form.validation.required'),
      invalidEmail: i18n.t('form.validation.invalid_email'),
      pastDateRequired: i18n.t(`${playground}.validation.past_date_required`),
      dateAfterRequired: i18n.t(`${playground}.validation.date_after_required`),
      scenarioId: i18n.t(`${playground}.scenario_id`),
      userData: i18n.t(`${playground}.user_data`),
      userEmailLabel: i18n.t(`${playground}.user_email_label`),
      userRoleLabel: i18n.t(`${playground}.user_role_label`),
      companyData: i18n.t(`${playground}.company_data`),
      companyNameLabel: i18n.t(`${playground}.company_name_label`),
      companyVisibilityLevelLabel: i18n.t(`${playground}.company_visibility_level_label`),
      saveButtonText: i18n.t(`${playground}.save_button_text`),
      shipmentData: i18n.t(`${playground}.shipment_data`),
      shipmentStartDateLabel: i18n.t(`${playground}.shipment_start_date_label`),
      shipmentEndDateLabel: i18n.t(`${playground}.shipment_end_date_label`),
      shipmentStatusCodeLabel: i18n.t(`${playground}.shipment_status_code`),
      shipmentLastLocation: i18n.t(`${playground}.shipment_last_location`),
      customerAddress: i18n.t(`${playground}.customer_address`),
      addressNameLabel: i18n.t(`${playground}.address_name_label`),
      addressCityLabel: i18n.t(`${playground}.address_city_label`),
      addressCountryLabel: i18n.t(`${playground}.address_country_label`),
      addressPostalCodeLabel: i18n.t(`${playground}.address_postal_code_label`),
      addressAddressLabel: i18n.t(`${playground}.address_address_label`),
      pickUpAddress: i18n.t(`${playground}.pick_up_address`),
      locationLatitudeLabel: i18n.t(`${playground}.location_latitude_label`),
      locationLongitudeLabel: i18n.t(`${playground}.location_longitude_label`),
      deliveryAddress: i18n.t(`${playground}.delivery_address`),
      shipmentStatuses: i18n.t(`${playground}.shipment_statuses`),
      shipmentStatusName: i18n.t(`${playground}.shipment_status_name`),
      shipmentStatusCode: i18n.t(`${playground}.shipment_status_code`),
      shipmentStatusDateTime: i18n.t(`${playground}.shipment_status_date_time`),
      shipmentStatusCityLocation: i18n.t(`${playground}.shipment_status_city_location`),
      shipmentStatusActions: i18n.t(`${playground}.shipment_status_actions`),
      shipmentAddStatus: i18n.t(`${playground}.shipment_add_status`),
      shipmentAddStatusText1: i18n.t(`${playground}.shipment_add_status_text_1`),
      shipmentAddStatusText2: i18n.t(`${playground}.shipment_add_status_text_2`),
      shipmentAddStatusDateTime: i18n.t(`${playground}.shipment_add_status_date_time`),
      shipmentAddStatusCityLocation: i18n.t(`${playground}.shipment_add_status_city_location`),
      shipmentAddStatusSubmitButton: i18n.t(`${playground}.shipment_add_status_submit_button`),
      shipmentCancelButton: i18n.t(`${playground}.shipment_cancel_button`),
      shipmentGeocodeAddressText: i18n.t(`${playground}.shipment_geocode_address_text`),
      shipmentEditAddressButton: i18n.t(`${playground}.shipment_edit_address_button`),
      shipmentAddAddressButton: i18n.t(`${playground}.shipment_add_address_button`),
      shipmentAddAddressSubmitButton: i18n.t(`${playground}.shipment_add_address_submit_button`),
      pairing: i18n.t(`${playground}.pairing`),
      pairingDescriptionLabel: i18n.t(`${playground}.pairing_description_label`),
      pairingTemperatureThresholdLabel: i18n.t(`${playground}.pairing_temperature_threshold_label`),
      minLabel: i18n.t(`${playground}.min`),
      maxLabel: i18n.t(`${playground}.max`),
      removeStatus: i18n.t(`${playground}.remove_status`),
      addNewStatus: i18n.t(`${playground}.add_new_status`),
      telematics: i18n.t(`${playground}.telematics`),
      telematicsLicensePlate: i18n.t(`${playground}.telematics_license_plate`),
      document: i18n.t(`${playground}.document`),
      documentType: i18n.t(`${playground}.document_type`),
      documentCountryCode: i18n.t(`${playground}.document_country_code`),
      scenarioVehicleLevelVisibilityAdvanced: i18n.t(`${playground}.scenario.vehicle_level_visibility_advanced`),
      scenarioVehicleLevelVisibilityAdvancedDescription: i18n.t(
        `${playground}.scenario.vehicle_level_visibility_advanced_description`
      ),
      scenarioCarrierSolutionRegressionSuite: i18n.t(`${playground}.scenario.carrier_solution_regression_suite`),
      scenarioCarrierSolutionRegressionSuiteDescription: i18n.t(
        `${playground}.scenario.carrier_solution_regression_suite_description`
      ),
      documentTypePOD: i18n.t(`${playground}.document_types.pod`),
      checkboxAddPairing: i18n.t(`${playground}.checkbox.add_pairing`),
      checkboxAddPairingEta: i18n.t(`${playground}.checkbox.add_pairing_eta`),
      checkboxAddTelematics: i18n.t(`${playground}.checkbox.add_telematics`),
      checkboxAddDocument: i18n.t(`${playground}.checkbox.add_document`),
      checkboxUserAcknowledgement: i18n.t(`${playground}.checkbox.user_acknowledgement`),
      checkboxCreateUser: i18n.t(`${playground}.checkbox.create_user`),
      checkboxCompanyAcknowledgement: i18n.t(`${playground}.checkbox.company_acknowledgement`),
      checkboxCreateCompany: i18n.t(`${playground}.checkbox.create_company`),
      checkboxAddTemperatureThreshold: i18n.t(`${playground}.checkbox.add_temperature_threshold`),
      checkboxViolateTemperatureThreshold: i18n.t(`${playground}.checkbox.violate_temperature_threshold`),
    },

    roles: {
      viewer: i18n.t(`${userRoles}.Viewer`),
      editor: i18n.t(`${userRoles}.Editor`),
      admin: i18n.t(`${userRoles}.Admin`),
    },

    visibilityLevels: {
      basic: i18n.t(`${visibilityLevels}.Basic`),
      advanced: i18n.t(`${visibilityLevels}.Advanced`),
      max: i18n.t(`${visibilityLevels}.Max`),
    },

    logsTranslations: {
      OK: i18n.t(`${playground}.status.OK`),
      ERROR: i18n.t(`${playground}.status.ERROR`),
      IN_PROGRESS: i18n.t(`${playground}.status.IN_PROGRESS`),
      COMPANY: i18n.t(`${playground}.step_type.COMPANY`),
      USER: i18n.t(`${playground}.step_type.USER`),
      CREATE_SHIPMENT: i18n.t(`${playground}.step_type.CREATE_SHIPMENT`),
      CREATE_POD_DOCUMENT: i18n.t(`${playground}.step_type.CREATE_POD_DOCUMENT`),
      GENERATE_TELEMATICS_DATA: i18n.t(`${playground}.step_type.GENERATE_TELEMATICS_DATA`),
      GENERATE_CHORUS_DATA: i18n.t(`${playground}.step_type.GENERATE_CHORUS_DATA`),
      CREATE_PAIRING: i18n.t(`${playground}.step_type.CREATE_PAIRING`),
      CREATE_TRIP: i18n.t(`${playground}.step_type.CREATE_TRIP`),
    },
  }
}
