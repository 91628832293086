// Functional
import { isDemo, isProd } from 'global/helpers/environment'
import { hasRole, hasVisibilityLevel, isCompanyType } from 'global/helpers/authorization'

// KN imports
import {
  KNLoopIcon,
  KNCarriersIcon,
  KNCompaniesIcon,
  KNDriverIcon,
  KNHelpAndInfoIcon,
  KNPairingIcon,
  KNTripIcon,
  KNTruckIcon,
  KNUsersIcon,
  KNVisibilityIcon,
} from 'components/KN_Molecules/KNIcon/KNIcon'
import { KNNotificationsActiveIcon } from 'components/KN_Molecules/KNIcon/KNMaterialIcon'

// Types
import { CompanyType, VisibilityLevel } from 'context/authentication/Company.types'
import { Role } from 'context/authentication/Role.types'

// Feature flags
import { notificationCenterFeatureFlag } from 'FeatureFlags'

interface Section {
  sectionName: string
  sectionShortName?: string
  shortName?: string
  sectionOptions: SectionOption[]
  predicate?: () => boolean
}

interface SectionOption {
  name: string
  label: string
  link: string
  icon: JSX.Element
  predicate?: () => boolean
}

export const menuOptions = (): Section[] => {
  const noProd = !isProd()

  // Feature flags
  const notificationCenterFlag = Boolean(notificationCenterFeatureFlag())

  return [
    {
      sectionName: 'drawer_menu.carrier_solution',
      sectionShortName: 'drawer_menu.carrier_solution_short',
      predicate: () => isCompanyType(CompanyType.Carrier),
      sectionOptions: [
        {
          name: 'trips',
          label: 'drawer_menu.trips',
          link: '/trips',
          icon: <KNTripIcon />,
        },
        {
          name: 'drivers',
          label: 'drawer_menu.drivers',
          link: '/drivers',
          icon: <KNDriverIcon />,
        },
        {
          name: 'vehicles',
          label: 'drawer_menu.vehicles',
          link: '/vehicles',
          icon: <KNTruckIcon />,
        },
      ],
    },
    {
      sectionName: 'drawer_menu.customer_visibility',
      sectionShortName: 'drawer_menu.customer_visibility_short',
      predicate: () => isCompanyType(CompanyType.Customer),
      sectionOptions: [
        {
          name: 'visibility',
          label: 'drawer_menu.visibility_dashboard',
          link: '/visibility-dashboard',
          icon: <KNVisibilityIcon />,
          predicate: () => !hasRole(Role.Pairer),
        },
        {
          name: 'pairing',
          label: 'drawer_menu.pairing',
          link: '/pairing',
          icon: <KNPairingIcon />,
          predicate: () =>
            hasVisibilityLevel(VisibilityLevel.Max) &&
            (hasRole(Role.Editor) || hasRole(Role.Admin) || hasRole(Role.Pairer)),
        },
      ],
    },
    {
      sectionName: 'drawer_menu.admin',
      predicate: () => hasRole(Role.Admin) || isCompanyType(CompanyType.Operator),
      sectionOptions: [
        {
          name: 'users',
          label: 'drawer_menu.users',
          link: '/users',
          icon: <KNUsersIcon />,
          predicate: () => hasRole(Role.Admin) || isCompanyType(CompanyType.Operator),
        },
        {
          name: 'companies',
          label: 'drawer_menu.companies',
          link: '/companies',
          icon: <KNCompaniesIcon />,
          predicate: () => isCompanyType(CompanyType.Operator),
        },
        {
          name: 'carriers',
          label: 'drawer_menu.carriers',
          link: '/carriers',
          icon: <KNCarriersIcon />,
          predicate: () => isCompanyType(CompanyType.Operator),
        },
        {
          name: 'playground',
          label: 'drawer_menu.playground',
          link: '/playground',
          icon: <KNLoopIcon />,
          predicate: () => hasRole(Role.Admin) && isCompanyType(CompanyType.Operator) && noProd,
        },
      ],
    },
    {
      sectionName: 'drawer_menu.user',
      predicate: () => isCompanyType(CompanyType.Carrier),
      sectionOptions: [
        {
          name: 'releasenotes',
          label: 'drawer_menu.releasenotes',
          link: '/help&info',
          icon: <KNHelpAndInfoIcon />,
        },
      ],
    },
    {
      sectionName: 'drawer_menu.user_settings',
      predicate: () => isCompanyType(CompanyType.Customer) && notificationCenterFlag,
      sectionOptions: [
        {
          name: 'notificationcenter',
          label: 'drawer_menu.notification_center',
          link: '/notification-center',
          icon: <KNNotificationsActiveIcon />,
        },
      ],
    },
  ]
}

export const getMenuOptions = (): Section[] => {
  return menuOptions()
    .filter((section) => !section.predicate || section.predicate())
    .map((section) => ({
      sectionName: section.sectionName,
      sectionShortName: section.sectionShortName,
      sectionOptions: section.sectionOptions.filter((option) => !option.predicate || option.predicate()),
    }))
}
