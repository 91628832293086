import { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import SignaturePad from 'signature_pad'

// @mui imports
import { Theme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import ReplayIcon from '@mui/icons-material/Replay'
import WarningIcon from '@mui/icons-material/Warning'
import { useFormControl } from '@mui/material/FormControl'

// KN imports
import KNCaption from 'components/KN_Molecules/KNCaption/KNCaption'

export interface KNSignatureProps {
  /**
   * Callback that passes base64 encoded PNG of the canvas content
   */
  onChange?: (output: string | null) => void
}

const KNSignature: React.FC<KNSignatureProps> = ({ onChange }) => {
  const { t } = useTranslation()
  const formControlState = useFormControl()
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [signaturePad, setSignaturePad] = useState<SignaturePad>()
  const [printCanvas, setPrintCanvas] = useState<HTMLCanvasElement>()
  const [printSignaturePad, setPrintSignaturePad] = useState<SignaturePad>()
  const [overflow, setOverflow] = useState(false)
  const printWidth = 256
  const printHeight = 1024

  const resizeCanvas = (): void => {
    if (!canvasRef.current || !signaturePad) {
      return
    }
    const data = signaturePad.toData()
    const ratio = Math.max(window.devicePixelRatio ?? 1, 1)
    canvasRef.current.width = canvasRef.current.offsetWidth * ratio
    canvasRef.current.height = canvasRef.current.offsetHeight * ratio
    canvasRef.current.getContext('2d')?.scale(ratio, ratio)
    signaturePad.fromData(data)

    let maxWidth = 0
    data.map((segment) => {
      segment.points.map((point) => {
        if (point.x > maxWidth) {
          maxWidth = point.x
        }
      })
    })
    setOverflow(maxWidth > canvasRef.current.offsetWidth)

    if (printCanvas && printSignaturePad) {
      // NOTE: print canvas has fixed dimensions, so calculate scaling ratio based on
      // base display height (without taking device pixel ratio into account) and target print height
      const printRatio = printWidth / canvasRef.current.offsetHeight
      printCanvas.width = printHeight
      printCanvas.height = printWidth
      printCanvas.getContext('2d')?.scale(printRatio, printRatio)
      printSignaturePad.fromData(data)
    }
  }

  const clearCanvas = (): void => {
    if (!signaturePad) {
      return
    }
    signaturePad.clear()
    updateOutput()
    setOverflow(false)
  }

  const updateOutput = (): void => {
    if (!signaturePad || !printSignaturePad) {
      return
    }
    printSignaturePad.fromData(signaturePad.toData())
    onChange?.(printSignaturePad.isEmpty() ? null : printSignaturePad.toDataURL())
  }

  useEffect(() => {
    if (canvasRef.current) {
      setSignaturePad(
        new SignaturePad(canvasRef.current, {
          minWidth: 1,
          maxWidth: 3,
          throttle: 10,
        })
      )
      const canvas = document.createElement('canvas')
      setPrintCanvas(canvas)
      setPrintSignaturePad(new SignaturePad(canvas))
    }
  }, [canvasRef])

  useEffect(() => {
    if (!signaturePad) {
      return
    }

    signaturePad.addEventListener('endStroke', updateOutput)
    if (window.screen?.orientation) {
      window.screen.orientation.addEventListener('change', resizeCanvas)
    } else {
      window.addEventListener('orientationchange', resizeCanvas)
    }
    window.addEventListener('resize', resizeCanvas)
    resizeCanvas()

    return () => {
      window.removeEventListener('resize', resizeCanvas)
      if (window.screen?.orientation) {
        window.screen.orientation.removeEventListener('change', resizeCanvas)
      } else {
        window.removeEventListener('orientationchange', resizeCanvas)
      }
      signaturePad.removeEventListener('endStroke', updateOutput)
    }
  }, [signaturePad])

  return (
    <Box
      sx={{
        position: 'relative',
        backgroundColor: 'light.main',
        border: '1px solid',
        borderColor: formControlState?.error ? 'error.main' : 'light.main',
        borderRadius: ({ borders: { borderRadius } }: Theme) => borderRadius.lg,
      }}
    >
      <canvas
        ref={canvasRef}
        style={{
          display: 'block',
          width: '100%',
          height: '16rem',
        }}
      />
      {overflow && (
        <KNCaption
          color="warning"
          icon={<WarningIcon />}
          sx={{
            pointerEvents: 'none',
            position: 'absolute',
            bottom: '.75rem',
            left: '.75rem',
          }}
        >
          {t('trip_details.update_status.signature_overflow')}
        </KNCaption>
      )}
      <IconButton
        sx={{
          position: 'absolute',
          right: 0,
          bottom: 0,
        }}
        onClick={(): void => clearCanvas()}
      >
        <ReplayIcon />
      </IconButton>
    </Box>
  )
}

export default KNSignature
