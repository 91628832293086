import { useState, useEffect, useContext, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'

// @mui imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

// KN Components
import { analyticsEvent } from 'global/helpers/analytics'
import { VehicleListContext } from 'context/vehicles/VehicleListContext'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNAdvancedDropdown from 'components/KN_Molecules/KNAdvancedDropdown/KNAdvancedDropdown'

const VehicleFilters = (): ReactElement => {
  const { t } = useTranslation()
  const [vehicleListState, vehicleListDispatch] = useContext(VehicleListContext)
  const [payload, setPayload] = useState<object | null>(null)
  const [debouncedPayload] = useDebounce(payload, 100)

  useEffect(() => {
    if (debouncedPayload) {
      vehicleListDispatch({
        type: 'setFilters',
        payload: debouncedPayload,
      })
    }
    setPayload(null)
  }, [debouncedPayload])

  const handleResetClick = useCallback((): void => {
    vehicleListDispatch({ type: 'resetFilters' })
    analyticsEvent('polestar_cs_reset_vehicle_filters')
  }, [])

  return (
    <Box sx={{ position: 'relative' }}>
      <Grid container spacing={2} mb={4}>
        <Grid data-test="vehicles-filter-container" item xs={12} sm={4}>
          <Autocomplete
            multiple
            freeSolo
            options={[]}
            value={vehicleListState.filters.keywords ?? []}
            onChange={(event, newValue): void => {
              setPayload({
                keywords: [...newValue] as string[],
              })
              analyticsEvent('polestar_cs_vehicle_filters', ['keywords', newValue.join(', ')])
            }}
            renderTags={(tagValue, getTagProps): ReactElement[] =>
              tagValue.map((option, index) => (
                // eslint-disable-next-line react/jsx-key
                <Chip {...getTagProps({ index })} size="small" label={option} />
              ))
            }
            renderInput={(params): ReactElement => (
              <TextField {...params} label={t('vehicle_manager.filters.search')} />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3} data-test="vehicle-type-dropdown">
          <KNAdvancedDropdown
            label={t('vehicle_manager.filters.vehicle_type')}
            options={['TRUCK', 'TRAILER'].map((type) => ({
              value: type,
              label: t(`vehicle_manager.vehicle_types.${type}`),
            }))}
            value={vehicleListState.filters.vehicleType ?? []}
            onChange={(event): void => {
              const newValue = event.target.value as string[]
              setPayload({
                vehicleType: newValue,
              })
            }}
            multiple
          />
        </Grid>
      </Grid>
      {vehicleListState.filtersDirty && (
        <KNButton
          size="small"
          variant="text"
          color="secondary"
          data-test="reset-vehicle-filters-button"
          onClick={handleResetClick}
          sx={{
            position: 'absolute',
            left: 0,
            bottom: '-28px',
            paddingX: 0,
            paddingY: '4px',
            minHeight: 'auto',
          }}
        >
          {t('general.reset_filters')}
        </KNButton>
      )}
    </Box>
  )
}

export default VehicleFilters
