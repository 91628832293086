import React, { useState, useEffect, useRef, useCallback, useContext, ReactElement, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

// @mui imports
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Alert from '@mui/material/Alert'
import EditNoteIcon from '@mui/icons-material/EditNote'
import Collapse from '@mui/material/Collapse'

// KN imports
import { StopsViewContext } from 'context/trips/StopsViewContext'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import { KNDataCardAction } from 'components/KN_Molecules/KNDataCard/types'
import StatusUpdateDialog from 'screens/StatusManager/StatusUpdateDialog'
import LocationLink from 'screens/TripDashboard/LocationLink'
import LegHeader from './LegHeader'
import StopHeader from './StopHeader'
import SecondaryActions from './SecondaryActions'
import { StopData } from './TripDetails.types'
import { GroupedStopsCardCustomsStopProps } from './GroupedStopsCard.types'

const GroupedStopsCardCustomsStop = ({
  trip,
  leg,
  stop,
  weblinkToken,
  onChange,
  collapsed = false,
}: GroupedStopsCardCustomsStopProps): ReactElement => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const location = useLocation<{ wayPointCids: string[] }>()
  const [stopsViewState, stopsViewDispatch] = useContext(StopsViewContext)
  const [statusUpdateDialogOpen, setStatusUpdateDialogOpen] = useState(false)
  const stopErrors = stopsViewState.stopsErrors?.filter((error) => error.waypointCid === stop.wayPointCid)

  const handleStatusUpdateClick = useCallback((): void => setStatusUpdateDialogOpen(true), [])
  const handleStatusUpdateAction = useCallback((updatedStops: StopData[]): void => {
    setStatusUpdateDialogOpen(false)
    onChange?.(updatedStops)
  }, [])
  const handleStatusUpdateClose = useCallback((): void => {
    setStatusUpdateDialogOpen(false)
  }, [])

  const actions: KNDataCardAction[] = []
  if (stop.availableStatuses.length > 0) {
    actions.push({
      label: t('trip_details.card.actions.update_status'),
      icon: <EditNoteIcon />,
      handler: handleStatusUpdateClick,
    })
  }

  return (
    <>
      {stopErrors.length > 0 && (
        <Alert severity="error" sx={{ marginBottom: 1 }}>
          {stopErrors.map((error, index) => (
            <Fragment key={index}>
              {index > 0 && <br />}
              {error.message}
            </Fragment>
          ))}
        </Alert>
      )}
      <Stack
        spacing={2}
        direction={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'stretch', md: 'start' }}
      >
        <Box sx={{ width: '100%' }}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} md={6} sx={{ marginBottom: { xs: 1, md: 0 } }}>
              <StopHeader stop={stop} />
            </Grid>
            <Grid item xs={12} md={6}>
              <LegHeader trip={trip} leg={leg} />
            </Grid>
          </Grid>
        </Box>
        {actions.length > 0 && (
          <SecondaryActions
            actions={actions}
            sx={{ marginTop: { xs: '0.5rem !important', md: '0.125rem !important' }, minWidth: '76px' }}
          />
        )}
      </Stack>

      <StatusUpdateDialog
        payload={{
          trip,
          leg,
          stop,
          weblinkToken,
        }}
        open={statusUpdateDialogOpen}
        onAction={handleStatusUpdateAction}
        onClose={handleStatusUpdateClose}
      />
    </>
  )
}

export default GroupedStopsCardCustomsStop
