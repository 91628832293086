import { useState, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler } from 'react-hook-form'

// @mui imports
import DialogContentText from '@mui/material/DialogContentText'
import Alert from '@mui/material/Alert'

// KN imports
import { analyticsEvent } from 'global/helpers/analytics'
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import { processServerErrorMessages } from 'global/helpers/form'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import { VehicleData } from './VehicleManager.types'
import { deleteVehicle } from './VehicleManager.service'
import { getDataForDeleteVehicle } from './VehicleManager.helpers'

interface DeleteVehicleDialogPayload {
  vehicle: VehicleData
}

interface DeleteVehicleDialogProps {
  payload: DeleteVehicleDialogPayload
  open: boolean
  onAction: (updatedVehicle: VehicleData, action: string) => void
  onClose: () => void
}

const DeleteVehicleDialog = ({ payload, open, onAction, onClose }: DeleteVehicleDialogProps): ReactElement => {
  const { t } = useTranslation()
  const { handleSubmit, formState, setError } = useForm()

  const onSubmit: SubmitHandler<object> = async (): Promise<void> => {
    try {
      await deleteVehicle(payload.vehicle.id)
      onAction(getDataForDeleteVehicle(payload.vehicle), 'delete')
      analyticsEvent('polestar_cs_vehicle_deleted')
    } catch (error) {
      setError('root', processServerErrorMessages(error))
    }
  }

  return (
    <KNDialog
      open={open}
      onClose={onClose}
      closeLabel={t('general.cancel')}
      preventClosing={formState.isSubmitting}
      title={`${payload.vehicle.displayLicensePlate} — ${t('vehicle_manager.card.actions.delete')}`}
      actions={
        !payload.vehicle.telematicsReady && (
          <>
            <KNLoadingButton
              type="submit"
              color="error"
              variant="contained"
              loading={formState.isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              {t('vehicle_manager.delete_vehicle.delete')}
            </KNLoadingButton>
          </>
        )
      }
      onSubmit={handleSubmit(onSubmit)}
      maxWidth="xs"
    >
      <KNDialogFormErrors errors={formState.errors?.root} />
      <DialogContentText>
        {payload.vehicle.telematicsReady
          ? t('vehicle_manager.delete_vehicle.telematics_notice')
          : t('vehicle_manager.delete_vehicle.confirmation')}
      </DialogContentText>
    </KNDialog>
  )
}

export default DeleteVehicleDialog
