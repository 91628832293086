import { ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import FadeIn from 'react-fade-in'
import { useHistory } from 'react-router-dom'

// @mui imports
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'

// KN imports
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import MonitoringRulesTable from 'modules/MonitoringRulesTable/MonitoringRulesTable'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'

// Context
import { UserContext } from 'context/authentication/UserContext'

// Functional
import { notificationCenterTranslations } from './NotificationCenter.data'
import { getMonitoringRulesData } from './NotificationCenter.helpers'
import { getMonitoringRules } from 'modules/MonitoringRulesTable/MonitoringRulesTable.service'
import { analyticsEvent } from 'global/helpers/analytics'
import { sleep } from 'global/helpers/sleep'

// Types
import { MonitoringRuleRow } from 'modules/MonitoringRulesTable/MonitoringRulesTable.types'

const NotificationCenter = (): ReactElement => {
  const [monitoringRulesData, setMonitoringRulesData] = useState<MonitoringRuleRow[]>([])
  const [loading, setLoading] = useState(true)
  const { translation } = notificationCenterTranslations()
  const history = useHistory()
  const { userTimezone } = useContext(UserContext)

  const fetchData = async (): Promise<void> => {
    setLoading(true)
    const monitoringRules = await getMonitoringRules()
    setMonitoringRulesData(getMonitoringRulesData(monitoringRules, userTimezone))
    setLoading(false)
  }

  useEffect(() => {
    void fetchData()
  }, [])

  const handleOnChange = useCallback(async (): Promise<void> => {
    setLoading(true)
    await sleep(1000)
    await fetchData()
    setLoading(false)
  }, [])

  return (
    <Container maxWidth={false} data-test="notification-center-container">
      {loading ? (
        <FadeIn visible={loading}>
          <KNLoader />
        </FadeIn>
      ) : (
        <FadeIn visible={!loading}>
          <Paper elevation={8} sx={{ padding: 2 }}>
            <Stack
              spacing={1}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
              alignItems={{ xs: 'start', md: 'center' }}
              mb={2}
            >
              <KNTypography variant="displayMD_SB" color="primary.focus">
                {translation.screenName}
              </KNTypography>
              <KNButton
                onClick={(): void => {
                  history.push('/notification-center/new')
                  analyticsEvent('polestar_cv_create_monitoring_rule_button_clicked')
                }}
                data-test="add-new-monitoring-rule-button"
                variant="contained"
                color="secondary"
                size="small"
              >
                {translation.addNew}
              </KNButton>
            </Stack>
            <MonitoringRulesTable monitoringRules={monitoringRulesData} onChange={handleOnChange} />
          </Paper>
        </FadeIn>
      )}
    </Container>
  )
}

export default NotificationCenter
