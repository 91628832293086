import { FunctionComponent, PropsWithChildren, ReactNode } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import * as Sentry from '@sentry/react'

// Functional
import { getSelectedCompany, getStartPage } from 'context/authentication/User.helpers'
import { isSentryEnabled } from 'global/helpers/environment'

export interface GuardedRouteProps extends RouteProps {
  children: ReactNode
  guardType?: 'Max' | 'Carrier' | 'Basic' | 'Advanced'
  isMonitored?: boolean
  visibilityGuard?: 'Max' | 'Basic' | 'Advanced'
  companyTypeGuard?: 'Carrier' | 'Customer' | 'KNadmin'
  adminOnly?: boolean
  excludedRoles?: string[]
  noRedirect?: boolean
}

const GuardedRoute: FunctionComponent<PropsWithChildren<GuardedRouteProps>> = ({
  children,
  guardType,
  isMonitored = true,
  visibilityGuard,
  companyTypeGuard,
  adminOnly,
  excludedRoles,
  noRedirect,
  ...rest
}) => {
  const MonitoredRoute = isSentryEnabled() ? Sentry.withSentryRouting(Route) : Route
  const company = getSelectedCompany()
  const startPage = getStartPage()
  const isHome = location.pathname === '/'

  let guardActive = false
  if (visibilityGuard) {
    visibilityGuard === company?.properties?.visibilityLevel ? (guardActive = true) : (guardActive = false)
  }
  if (companyTypeGuard) {
    companyTypeGuard === company?.type ? (guardActive = true) : (guardActive = false)
  }
  if (adminOnly) {
    company?.role === 'Admin' ? (guardActive = true) : (guardActive = false)
  }

  if (excludedRoles) {
    company?.role && !excludedRoles.includes(company?.role) ? (guardActive = true) : (guardActive = false)
  }

  if (noRedirect) return <MonitoredRoute>{children}</MonitoredRoute>
  if (isHome)
    return (
      <MonitoredRoute exact path="/">
        <Redirect to={startPage} />
      </MonitoredRoute>
    )
  return (
    <MonitoredRoute
      {...rest}
      render={({ location }): ReactNode => {
        return guardActive ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }}
    />
  )
}

export default GuardedRoute
