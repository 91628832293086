import { parseISO, isEqual, isBefore } from 'date-fns'

import { fetchGet, fetchPost, weblinkHeaders } from 'global/helpers/fetchWrapper'
import {
  LegData,
  StopData,
  AttachmentData,
  VehiclePositionsData,
  StopLegPair,
} from 'screens/TripDetails/TripDetails.types'
import { BulkStatusUpdateFormValues } from './BulkStatusUpdateDialog'
import { StatusUpdateFormValues } from './StatusUpdateDialog'
import { StatusUpdateWithAttachmentsFormValues } from './StatusUpdateWithAttachmentsDialog'
import { DocumentsUploadFormValues } from './DocumentsUploadDialog'

export interface UploadFileResponse {
  id: string
  fileName: string
  checksum: string
  size: number
  timestamp: number
  isDocument: boolean
}

export const updateStopStatus = async (
  tripCid: string,
  wayPointCid: string,
  data: StatusUpdateFormValues,
  weblinkToken: string | null = null
): Promise<void> => {
  // NOTE: data without intermediate values
  const processedData = {
    wayPointType: data.wayPointType,
    statusCode: data.statusCode,
    reasonCode: data.reasonCode,
    createdAt: data.createdAt,
    shipmentNumber: data.shipmentNumber,
    comment: data.comment,
    signee: data.signee,
    signature: data.signature,
  }
  return await fetchPost(
    `trips/${tripCid}/waypoints/${wayPointCid}/status`,
    processedData,
    weblinkHeaders(weblinkToken)
  )
}

export const updateStopStatusWithAttachments = async (
  tripCid: string,
  wayPointCid: string,
  data: StatusUpdateWithAttachmentsFormValues,
  weblinkToken: string | null = null
): Promise<void> => {
  // NOTE: data without intermediate values
  const processedData = {
    wayPointType: data.wayPointType,
    statusCode: data.statusCode,
    reasonCode: data.reasonCode,
    createdAt: data.createdAt,
    shipmentNumber: data.shipmentNumber,
    comment: data.comment,
    signee: data.signee,
    signature: data.signature,
    attachments: processAttachments(data),
  }
  return await fetchPost(
    `trips/${tripCid}/waypoints/${wayPointCid}/status-with-attachments`,
    processedData,
    weblinkHeaders(weblinkToken)
  )
}

export const bulkUpdateStopStatus = async (
  tripCid: string,
  stopLegPairs: StopLegPair[],
  data: BulkStatusUpdateFormValues,
  weblinkToken: string | null = null
): Promise<void> => {
  // NOTE: data without intermediate values
  const processedData = {
    updates: stopLegPairs.map((stopLegPair) => {
      return {
        wayPointCid: stopLegPair.stop.wayPointCid,
        wayPointType: data.wayPointType,
        statusCode: data.statusCode,
        reasonCode: data.reasonCode,
        createdAt: data.createdAt,
        shipmentNumber: stopLegPair.leg.shipmentNumber,
        comment: data.comment,
        signee: data.signee,
        signature: data.signature,
        attachments: [], // see note below
      }
    }),
  }
  // NOTE: it uses status-with-attachments endpoint to properly process paperless,
  // but so far actual document upload isn't supported yet and isn't visible in the form
  return await fetchPost(
    `trips/${tripCid}/waypoints/status-with-attachments`,
    processedData,
    weblinkHeaders(weblinkToken)
  )
}

export const minFileSize = 1024 * 10
export const maxFileSize = 1024 * 1024 * 10

export const uploadFile = async (file: File, weblinkToken: string | null = null): Promise<UploadFileResponse> => {
  const formData = new FormData()
  formData.append('form', file)
  return await fetchPost('files', formData, weblinkHeaders(weblinkToken))
}

export const attachFiles = async (
  tripCid: string,
  wayPointCid: string,
  data: DocumentsUploadFormValues,
  weblinkToken: string | null = null
): Promise<void> => {
  const processedData = {
    attachments: processAttachments(data),
  }
  await fetchPost(`trips/${tripCid}/waypoints/${wayPointCid}/attachments`, processedData, weblinkHeaders(weblinkToken))
}

export const processAttachments = (data): AttachmentData[] => {
  const attachments: AttachmentData[] = []
  if (data.popFiles) {
    for (const popId of data.popFiles) {
      attachments.push({ fileId: popId, fileType: '507' }) // 507=POP
    }
  }
  if (data.podFiles) {
    for (const podId of data.podFiles) {
      attachments.push({ fileId: podId, fileType: '545' }) // 545=POD
    }
  }
  if (data.photoFiles) {
    for (const photoId of data.photoFiles) {
      attachments.push({ fileId: photoId, fileType: '991' }) // 991=IMG
    }
  }
  if (data.palletFile) {
    attachments.push({ fileId: data.palletFile, fileType: '535' }) // 535=pallet slip
  }
  return attachments
}
