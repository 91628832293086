import i18n from 'i18n'
import { format, utcToZonedTime } from 'date-fns-tz'

// Types //
import { EventProps } from './EventTimeline.types'

export const mapEvents = (events: EventProps[], userTimezone: string): EventProps[] => {
  const mappedEvents = events.map((event) => {
    const { address, city, country } = event.location ?? {}
    const addressString = String([address, city?.toUpperCase(), country].join(' '))
    return {
      cid: event.cid,
      statusCode: i18n.t(`status.${event.statusCode}`),
      dateTime: format(utcToZonedTime(new Date(event.dateTime), userTimezone), 'dd/MM/yyyy HH:mm'),
      location: { name: addressString },
    }
  })
  return mappedEvents
}
