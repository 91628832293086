import { Driver, PopoverDOM, driver } from 'driver.js'
import 'driver.js/dist/driver.css'

import i18n from 'i18n'

// Functional
import { postUserPreferences } from 'screens/UserManager/UserManager.service'

// Types
import { WelcomeGuideStatus } from 'screens/UserManager/UserManager.types'

export const getWelcomeGuide = (updateUserPreferences: boolean): Driver => {
  const welcomeGuide = driver({
    showProgress: true,
    showButtons: ['next'],
    nextBtnText: i18n.t('general.next'),
    doneBtnText: i18n.t('general.close'),
    onPopoverRender: (popover: PopoverDOM) => {
      if (welcomeGuide.isLastStep()) {
        return
      }
      const skipButton = document.createElement('button')
      skipButton.innerText = i18n.t('general.skip')
      popover.footerButtons.appendChild(skipButton)
      skipButton.addEventListener('click', async () => {
        welcomeGuide.destroy()
        updateUserPreferences && (await postUserPreferences({ welcomeGuideTripDetails: WelcomeGuideStatus.SKIPPED }))
      })
    },
    steps: [
      {
        element: '[data-guide="leg-summary"]',
        popover: {
          title: i18n.t('trip_details.guide.leg_summary.title'),
          description: i18n.t('trip_details.guide.leg_summary.description'),
          side: 'bottom',
          align: 'center',
        },
      },
      {
        element: '[data-guide="PUP-card"] [data-guide="primary-actions"]',
        popover: {
          title: i18n.t('trip_details.guide.pup_primary_actions.title'),
          description: i18n.t('trip_details.guide.pup_primary_actions.description'),
          side: 'bottom',
          align: 'center',
        },
      },
      {
        element: '[data-guide="DEL-card"] [data-guide="primary-actions"]',
        popover: {
          title: i18n.t('trip_details.guide.del_primary_actions.title'),
          description: i18n.t('trip_details.guide.del_primary_actions.description'),
          side: 'bottom',
          align: 'center',
        },
      },
      {
        element: '[data-guide="trip-summary"]',
        popover: {
          title: i18n.t('trip_details.guide.trip_summary.title'),
          description: i18n.t('trip_details.guide.trip_summary.description'),
          side: 'bottom',
          align: 'center',
        },
      },
      {
        element: '[data-guide="trip-share"]',
        popover: {
          title: i18n.t('trip_details.guide.trip_share.title'),
          description: i18n.t('trip_details.guide.trip_share.description'),
          side: 'bottom',
          align: 'center',
        },
      },
    ],
    onDestroyStarted: async () => {
      if (welcomeGuide.isLastStep() && updateUserPreferences) {
        try {
          await postUserPreferences({ welcomeGuideTripDetails: WelcomeGuideStatus.DONE })
        } catch {
          //
        }
      }
      welcomeGuide.destroy()
    },
  })

  return welcomeGuide
}
